const RequestStatusRenderer = ({ value }) => {
  const getClassName = () => {
    switch (value) {
      case 'Draft':
        return 'draft';
      case 'Submitted':
        return 'submit';
      case 'Acknowledged':
        return 'acknowledged';
      case 'Not Approved':
        return 'not-approved';
      case 'Cancelled':
        return 'cancelled';
      case 'Lead Agency Changed':
        return 'lead-agency-changed';
      case 'Re-Routed':
        return 're-routed';
      case 'Assigned':
        return 'assigned';
      case 'Withdrawn':
        return 'withdrawn';
      default:
        break;
    }
  };

  return (
    value
      ? <span className={`request-status-tag ${getClassName()}`}>{value}</span>
      : null
  );
};

export default RequestStatusRenderer;
