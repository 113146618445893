import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const MVP_ST_PAUL = () => (
  <>
    <section id='mvp-st-paul'>
      <DistrictInfoTable district='MVP'/>
      <p>
      Please do not use RRS to apply for a permit in Minnesota or Wisconsin.  The St. Paul District employs a joint permit application process with those States.  The <ExternalLink content='St. Paul District website' title='St. Paul District Website' href='https://www.mvp.usace.army.mil/Missions/Regulatory/Permitting-Process-Procedures/' /> contains more information on how to obtain a permit or to obtain copies of the permit application forms.
      </p>
    </section>
  </>
);

export default MVP_ST_PAUL;
