import { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';
import { Alert, Accordion } from '@trussworks/react-uswds';
import {  mdiKeyboard, mdiMapSearchOutline, mdiDatabaseMarker } from '@mdi/js';
import L from 'leaflet';
import * as turf from '@turf/turf';

import TextInput from '@components/text-input/TextInput';
import TextAreaInput from '@components/textarea/TextArea';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput';
import ModuleButton from '@pages/Resources/components/_shared/ModuleButton';
import Map from '@components/map/map';
import ReadOnlyMap from '@components/map/readOnlyMap';
import SplitLayout from '@components/layout/splitLayout';
import { mapStateAbbrToFullName, scrollToElement , formatCoordFlt, formatCoordStr, isValidORMNumber, mapCountryAlpha3toAlpha2 } from '@src/utils/helpers';
import { latRegex, lngRegex } from '@src/utils/regex';
import {  GeometryTypes } from '@src/utils/enums';

import { mapUseTipsAccordionList, ormErrorMsgAlert , states, usAndTerritories } from '@forms/input-forms/_helper';
import countryRegionData from 'country-region-data/dist/data-umd';
import AddressSuggestInput from '@components/address-suggestion/AddressSuggestInput';
import Tooltip from '@components/tooltip/tooltip';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import RadioButton from '@components/radio-button/RadioButton';
import Checkbox from '@components/checkbox/Checkbox';
import ProjectLocationTabGDB from './ProjectLocationTab.gdb';

const ProjectLocationTab = connect(
  'doResetAddressCandidatesResults',
  'doResetGeocodingResults',
  'doUpdateRequestLocation',
  'doUpdateComplexStateField',
  'doGetDistrictFromGeometry',
  'doResetDistrictAPIResults',
  'doGetAddressFromLatLng',
  'doGetCountyListByState',
  'doGetPLSSFromLatLng',
  'doResetPLSSAPIResults',
  'doResetComplexState',
  'doUpdateRequestObject',
  'doClearRequestLocation',
  'doFindAddressCandidates',
  'selectAddressCandidateResults',
  'selectReverseGeocodeResults',
  'selectGDBProjectResults',
  ({
    doResetAddressCandidatesResults,
    doResetGeocodingResults,
    doUpdateRequestLocation,
    doUpdateComplexStateField,
    doGetDistrictFromGeometry,
    doResetDistrictAPIResults,
    doGetAddressFromLatLng,
    doGetCountyListByState,
    doGetPLSSFromLatLng,
    doResetPLSSAPIResults,
    doResetComplexState,
    doUpdateRequestObject,
    doClearRequestLocation,
    doFindAddressCandidates,
    addressCandidateResults,
    reverseGeocodeResults,
    gDBProjectResults,
    areaRequired,
    activeTab,
    locationType = 'Project',
    isReadOnly,
    setProjectOutsideUS,
  }) => {
    const {  formState: { errors }, watch, setValue, resetField, clearErrors } = useFormContext();
    const GDBUploadRef = useRef();
    const [moduleCollapse, setModuleCollapse] = useState(0);
    const [geometry, setGeometry] = useState();
    const [extent, setExtent] = useState();
    const [countyOptions, setCountyOptions] = useState([]);
    const [returnedCounty, setReturnedCounty] = useState();
    const [stateOptions, setStateOptions] = useState([]);
    const inputType = ['address', 'coordinates'];


    const errorCount = Object.entries(errors)?.length;
    const inputTypeError = Object.keys(errors).some(error => error === 'inputType');
    const addressOptionError = Object.keys(errors).some(error => error === 'addressInputType');

    const projectName = watch('projectName');
    const selectedCountry = watch('country');
    const latitude = watch('latitude');
    const longitude = watch('longitude');
    const address = watch('address');
    const city = watch('city');
    const state = watch('state');
    const zipcode = watch('zipcode');
    const country = watch('country');
    const showMapInput = watch('showMapInput');
    const showGDBInput = watch('showGDBInput');
    const showAddressInput = watch('showAddressInput');
    const addressInputType = watch('addressInputType');
    const watchPreviousFileNumbersExist = watch('previousFileNumbersExist', 'false');
    const watchPreviousFileNumbers = watch('previousFileNumbers', []);

    const currentInputState = {
      Address: showAddressInput,
      Map: showMapInput,
      GDB: showGDBInput,
    };

    const pointGeom = {
      'type': 'Point',
      'coordinates': [
        formatCoordFlt(longitude),
        formatCoordFlt(latitude)
      ]
    };

    const resetFields = () => {
      setExtent();
      setGeometry();
      doResetGeocodingResults();
      doResetDistrictAPIResults();
      doResetPLSSAPIResults();
      doUpdateRequestLocation({});
      doResetAddressCandidatesResults();
      doClearRequestLocation();
      doResetComplexState();
      resetField('address');
      resetField('addressTwo');
      resetField('city');
      resetField('state');
      resetField('zipcode');
      resetField('county');
      resetField('projectArea');
      resetField('projectOutside');
      resetField('unitOfMeasurement');
      setValue('latitude', undefined);
      setValue('longitude', undefined);
      clearErrors('inputType');
    };

    const clearFileInput = () => {
      GDBUploadRef?.current && GDBUploadRef.current.clearFiles();
      doResetGeocodingResults();
      setExtent();
      setGeometry();
    };

    const handleSelectChange = (e) => {
      const results = e?.map(num => isValidORMNumber(num.value));

      if (!results.every(Boolean)) {
        setValue('previousFileNumbers', e?.length > 1 ? watchPreviousFileNumbers : []);
        doUpdateRequestObject({ ormDANumber: e?.length > 1 ? watchPreviousFileNumbers : null });
        alert(ormErrorMsgAlert);
      }
    };

    const handlePreviousFileNumberChange = (e) => {
      if (e?.target?.value === 'false') {
        setValue('previousFileNumbers', [], { shouldValidate: true });
      }
    };

    const handleBlur = (e) => {

      const didAddressFieldChange = (field,) => (['address', 'city', 'state', 'zipcode', 'country'].includes(field));

      if (e?.target?.name === 'latitude' || e?.target?.name === 'longitude') {
        if (latRegex.test(latitude) && lngRegex.test(longitude)) {
          const formattedLatFlt = formatCoordFlt(latitude);
          const formattedLngFlt = formatCoordFlt(longitude);
          const pointGeometry = { type: 'Point', coordinates: [formattedLatFlt, formattedLngFlt] };
          const reversePointGeometry = { type: 'Point', coordinates: [formattedLngFlt, formattedLatFlt] };

          const geoJsonLayer = L.geoJson(reversePointGeometry);
          const extent = geoJsonLayer.getBounds();
          const bounds = [[extent._northEast.lat, extent._northEast.lng], [extent._southWest.lat, extent._southWest.lng]];

          reversePointGeometry?.coordinates?.length > 0 && doGetPLSSFromLatLng(formattedLatFlt, formattedLngFlt);
          reversePointGeometry?.coordinates?.length > 0 && doGetDistrictFromGeometry(reversePointGeometry);
          setGeometry(reversePointGeometry);

          geometry && doUpdateComplexStateField({ name: 'projectGeometry', value: pointGeometry });
          bounds && doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
          bounds && setExtent(bounds);
        }
        //Add Marker to Map
        if (!geometry || geometry.type === 'Point') {
          doUpdateRequestLocation({ geometry: pointGeom });
          doUpdateComplexStateField({ name: 'projectGeometry', value: pointGeom });
        }
        doUpdateRequestLocation({ [e.target.name]: formatCoordFlt(e.target.value) });
        return;
      }
      if (address && city && state && zipcode && country && didAddressFieldChange(e?.target?.name)) {
        const addressString = `${address}, ${city}, ${state} ${zipcode}, ${country}`;
        doFindAddressCandidates(addressString);
      }

      if (e?.target?.name && e?.target?.value) {
        doUpdateRequestLocation({ [e.target.name]: e.target.value });
      }
    };

    const handleCheckboxChange = (e) => {
      setProjectOutsideUS(e?.target?.checked ? true : false);
    };

    const handleInputChange = (e) => {
      //Used to account for browser autofill
      if (e?.target?.name) {
        doUpdateRequestLocation({ [e.target.name]: e?.target?.value });
      }
    };

    const handleAddressOnChange = e => {
      setValue('addressInputType', e?.target?.value, { shouldValidate: true });
      scrollToElement('second_scroll');
      resetFields();
    };

    const handleStateOnChange = async (e) => {

      resetField('county', '');
      doUpdateRequestLocation({ county: ''});
      setReturnedCounty(undefined);

      const state = e?.target?.value;

      const stateName = mapStateAbbrToFullName(state);

      const counties = await doGetCountyListByState(stateName);
      const countyOptions = counties?.map(county =>  <option key={county} value={county}>{county}</option>);
      setCountyOptions(() => countyOptions);

    };

    const populateAddressFields = async (address, isProjectAddress) => {
      const fields = address?.[0]?.candidates?.[0]?.attributes;
      const addressField = document.getElementById('address');
      const addressFieldFocused = document.activeElement === addressField;

      if (fields) {

        const stateName = mapStateAbbrToFullName(fields?.RegionAbbr);

        const counties = await doGetCountyListByState(stateName);
        const countyOptions = counties?.map(county =>  <option key={county} value={county}>{county}</option>);
        setCountyOptions(countyOptions);

        if (addressFieldFocused) {
          addressField.blur();
        }

        setValue('address', fields.StAddr, { shouldValidate: true });
        setValue('addressTwo', fields.SubAddr, { shouldValidate: true });
        setValue('city', fields.City, { shouldValidate: true });
        setValue('state', fields.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', fields.Postal, { shouldValidate: true });
        setReturnedCounty(fields.Subregion);
        setValue('country', mapCountryAlpha3toAlpha2(fields.Country), { shouldValidate: true });
        if (isProjectAddress)
        {
          setValue('latitude', formatCoordStr(fields.Y), { shouldValidate: true });
          setValue('longitude', formatCoordStr(fields.X), { shouldValidate: true });
          doUpdateRequestLocation({ address: fields?.StAddr, addressTwo: fields?.SubAddr, city: fields?.City, state: fields?.RegionAbbr, zipcode: fields?.Postal, county: fields?.Subregion, country: fields?.Country !== 'USA' ? fields?.Country : 'US', latitude: formatCoordFlt(fields.Y), longitude: formatCoordFlt(fields.X) });
        }
        else{
          doResetAddressCandidatesResults();
        }

        if (addressFieldFocused) {
          addressField.focus();
        }
      }
    };

    const toggleInputType = (type) => {
      if (!currentInputState[type]) {
        resetFields();
        scrollToElement('first_scroll');
      }

      switch (type) {
        case 'Address':
          setValue('showAddressInput', true);
          setValue('showMapInput', false);
          setValue('showGDBInput', false);
          setModuleCollapse(moduleCollapse !== 1 ? 1 : 0);
          errorCount > 0 && clearErrors();
          break;
        case 'Map':
          setValue('showAddressInput', false);
          setValue('showMapInput', true);
          setValue('showGDBInput', false);
          setModuleCollapse(moduleCollapse !== 2 ? 2 : 0);
          errorCount > 0 && clearErrors();
          break;
        case 'GDB':
          setValue('showAddressInput', false);
          setValue('showMapInput', false);
          setValue('showGDBInput', true);
          setModuleCollapse(moduleCollapse !== 3 ? 3 : 0);
          errorCount > 0 && clearErrors();
          break;
        default:
          setModuleCollapse(0);
          resetFields();
          break;
      }
    };

    const regionHelper = useCallback((country) => {
      const selectedCountryData = countryRegionData?.find(regionCountry => regionCountry.countryShortCode === country);

      let regions = [];
      if (selectedCountryData) {
        regions = selectedCountryData.regions.map(region => ({
          value: region.shortCode ?? region.name,
          text: region.name
        }));
      }

      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      return regions;
    }, []);

    const handleCountryChange = useCallback((selectedCountry) => {
      const regions = regionHelper(selectedCountry);
      setStateOptions(regions);
      setValue('city', '',);
      setValue('state', '',);
      setValue('zipcode', '',);
      setValue('county', '',);
      setCountyOptions([]);
    }, [regionHelper, setValue]);

    const refreshMapWithGDBData = useCallback((type, coordinates) => {
      switch (type) {
        case GeometryTypes.Point:
          doGetPLSSFromLatLng(coordinates);
          doGetAddressFromLatLng(formatCoordStr(coordinates[0]), formatCoordStr(coordinates[1]));
          setValue('latitude', formatCoordStr(coordinates[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(coordinates[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(coordinates[1]), longitude: formatCoordFlt(coordinates[0]) });
          break;

        case GeometryTypes.MultiPoint:
          if (coordinates.length > 0) {
            const firstPoint = coordinates[0];
            doGetPLSSFromLatLng({ lat: firstPoint[1], lng: firstPoint[0] });
            doGetAddressFromLatLng(formatCoordStr(firstPoint[1]), formatCoordStr(firstPoint[0]));
            setValue('latitude', formatCoordStr(firstPoint[1]), { shouldValidate: true });
            setValue('longitude', formatCoordStr(firstPoint[0]), { shouldValidate: true });
            doUpdateRequestLocation({ latitude: formatCoordFlt(firstPoint[1]), longitude: formatCoordFlt(firstPoint[0]) });
          }
          break;

        case GeometryTypes.Polygon:
        case GeometryTypes.MultiPolygon:
          const firstPolygon = coordinates[0]; // Use the first polygon if MultiPolygon
          const turfPolygon = turf.polygon(firstPolygon);
          const polygonCentroid = turf.centroid(turfPolygon).geometry.coordinates;
          doGetPLSSFromLatLng({ lat: polygonCentroid[1], lng: polygonCentroid[0] });
          doGetAddressFromLatLng(formatCoordStr(polygonCentroid[1]), formatCoordStr(polygonCentroid[0]));
          setValue('latitude', formatCoordStr(polygonCentroid[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(polygonCentroid[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(polygonCentroid[1]), longitude: formatCoordFlt(polygonCentroid[0]) });

          // Calculate Project Area (Acres)
          const area = turf.area(turfPolygon);
          const formattedArea = area / 4046.856422;
          const roundedArea = formattedArea.toFixed(6);
          setValue('projectArea', roundedArea, { shouldValidate: true });
          setValue('unitOfMeasurement', 'Acres', { shouldValidate: true });
          doUpdateRequestLocation({ projectArea: Number(roundedArea), unitOfMeasurement: 'Acres' });
          break;

        case GeometryTypes.LineString:
        case GeometryTypes.MultiLineString:
          const firstLine = coordinates[0]; // Use the first line if MultiLineString
          const turfLine = turf.lineString(firstLine);
          const lineCentroid = turf.centroid(turfLine).geometry.coordinates;
          doGetPLSSFromLatLng({ lat: lineCentroid[1], lng: lineCentroid[0] });
          doGetAddressFromLatLng(formatCoordStr(lineCentroid[1]), formatCoordStr(lineCentroid[0]));
          setValue('latitude', formatCoordStr(lineCentroid[1]), { shouldValidate: true });
          setValue('longitude', formatCoordStr(lineCentroid[0]), { shouldValidate: true });
          doUpdateRequestLocation({ latitude: formatCoordFlt(lineCentroid[1]), longitude: formatCoordFlt(lineCentroid[0]) });
          break;

        default:
          break;
      }
    }, [doGetAddressFromLatLng, doGetPLSSFromLatLng, doUpdateRequestLocation, setValue]);

    useEffect(() => {
      if (reverseGeocodeResults) {
        setValue('address', reverseGeocodeResults.Address, { shouldValidate: true });
        setValue('addressTwo', reverseGeocodeResults.SubAddr, { shouldValidate: true });
        setValue('city', reverseGeocodeResults.City, { shouldValidate: true });
        setValue('state', reverseGeocodeResults.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', reverseGeocodeResults.Postal, { shouldValidate: true });
        setValue('county', reverseGeocodeResults.Subregion, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(reverseGeocodeResults.CountryCode), { shouldValidate: true });
        // ESRI API RETURNS 'USA' and 'United States', dropdown expects 'US' or 'United States of America'
        doUpdateRequestLocation({ address: reverseGeocodeResults.Address, addressTwo: reverseGeocodeResults.SubAddr, city: reverseGeocodeResults.City, state: reverseGeocodeResults.RegionAbbr, zipcode: reverseGeocodeResults.Postal, county: reverseGeocodeResults.Subregion, country: reverseGeocodeResults.Country !== 'USA' ? reverseGeocodeResults.Country : 'US' });
      }
    }, [reverseGeocodeResults, setValue, doUpdateRequestLocation]);


    useEffect(() => {
      if (gDBProjectResults) {
        setGeometry(gDBProjectResults);
        doUpdateRequestLocation({ geometry: gDBProjectResults });
        doUpdateComplexStateField({ name: 'projectGeometry', value: gDBProjectResults });
        doGetDistrictFromGeometry(gDBProjectResults);

        const coordinates = gDBProjectResults?.coordinates;
        const type = gDBProjectResults?.type;

        refreshMapWithGDBData(type, coordinates);

        const geoJsonLayer = L.geoJson(gDBProjectResults);
        const extent = geoJsonLayer.getBounds();
        const bounds = [[extent._northEast.lat, extent._northEast.lng], [extent._southWest.lat, extent._southWest.lng]];
        bounds && doUpdateComplexStateField({ name: 'projectBounds', value: bounds });
        bounds && setExtent(bounds);
      }
    }, [gDBProjectResults, doUpdateComplexStateField, doUpdateRequestLocation, doGetDistrictFromGeometry, refreshMapWithGDBData]);

    useEffect(() => {
      const location = addressCandidateResults?.candidates?.[0]?.location;
      const extent = addressCandidateResults?.candidates?.[0]?.extent;

      if (location && addressInputType !== 'coordinates') {
        //IMPORTANT: location coords are returned from API in (x: long, y: lat) format

        doUpdateRequestLocation({ latitude: formatCoordFlt(location?.y), longitude: formatCoordFlt(location?.x) });
        setValue('latitude', formatCoordStr(formatCoordStr(location?.y)), { shouldValidate: true });
        setValue('longitude', formatCoordStr(formatCoordStr(location?.x)), { shouldValidate: true });

        const geometry = { type: 'Point', coordinates: [formatCoordFlt(location?.x), formatCoordFlt(location?.y)] };
        geometry && setGeometry(geometry);
        geometry && doUpdateRequestLocation({ geometry: geometry });
        geometry && doUpdateComplexStateField({ name: 'projectGeometry', value: geometry });
        geometry && doGetDistrictFromGeometry(geometry);
        doGetPLSSFromLatLng(location?.y, location?.x);

        if (Math.sign(location?.y) === -1 || Math.sign(location?.x) === 1) {
          setValue('projectOutside', 'checked');
          setProjectOutsideUS(true);
        } else {
          setValue('projectOutside', '');
          setProjectOutsideUS(false);
        }
      }

      if (extent) {
        const boundary = [[extent.ymin, extent.xmin], [extent.ymax, extent.xmax]];
        boundary && doUpdateComplexStateField({ name: 'projectBounds', value: boundary });
        boundary && setExtent(boundary);
      }

      doResetAddressCandidatesResults();

    }, [addressCandidateResults, addressInputType, doResetAddressCandidatesResults, setValue, doUpdateRequestLocation, doUpdateComplexStateField, doGetDistrictFromGeometry, doGetPLSSFromLatLng, setProjectOutsideUS]);

    useEffect(() => {
      if (!usAndTerritories.some(country => country.value === selectedCountry) && selectedCountry !== '') {
        setValue('country', '');
      }
    }, [selectedCountry, setValue]);

    useEffect(()=>{

      returnedCounty && setValue('county', returnedCounty, { shouldValidate: true });

    },[returnedCounty, countyOptions, setValue]);

    useEffect(() => {
      const regions = regionHelper(country);
      setStateOptions(regions);
    }, [country, regionHelper, setStateOptions]);

    return (
      <>
        {errors && <ErrorSummary errors={errors} modalID='projectLocationTab' type='modal' />}
        <div id='projectLocationTab'>
          {locationType === 'Project' &&
            <>
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom width-full padding-bottom-1 margin-top-1'>Project Information</h4>
                  A red asterisk (<span className='asterisk-color'>*</span>) indicates a required field.
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput name='projectName' label='Project Name' type='text' required readOnly={isReadOnly} onBlur={handleBlur}  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <SelectInput name='previousFileNumbersExist' label='Has the USACE previously issued a file number for any part of the project area?' required className='w-50' readOnly={isReadOnly} onChange={handlePreviousFileNumberChange} onBlur={handleBlur}>
                    <option key='2' value='true'>Yes</option>
                    <option key='3' value='false'>No</option>
                  </SelectInput>
                </div>
              </div>

              {watchPreviousFileNumbersExist === 'true' && <>
                <div className='row margin-top-1 margin-bottom-2'>
                  <div className='col'>
                    <CreatableSelectInput name='previousFileNumbers' label='Previous File Number(s)' tooltip='Enter the file number and press "ENTER" or click on "Create ..."' placeholder='e.g. MVS-2023-12345, MVM-2020-1234 ...' required multi handleChange={handleSelectChange} readOnly={isReadOnly} />
                  </div>
                </div>
              </>}
            </>}
          <div className='row margin-top-2'>
            <div className='col'>
              <h4 className='border-bottom width-full padding-bottom-1'>{locationType} Location</h4>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              {locationType !== 'Site' ?
                <Alert type='warning' headingLevel='h4' heading='IMPORTANT'>{`It is critical to accurately input the location during the initial creation of the ${locationType.toLowerCase()}. The ${locationType.toLowerCase()}'s location determines the range of available requests within that specific geographical area. If an error is made in entering the ${locationType.toLowerCase()} location, it will be necessary to delete the request and initiate a new one for the ${locationType.toLowerCase()}.`}</ Alert>
                :
                <div>{`Please provide the ${locationType.toLowerCase()} location of the potential violation being reported.`}</div>
              }
            </div>
          </div >
          <div className={`padding-1 margin-top-1 align-content-center ${(showAddressInput || showMapInput || showGDBInput) ? 'border-bottom' : ''} border-top`} id='inputType'>
            <h4 className='text-center margin-bottom-1'>How would you like to provide the {locationType} Location? </h4>
            <div className='row justify-content-center'>
              <div className='col-lg-3 col-md-6 col-xs-12 margin-top-1 margin-right-2'>
                <ModuleButton
                  title='Type in Address'
                  imageAlt='Type in Address'
                  text='Provide us with a physical address or latitude and longitude coordinates'
                  subtext={`Recommended for smaller ${locationType.toLowerCase()}s.`}
                  icon={mdiKeyboard}
                  onClick={() => toggleInputType('Address')} S
                  isModalButton
                  isError={inputTypeError}
                  selected={showAddressInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 margin-top-1 margin-bottom-1 margin-right-2'>
                <ModuleButton
                  title='Use a Map'
                  imageAlt='Use a Map'
                  text='Provide us with an address by placing a point, line or polygon on a map.'
                  subtext={`Recommended for small and medium  ${locationType.toLowerCase()}s.`}
                  icon={mdiMapSearchOutline}
                  onClick={() => toggleInputType('Map')}
                  isModalButton
                  isError={inputTypeError}
                  selected={showMapInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
              <div className='col-lg-3 col-md-6 col-xs-12 margin-top-1 margin-bottom-1 margin-right-2'>
                <ModuleButton
                  title='Upload a Geodatabase'
                  imageAlt='Upload a Geodatabase'
                  text={`Provide us with ${locationType.toLowerCase()} boundaries by uploading a geodatabase file.`}
                  subtext={`Recommended for larger ${locationType.toLowerCase()}s.`}
                  icon={mdiDatabaseMarker}
                  onClick={() => toggleInputType('GDB')}
                  isModalButton
                  isError={inputTypeError}
                  selected={showGDBInput}
                  hideDetails={moduleCollapse !== 0}
                />
              </div>
            </div>
          </div >
          {showAddressInput &&
            <>
              <div className='row justify-content-center' id='first_scroll'>
                <div className='col-12 col-md-6'>
                  <fieldset className={`radio-button-group mx-auto w-75 margin-top-2 ${addressOptionError ? 'radio-fieldset-invalid' : ''}`} id='addressInputType'>
                    <legend className='padding-left-1' htmlFor='addressCoordinates'>I would like to enter ...</legend>
                    <RadioButton
                      className='padding-1'
                      id='radio-address'
                      label={<>The <span className='text-bold'>physical address</span> for the {locationType.toLocaleLowerCase()} location</>}
                      name='addressCoordinates'
                      onChange={handleAddressOnChange}
                      value='address'
                      checked={addressInputType === 'address'}
                    />
                    <RadioButton
                      className='padding-1'
                      id='radio-coordinates'
                      label={<>The <span className='text-bold'>coordinates</span> for the {locationType.toLocaleLowerCase()} location</>}
                      name='addressCoordinates'
                      onChange={handleAddressOnChange}
                      value='coordinates'
                      checked={addressInputType === 'coordinates'}
                    />
                  </fieldset>

                </div>
              </div>
              {inputType?.includes(addressInputType) &&
                <>
                  <SplitLayout>
                    <>
                      <div className='row'>
                        <div className='col' id='second_scroll'>
                          <h4 className='border-bottom width-full padding-bottom-1 padding-top-1 margin-top-2'>{locationType} {addressInputType === 'address' ? 'Address' : 'Coordinates'}</h4>
                        </div>
                      </div>
                      {addressInputType === 'address' && <>
                        <div className='row'>
                          <div className='col'>
                            <AddressSuggestInput label='Address' name='address' required isProjectAddress onBlur={handleBlur} onChange={handleInputChange} populateAddressFields={populateAddressFields} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <TextInput label='Address Line 2' name='addressTwo' type='text' onBlur={handleBlur} onChange={handleInputChange} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-4'>
                            <SelectInput name='country' label='Country' onBlur={handleBlur} onChange={(e) => handleCountryChange(e.target.value)} required >
                              {usAndTerritories.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                            </SelectInput>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <TextInput label='City' name='city' type='text' maxLength={26} onBlur={handleBlur} onChange={handleInputChange} required />
                          </div>
                          <div className='col'>
                            {country === 'US' || country === null || country === '' ? (
                              <SelectInput name='state' label='State' onBlur={handleBlur} onChange={handleStateOnChange} required >
                                {states.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
                              </SelectInput>
                            ) : (
                              <SelectInput name='state' label='Region' onBlur={handleBlur} onChange={handleStateOnChange} required >
                                {stateOptions.map((item, i) => (<option key={i + 2} value={item.text}>{item.text}</option>))}
                              </SelectInput>
                            )}
                          </div>
                          <div className='col'>
                            <TextInput type='tel' label={country === 'US' || country === null || country === '' ? 'Zip Code' : 'Postal code'} name='zipcode' maxLength={5} onBlur={handleBlur} onChange={handleInputChange} required />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-4'>
                            <SelectInput name='county' label='County' readOnly={isReadOnly} onChange={handleInputChange} onBlur={handleBlur}>
                              {...countyOptions}
                            </SelectInput>
                          </div>
                        </div>
                      </>}
                      {addressInputType === 'coordinates' && <>
                        <div className='row margin-top-1'>
                          <div className='col'>
                            <span style={{ fontStyle: 'italic' }}>Coordinates must be in Decimal Degrees</span>
                          </div>
                        </div>
                        <div className='row'>
                          <fieldset className='col d-flex align-content-center' id='projectOutside'>
                            <Checkbox id='address-project-outside' name='projectOutside' label={<>My {locationType.toLowerCase()} is outside of the continental U.S.<Tooltip content={`Select this option for ${locationType.toLowerCase()}s in Guam or other U.S. Territories outside of the continental U.S.`}/></>} onChange={handleCheckboxChange} />
                          </fieldset>
                        </div>
                        <div className='row margin-bottom-2'>
                          <div className='col'>
                            <TextInput maxLength={12} name='latitude' label='Latitude' readOnly={isReadOnly} onBlur={handleBlur} required />
                          </div>
                          <div className='col'>
                            <TextInput maxLength={12} name='longitude' label='Longitude' readOnly={isReadOnly} onBlur={handleBlur} required />
                          </div>
                        </div>
                      </>}
                      <div className='row margin-top-2'>
                        <div className='col'>
                          <h4 className='border-bottom width-full padding-bottom-1'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-6'>
                          <TextInput type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                        </div>
                        <div className='col-6'>
                          <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                            <option key={1} value='Square Feet'>Square Feet</option>
                            <option key={2} value='Acres'>Acres</option>
                          </SelectInput>
                        </div>
                      </div>
                    </>

                    <>
                      <h4 className='border-bottom width-full padding-bottom-1 padding-top-1 margin-top-2'>{locationType} Map</h4>
                      {activeTab === 0 &&
                        <ReadOnlyMap extent={extent} geometry={geometry} geometryName={projectName} height='50vh' />
                      }
                    </>
                  </SplitLayout >
                </>}
            </>
          }
          {showMapInput &&
            <>
              <div className='row'>
                <div className='col'>
                  <Accordion bordered items={mapUseTipsAccordionList} className='margin-top-2 margin-bottom-1' />
                </div>
              </div>
              {activeTab === 0 &&
                <div className='row' id='mapScroll'>
                  <div className='col'>
                    <Map disableFileUpload={true} setProjectOutsideUS={setProjectOutsideUS} />
                  </div>
                </div>}
              <div className='row margin-top-1'>
                <div className='col'>
                  <span style={{ fontStyle: 'italic' }}>Coordinates must be in Decimal Degrees</span>
                </div>
              </div>
              <div className='row'>
                <fieldset className='col d-flex align-content-center' id='projectOutside'>
                  <Checkbox id='map-project-outside' name='projectOutside' label={<>My {locationType.toLowerCase()} is outside of the continental U.S.<Tooltip content={`Select this option for ${locationType.toLowerCase()}s in Guam or other U.S. Territories outside of the continental U.S.`}/></>} onChange={handleCheckboxChange} />
                </fieldset>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextInput maxLength={12} name='latitude' label='Latitude' readOnly onBlur={handleBlur} required />
                </div>
                <div className='col'>
                  <TextInput maxLength={12} name='longitude' label='Longitude' readOnly onBlur={handleBlur} required />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom width-full padding-bottom-1 margin-top-2'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <TextInput type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
                <div className='col-6'>
                  <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                    <option key={1} value='Square Feet'>Square Feet</option>
                    <option key={2} value='Acres'>Acres</option>
                  </SelectInput>
                </div>
              </div>
            </>}
          {showGDBInput && (
            <>
              <ProjectLocationTabGDB
                activeTab={activeTab}
                doDeleteFile={clearFileInput}
                extent={extent}
                geometry={geometry}
                locationType={locationType}
                resetFields={resetFields}
              />
              <div className='row'>
                <div className='col'>
                  <h4 className='border-bottom width-full padding-bottom-1'>{`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`}</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <TextInput type='tel' maxLength={17} name='projectArea' label={`${locationType === 'Project' ? 'Project Area' : 'Area of Violation'}`} required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
                <div className='col-6'>
                  <SelectInput name='unitOfMeasurement' label='Unit of Measurement' required={areaRequired === false ? false : true} readOnly={isReadOnly} onBlur={handleBlur}>
                    <option key={1} value='Square Feet'>Square Feet</option>
                    <option key={2} value='Acres'>Acres</option>
                  </SelectInput>
                </div>
              </div>
            </>
          )}
          {(inputType?.includes(addressInputType) || showMapInput || showGDBInput) &&
            <>
              <div className='row margin-top-1'>
                <div className='col'>
                  <h4 className='border-bottom width-full padding-bottom-1 padding-top-1'>Additional {locationType} Information</h4>
                </div>
              </div>
              <div className='row'>
                <div className='col-8'>
                  <TextInput name='parcelNumber' label='Parcel Number' type='text' readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
              </div>
              <div className='row'>
                <div className='col-8'>
                  <TextInput name='nearestWaterbody' label='Nearest Waterbody' type='text' readOnly={isReadOnly} onBlur={handleBlur} />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <TextAreaInput name='siteDirections' label={`Directions to ${locationType} Location`} readOnly={isReadOnly} onBlur={handleBlur} tooltip={<><p>Provide directions to the site from a known location or landmark. Include highway and street numbers as well as names. Also provide distances from known locations and any other information that would assist in locating the site. You may also provide description of the proposed {locationType.toLowerCase()} location, such as lot numbers, tract numbers, or you may choose to locate the proposed {locationType.toLowerCase()} location from a known point (such as the right descending bank of Smith Creek, one mile downstream from the Highway 14 bridge). If a large river or stream, include the river mile of the proposed {locationType.toLowerCase()} location if known.</p>
                    <p>Example: The {locationType.toLowerCase()}/review area is located on the NSEW side of ROADWAY, XX miles/feet NSEW of the intersection of ROADWAY and ROADWAY, at Latitude XX.XXXXXX and Longitude XX.XXXXXX; in CITY, COUNTY, STATE.</p></>} />
                </div>
              </div>
            </>
          }
        </div>
      </>
    );
  });

export default ProjectLocationTab;
