import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import PreAppForm3Instructions from '@forms/static-forms/PreAppForm3Instructions/PreAppForm3Instructions';
import PreAppForm3GeneralProjectInformation from '@forms/input-forms/PreAppForm/PreAppForm3GeneralProjectInformation';
import PreAppForm3Contacts from '@forms/input-forms/PreAppForm/PreAppForm3Contacts';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import PreAppForm3CertifySignSubmit from '@forms/input-forms/PreAppForm/PreAppForm3CertifySignSubmit';
import PreAppFormPropertyOwners from '@forms/input-forms/PreAppForm/PreAppFormPropertyOwners';

//List of steps for vertical stepper, these can also be used for form header. index = step ID 
export const PreAppForm3Metadata = [
  PreAppForm3Instructions.metadata,
  PreAppForm3GeneralProjectInformation.metadata,
  PreAppForm3Contacts.metadata,
  PreAppFormPropertyOwners.metadata,
  SupportingInformation.metadata,
  PreAppForm3CertifySignSubmit.metadata];

const PreAppForm3 = connect(
  'doUpdatePreAppRequest',
  'selectActiveStep',
  'selectPreAppID',
  'selectPreAppData',
  ({
    doUpdatePreAppRequest,
    activeStep,
    preAppID,
    preAppData,
    edit,
  }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showGeneralProjectInfo, setShowGeneralProjectInfo] = useState(false);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showPropertyOwners, setShowPropertyOwners] = useState(false);
    const [showSupportingInfo, setShowSupportingInfo] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0: {
            setShowInstructions(true);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 1: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(true);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 2: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(true);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 3: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(true);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
            break;
          }
          case 4: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(true);
            setShowCertifySignSubmit(false);
            break;
          }
          case 5: {
            setShowInstructions(false);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(true);
            break;
          }
          default: {
            setShowInstructions(true);
            setShowGeneralProjectInfo(false);
            setShowContactInfo(false);
            setShowPropertyOwners(false);
            setShowSupportingInfo(false);
            setShowCertifySignSubmit(false);
          }
        }
      }
    }, [activeStep]);

    // if user clicks on stepper, hide all other steps but one clicked on
    // if user clicks back, hide all other values but current - 1
    // user only able to go forward when section is valid

    const instructionStyles = { display: showInstructions ? 'inline' : 'none' };
    const generalProjInfoStyles = { display: showGeneralProjectInfo ? 'inline' : 'none' };
    const contactInfoStyles = { display: showContactInfo ? 'inline' : 'none' };
    const propertyOwnerStyles = { display: showPropertyOwners ? 'inline' : 'none' };
    const supportingInfoStyles = { display: showSupportingInfo ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (<>
      <section id='PreAppForm3Instructions' name='Instructions' style={instructionStyles}>
        <PreAppForm3Instructions stepNo={0} />
      </section>
      <section id='PreAppForm3GeneralProjectInformation' name='General Project Information' style={generalProjInfoStyles}>
        <PreAppForm3GeneralProjectInformation stepNo={1} />
      </section>
      <section id='PreAppForm3ContactInformation' name='Contact Information' style={contactInfoStyles}>
        <PreAppForm3Contacts componentID={preAppID} edit={edit} stepNo={2} />
      </section>
      <section id='PreAppForm3PropertyOwners' name='Property Owners' style={propertyOwnerStyles}>
        <PreAppFormPropertyOwners componentID={preAppID} edit={edit} stepNo={3} />
      </section>
      <section id='PreAppForm3SupportingInformation' name='Supporting Information' style={supportingInfoStyles}>
        <SupportingInformation
          componentID={preAppID}
          subtext={<p>Upload supporting documentation including wetland datasheets, OHWM datasheets, figures supporting the delineation report, draft waters of the US delineation. In addition, for standard permit pre-application requests please provide draft project plans, alternative analysis, purpose and need, and avoidance/minimization/compensatory mitigation measures as needed. Refer to instructions for additional information.</p>}
          requestData={preAppData}
          updateRequestData={doUpdatePreAppRequest}
          stepNo={4}
        />
      </section>
      <section id='PreAppForm3CertifySignSubmit' name='Certify Sign Submit' style={certifySignSubmitStyles}>
        <PreAppForm3CertifySignSubmit stepNo={5} />
      </section>
    </>);

  });

export default PreAppForm3;
