import L from 'leaflet';
import 'leaflet.fullscreen';
import { useRef, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import './fullScreenControl.scss';

const FullscreenControl = (props) => {
  const map = useMap();
  const ctrl = useRef(L.control.fullscreen(props));

  useEffect(() => {
    ctrl.current.addTo(map);
    const control = ctrl.current;

    return () => {
      control.remove();
      control.link.remove();
    };
  });

  return null;
};

export default FullscreenControl;