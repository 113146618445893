import { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'redux-bundler-react';
import './HeaderCell.scss';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import { mdiCloseOctagon, mdiPlusBox } from '@mdi/js';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { tError, tSuccess } from '@src/utils/toast-helpers';

import './headerCell.scss';
import FileInput from '@components/file-input/FileInput';
import { ErrorMessages } from '@src/utils/enums';
import statusModal from '@src/app-pages/Forms/components/modals/statusModal';

const readFile = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = (e) => resolve(e.target.result);
  reader.onerror = (e) => reject(e.target.error);
  reader.readAsArrayBuffer(file);
});

const HeaderCell = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    table,
    hideDrag = false
  }) => {
    const meta = table.options.meta;
    const selectedRows = table.getSelectedRowModel().rows;
    const [selectAll, setSelectAll] = useState(false);
    const [fileNames, setFileNames] = useState();
    const columns = useMemo(() => table.getAllColumns(), [table]);
    const columnHeaders = columns?.map(column => column.columnDef?.header);

    const toggleSelectAll = () => {
      setSelectAll(!selectAll);
      table.toggleAllRowsSelected(!selectAll);
    };

    const removeRows = () => {
      meta.removeSelectedRows(
        table.getSelectedRowModel().rows.map((row) => row.index)
      );
      table.resetRowSelection();
    };

    const validateData = useCallback(async (rowData) => {
      const providedHeaders = [...new Set(rowData.flatMap(data => Object.keys(data)))];
      const headersMatch = providedHeaders?.every(header => columnHeaders.includes(header.trim()));
      // Validate if CSV headers match expected
      if (!headersMatch) {
        doSecondaryModalOpen(statusModal, { msg: ErrorMessages.InvalidHeaders });
      } else {
        rowData?.forEach(dataItem => {
          columns.forEach(column => {
            if (dataItem?.hasOwnProperty(column.columnDef.header)) {
              const columnValue = dataItem[column.columnDef.header];
              // Map to column.id
              if (column.id && !dataItem.hasOwnProperty(column.id)) {
                dataItem[column.id] = columnValue;
              }
            }
          });
        });
      }
    }, [columns, columnHeaders, doSecondaryModalOpen]);

    const handleFileInput = e => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setFileNames(filesArr);
    };

    const processFiles = useCallback(async () => {
      if (!fileNames || fileNames.length === 0) return;

      // Show a loading toast and store its ID
      const toastId = toast.loading('Processing file(s)...');

      try {
        const allRowData = [];
        for (const file of fileNames) {
          const fileContents = await readFile(file);
          const workbook = XLSX.read(fileContents, { type: 'buffer' });

          workbook.SheetNames.forEach(sheetName => {
            const sheet = workbook.Sheets[sheetName];
            for (const cell in sheet) {
              if (cell[0] === '!') continue;
              if (sheet[cell].v !== undefined && sheet[cell].v !== null) {
                sheet[cell].v = String(sheet[cell].v);
                sheet[cell].t = 's';
              }
            }
            const rowObject = XLSX.utils.sheet_to_json(sheet);
            allRowData.push({ sheetName, rowObject });
          });
          const flattenedData = allRowData.flatMap(entry => entry.rowObject);
          validateData(flattenedData);
        }
        tSuccess(toastId, 'File(s) processed successfully.');
      } catch (error) {
        console.error(error);
        tError(toastId, 'An error occurred while processing file(s).');
      }
    }, [fileNames, validateData]);

    useEffect(() =>{
      processFiles();
    }, [processFiles]);
    
    return (
      <div className='header-buttons'>
        <input
          type='checkbox'
          title='Select All'
          checked={selectAll}
          onChange={toggleSelectAll}
        />
        {selectedRows?.length > 0 && (
          <Button
            className={'remove-button'}
            title='Remove Selected Rows'
            size='small'
            onClick={removeRows}>
            <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
            Remove Selected Rows
          </Button> 
        )}
        <Button
          className='add-button'
          title='Add New Row'
          size='small'
          onClick={meta?.addRow}
        >
          <Icon focusable={false} className='mr-1' path={mdiPlusBox} size={'16px'} />
          Add New Row
        </Button> 
        {hideDrag === false && (
          <FileInput
            accept='.xlsx,.txt,.csv'
            id='bulk-file'
            name='bulk-file'
            onChange={handleFileInput}
            onDrop={handleFileInput}
          />
        )}
      </div>
    );
  });

export default HeaderCell;