import { connect } from 'redux-bundler-react';
import { ModalContent, ModalFooter } from '@components/modal';
import { useEffect } from 'react';

const NavbarNavigateWarningModal = connect(
  'doModalClose',
  'doUpdateRelativeUrl',
  'selectIsReadOnly',
  ({ 
    doModalClose, 
    doUpdateRelativeUrl,
    url, openInNewTab, 
    isReadOnly 
  }) => {
    const isInternal = openInNewTab;

    useEffect(() => {
      if (isReadOnly) {
        if (isInternal) {
          window.open(url, '_blank');
        } else {
          doUpdateRelativeUrl(url);
        }
        doModalClose();
      }
    }, [isReadOnly, isInternal, url, doUpdateRelativeUrl, doModalClose]);

    const handleNavigation = () => {
      doModalClose();
      if (isInternal) {
        window.open(url, '_blank');
      } else {
        doUpdateRelativeUrl(url);
      }
    };

    if (isReadOnly) {
      return null;
    }

    return (
      <ModalContent size='md' title={isInternal ? 'Internal Resource' : 'Warning!'}>
        <section className='modal-body'>
          {isInternal ? (
            <h6>You are now being redirected to an internal page of the application in a new tab.</h6>
          ) : (
            <h6>
              You are about to navigate away from the forms application; any unsaved work will be lost! <br /> <br />
              Press Cancel to go back and save progress, or OK to proceed.
            </h6>
          )}
        </section>
        <ModalFooter
          onSave={handleNavigation}
          onCancel={doModalClose}
          saveText='OK'
          customSaveLogic
        />
      </ModalContent>
    );
  }
);

export default NavbarNavigateWarningModal;
