import { Accordion } from '@trussworks/react-uswds';
import SPK_SACRAMENTO from './DistrictContent/SPD/SPD.SPK_Sacramento';
import SPN_SAN_FRANCISCO from './DistrictContent/SPD/SPD.SPN_San_Francisco';

const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Sacramento District (SPK)',
    content: <SPK_SACRAMENTO />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'San Francisco District (SPN)',
    content: <SPN_SAN_FRANCISCO />,
  },
];


const SPD = () => (
  <>
    <section id='spd'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default SPD;
