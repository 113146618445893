import Icon from '@components/icon/Icon';
import {
  mdiCloseCircle,
  mdiCheckboxMarkedCircle,
  mdiCheckboxBlankCircle,
  mdiCheckboxBlankCircleOutline,
} from '@mdi/js';

import Progress from './Progress';

import './Step.scss';

const Step = ({ children, last, status, touched, onStepClick, id }) => {
  let icon = '';
  let classes = '';
  let complete = false;
  let error = false;

  switch (true) {
    case status === 'error' && touched === 'true':
      icon = mdiCloseCircle;
      classes = 'is-error';
      complete = false;
      error = true;
      break;
    case status === 'complete' && touched === 'true':
      icon = mdiCheckboxMarkedCircle;
      classes = 'is-complete';
      complete = true;
      error = false;
      break;
    case status === 'activecomplete':
      icon = mdiCheckboxMarkedCircle;
      classes = 'is-active is-complete';
      complete = true;
      error = false;
      break;
    case status === 'activeerror' && touched === 'true':
      icon = mdiCloseCircle;
      classes = 'is-active is-error';
      complete = false;
      error = true;
      break;
    case status === 'activeerror':
      icon = mdiCheckboxBlankCircle;
      classes = 'is-active is-error';
      complete = false;
      error = false;
      break;
    default:
      icon = mdiCheckboxBlankCircleOutline;
      classes = '';
      complete = false;
      error = false;
  }

  return (
    <div
      id={id}
      onClick={onStepClick}
      onKeyUp={(e) => {
        e.key === 'Enter' && onStepClick(e);
      }}
      className={`step-container ${classes} v-stepper`}
      tabIndex={0}
    >
      <div id={id} className='step-icon'>
        <Icon focusable={false} id={id} path={icon} size={'21px'} />
      </div>
      <div id={id} className='step-progress'>
        {last === 'false' && <Progress id={id} complete={complete} error={error} />}
      </div>
      <div id={id} className='step-label'>
        <span id={id}>{children}</span>
      </div>
    </div>
  );
};

export default Step;
