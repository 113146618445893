import React from 'react';
import { Button } from '@trussworks/react-uswds';
import { mdiChat, mdiEye } from '@mdi/js';
import Icon from '@components/icon/Icon';

import './actionsTableCell.scss';
import { connect } from 'redux-bundler-react';
import PublicNoticeInfoModal from '../PublicNoticeInfoModal';
import PublicNoticeCommentModal from '../PublicNoticeCommentModal';

const ActionsTableCell = connect('doModalOpen', ({ doModalOpen, row }) => {
  const handleButtonClick = (action) => {
    if (action === 'comment') {
      doModalOpen(PublicNoticeCommentModal, { publicNoticeId: row.original.publicNoticeID });
    } else {
      doModalOpen(PublicNoticeInfoModal, { publicNoticeId: row.original.publicNoticeID });
    }
  };

  return (
    <div className='button-container'>
      <Button className={'view-notice'} title={'View Full Public Notice'} onClick={() => handleButtonClick('view')}>
        <Icon focusable={false} path={mdiEye} size={'16px'}/>
      </Button>
      <Button className={'submit-comments'} title={'Submit Comments'} onClick={() => handleButtonClick('comment')}>
        <Icon focusable={false} path={mdiChat} size={'16px'}/>
      </Button>
    </div>
  );
});

export default ActionsTableCell;
