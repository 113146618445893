import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRE_DETROIT = () => (
  <>
    <section id='lre-detroit'>
      <DistrictInfoTable district='LRE' orgEmail='LRE_REG_Michiana@usace.army.mil'/>
      <p>
      The RRS can also be used to apply for a standard permit from the Detroit District for work in the state of Indiana <span className='text-underline'>only.</span> Please note that the Regional General Permit Notification Form for Indiana and the Joint Application Form for Michigan are currently not available in RRS.
      </p> 
      <p>
        Those seeking a <ExternalLink content='permit in the state of Michigan' title='Michigan Permit' href='https://www.lrd.usace.army.mil/Wetlands-Permits/Article/3648190/regulatory-program-michigan/' /> should go to the Michigan Department of Environment, Great Lakes, and Energy website and complete the <ExternalLink content='joint permit application form' title='JPA Form' href='https://mienviro.michigan.gov/ncore/external/home' />. Once the application is submitted to the State of Michigan, the State will forward the permit application to the Detroit District via email.
      </p>
      <p>
        Those seeking a <ExternalLink content='permit in the state of Indiana' title='Indiana Permit' href='https://www.lrd.usace.army.mil/Wetlands-Permits/Article/3647635/regulatory-program-indiana/' /> using the Regional General Permit program or the Nationwide Permit program should go to the Indiana Department of Environmental Management website and complete form 51937 (<ExternalLink content='Wetlands: Section 401 WQC Regional General Permit Notification Form' title='Regional Permit Notification Form' href='https://www.in.gov/idem/forms/idem-agency-forms/#owq_stormwater'/>).  Once complete, the form can be submitted to <InternalLink content='LRE_REG_Michiana@usace.army.mil' href='mailto:LRE_REG_Michiana@usace.army.mil' title='LRE_REG_Michiana@usace.army.mil' />.  Those seeking a standard permit should utilize the RRS to apply.
      </p>
    </section>
  </>
);

export default LRE_DETROIT;
