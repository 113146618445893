import { Accordion, Table } from '@trussworks/react-uswds';

import FormsAndAppendicesJd from './FormsAndAppendices.jd';
import FormsAndAppendicesPreConstruction from './FormsAndAppendices.preConstruction';
import FormsAndAppendicesWotus from './FormsAndAppendices.wotus';
import FormsAndAppendicesIndividualPermit from './FormsAndAppendices.individualPermit';
import FormsAndAppendicesReportViolation from './FormsAndAppendices.reportViolation';
import FormsAndAppendicesPreApplication from './FormsAndAppendices.preApplication';

export const TableWrapper = ({ children }) => (
  <Table bordered fullWidth>
    <thead>
      <tr>
        <th scope='col'>Document Name</th>
        <th scope='col'>Purpose</th>
      </tr>
    </thead>
    <tbody valign='top'>{children}</tbody>
  </Table>
);

const requestTypeAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Request Pre-Application Meeting or Project Scoping',
    content: <FormsAndAppendicesPreApplication />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'Jurisdictional Determination',
    content: <FormsAndAppendicesJd />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'WOTUS Delineation Report and Aquatic Resource (AR) Inventory',
    content: <FormsAndAppendicesWotus />,
  },
  {
    id: '4',
    headingLevel: 'h3',
    title: 'Pre-Construction Notification (DA 6082)',
    content: <FormsAndAppendicesPreConstruction />,
  },
  {
    id: '5',
    headingLevel: 'h3',
    title: 'Individual Permit Application (DA 4345)',
    content: <FormsAndAppendicesIndividualPermit />,
  },
  {
    id: '6',
    headingLevel: 'h3',
    title: 'Report Violation',
    content: <FormsAndAppendicesReportViolation />,
  },
];

const FormsAndAppendices = () => (
  <>
    <p>
      Background - As you enter information throughout the RRS, that data is used to generate forms and appendices which
      are then sent to USACE staff to assist them in your request review.{' '}
      <span className='text-bold'>
        You do not need to fill out the forms ahead of time, but they are being provided to help you collect information
        prior to entering a request. The RRS will generate the forms and appendices for you (unless noted below – two
        forms are to be uploaded by applicants or agents – the Agent Authorization Form and the Right of Entry Form)
      </span>
      . Not all blocks on the forms/appendices will contain data, some fields are optional and are clearly marked on the
      screen as such. Other fields are mandatory and are marked with a red asterisk. Not all appendices will be visible.
      The appendices will only be generated if the information entered is applicable.
    </p>
    <h6>Request Type</h6>
    <div className='container margin-bottom-2'>
      <Accordion bordered items={requestTypeAccordion} className='margin-top-2' multiselectable={true} />
    </div>
  </>
);

export default FormsAndAppendices;
