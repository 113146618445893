const requestDataBundle = {
  name: 'requestdata',

  getReducer: () => {
    const initialState = {
      formData: {
        projectID: null,
        requestID: null,
        projectName: '',
        projectDescription: '',
        natureOfActivity: '',
        projectPurpose: '',
        request: {
          requestType: '',
          ownerEntryComments: null,
          agentAuthComments: null,
          ormDANumber: null,
          signature: null,
          signatureDate: null,
          requestStatus: null,
        },
        location: {},
        previousFileNumbers: [],
      },
      apiData: {}
    };
    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'RESET_REQUEST_DATA':
          return initialState;
        case 'UPDATE_REQUEST_FORM_DATA':
          return {
            ...state,
            formData: {
              ...state.formData,
              ...payload
            },
          };
        case 'UPDATE_REQUEST_API_DATA':
          return {
            ...state,
            apiData: {
              ...payload
            },
          };
        case 'UPDATE_REQUEST_OBJECT':
          return {
            ...state,
            formData: {
              ...state.formData,
              request: { ...state.formData.request, ...payload },
            },
          };
        case 'UPDATE_REQUEST_CONTACTS':
          return {
            ...state,
            formData: {
              ...state.formData,
              request: {
                ...state.formData.request,
                contacts: payload,
              }
            },
          };
        case 'UPDATE_REQUEST_LOCATION':
          return {
            ...state,
            formData: {
              ...state.formData,
              location: { ...state.formData.location, ...payload },
            },
          };
        case 'UPDATE_REQUEST_AQUATIC_RESOURCES':
          return {
            ...state,
            formData: {
              ...state.formData,
              request: {
                ...state.formData.request,
                aquaticResources: payload,
              }
            },
          };
        case 'UPDATE_REQUEST_PREVIOUS_FILE_NUMBERS':
          return {
            ...state,
            formData: {
              ...state.formData,
              previousFileNumbers: payload,
            },
          };
        case 'CLEAR_REQUEST_LOCATION_DATA':
          return {
            ...state,
            formData: {
              ...state.formData,
              location: {},
            },
          };
        default:
          return state;
      }
    };
  },

  selectRequestAPIData: (state) => state.requestdata.apiData,
  selectRequestFormData: (state) => state.requestdata.formData,
  selectRequestFormDataContacts: (state) => state.requestdata.formData.request.contacts,
  selectProjectID: (state) => state.requestdata.formData.projectID,
  selectRequestFormDataLocation: (state) => state.requestdata.formData.location,
  selectRequestID: (state) => state.requestdata.formData.requestID,
  selectVersion: (state) => state.requestdata.formData.version || 0,
  selectRequestStatus: (state) => state.requestdata.formData.request.requestStatus,
  doUpdateRequestData: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_REQUEST_FORM_DATA', payload: data });
  },
  doUpdateRequestObject: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_REQUEST_OBJECT', payload: data });
  },
  doUpdateRequestContacts: (data) => ({ dispatch, store }) => {
    dispatch({ type: 'UPDATE_REQUEST_CONTACTS', payload: data });
  },
  doClearContactType: (contactType) => ({ dispatch, store }) => {
    const filteredArr = store.selectRequestFormDataContacts().filter(contact => contact.contactType !== contactType);
    dispatch({ type: 'UPDATE_REQUEST_CONTACTS', payload: filteredArr });
  },
  doUpdateRequestAquaticResources: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_REQUEST_AQUATIC_RESOURCES', payload: data });
  },
  doUpdateRequestLocation: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_REQUEST_LOCATION', payload: data });
  },
  doClearRequestLocation: () => ({dispatch}) => {
    dispatch({ type: 'CLEAR_REQUEST_LOCATION_DATA'});
  },
  doUpdateRequestPreviousFileNumbers: (data) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_REQUEST_PREVIOUS_FILE_NUMBERS', payload: data });
  },
  doResetRequestData: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_REQUEST_DATA' });
  },
  doResetReduxFormData: () => ({ store }) => {
    store.doResetMap();
    store.doResetProjectAddress();
    store.doResetGeocodingResults();
    store.doResetFileObjectState();
    store.doClearProjectData();
    store.doResetStepper();
    store.doResetContactInfoState();
    store.doResetTables();
    store.doResetJDFormData();
    store.doResetPermitData();
    store.doResetSectionState();
    store.doResetPLSSAPIResults();
    store.doResetDistrictAPIResults();
    store.doResetFormValidity();
    store.doResetUsersFileData();
    store.doResetComplexState();
    store.doResetRequestData();
    store.doResetPreAppRequest();
    store.doResetViolationComplaintRequest();
    store.doResetSelectedRequest();
  },

};

export default requestDataBundle;