import React from 'react';
import { classArray } from '../../utils/helpers.jsx';

import './tab.scss';

const TabItem = ({ tab, onClick, index, isActive }) => {
  const { title, isHidden } = tab;

  const liClasses = classArray([
    'nav-item',
    isActive && 'active',
    isHidden && 'd-none',
  ]);

  return (
    <>
      <li className={liClasses} onClick={() => onClick(title, index)}>
        <span className='nav-link'>
          {title}
        </span>
      </li>
    </>
  );
};

export default TabItem;