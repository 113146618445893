import React from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import { connect } from 'redux-bundler-react';

import PopupRenderer from './PopupRenderer';
import RequestStatusRenderer from './requestStatusRenderer';
import PMEmailRender from './PMEmailRender';

import { dateFormatter } from '../../../utils/ag-grid-helpers';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import '../dashboard.scss';

const DashboardTable = connect(
  ({ rowData, msg }) => (
    <>
      <div className='ag-theme-balham dashboard-table mt-2'>
        <AgGridReact
          rowHeight={35}
          pagination={true}
          paginationAutoPageSize={true}
          rowData={(rowData === null || rowData === undefined) ? [] : rowData}
          defaultColDef={{ width: 150 }}
          components={{
            'popupRenderer': PopupRenderer,
            'requestStatusRenderer': RequestStatusRenderer
          }}
          overlayNoRowsTemplate={`<span>${msg ? msg : 'No projects or requests found'}</span>`}
        >
          <AgGridColumn field='Actions' headerName='Actions' cellRenderer='popupRenderer' width={84} />
          <AgGridColumn field='requestStatus' headerName='Request Status' width={175} cellRenderer='requestStatusRenderer' sortable unSortIcon />
          <AgGridColumn field='projectName' headerName='Project Name' width={175} sortable unSortIcon resizable />
          <AgGridColumn field='requestType' headerName='Request Type' width={250} sortable unSortIcon resizable />
          <AgGridColumn field='district' headerName='District' width={200} sortable unSortIcon />
          <AgGridColumn field='createdDate' valueGetter={params => dateFormatter(params.data.createdDate)} width={150} sortable sort='desc' unSortIcon />
          <AgGridColumn field='daNumber' headerName='DA Number' sortable unSortIcon />
          <AgGridColumn field='pmName' headerName='Project Manager Name' width={250} sortable unSortIcon cellRenderer={(params) => (<PMEmailRender value={params.value} pmEmail={params.data.pmEmail} onClickEmail={(email) => {window.location.href = `mailto:${email}`;}} />)} />
          <AgGridColumn field='pmContactInfo' headerName='Project Manager Phone #' width={250} sortable unSortIcon />
        </AgGridReact>
      </div>
    </>
  )
);

export default DashboardTable;
