import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRN_NASHVILLE = () => (
  <>
    <section id='lrn-nashville'>
      <DistrictInfoTable district='LRN'/>
      <p>
      Please note, for projects located in the Tennessee River Basin, a Tennessee Valley Authority (TVA) 26a permit may be required, and a DA/TVA Joint Application Form should be completed.  Please be sure to submit the DA/TVA Joint Application Form, and all required supplemental materials to TVA, as necessary.  The <ExternalLink content='Nashville District website' title='Nashville District Website' href='https://www.lrn.usace.army.mil/Missions/Regulatory/Obtain-a-Permit/' /> contains more information on obtaining a Department of the Army permit and the application form.
      </p>
    </section>
  </>
);

export default LRN_NASHVILLE;
