import { convertGeoJsonToEsri } from '@src/utils/helpers';

const geoStateValidationApiBundle = {
  name: 'geostatevalidationapi',

  getReducer: () => {
    const initialData = {
      validationState: null,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'SET_VALIDATION_STATE':
          return {
            ...state,
            validationState: payload,
          };
        case 'RESET_VALIDATION_STATE':
          return initialData;
        default:
          return state;
      }
    };
  },

  doGetValidateStateWithCoordinates: (latitude, longitude, state) => 
    async ({ dispatch }) => {
      if (!state) {
        dispatch({ type: 'SET_VALIDATION_STATE', payload: false });
        console.warn('No state provided for validation.');
        return false;
      }
      const validationResult = await dispatch(geoStateValidationApiBundle.doGetValidateGeometryInState({
        type: 'Point',
        coordinates: [Number(longitude), Number(latitude)],
      }));
      const isValid = validationResult === state.toUpperCase();
      dispatch({ type: 'SET_VALIDATION_STATE', payload: isValid });
      return isValid;
    },

  doGetValidateGeometryInState: (geometry) => 
    async ({ dispatch }) => {
      const esriGeometry = convertGeoJsonToEsri(geometry);
      const geometryType = (() => {
        switch (geometry?.type?.toUpperCase()) {
          case 'POINT':
            return 'esriGeometryPoint';
          case 'MULTIPOINT':
            return 'esriGeometryMultipoint';
          case 'LINESTRING':
            return 'esriGeometryPolyline';
          case 'POLYGON':
            return 'esriGeometryPolygon';
          default:
            console.error('Unsupported geometry type:', geometry?.type);
            return undefined;
        }
      })();
  
      const url = 'https://services2.arcgis.com/C8EMgrsFcRFL6LrL/ArcGIS/rest/services/US_States_and_Territories/FeatureServer/0/query';
      const params = new URLSearchParams({
        f: 'json',
        where: '1=1',
        geometry: JSON.stringify(esriGeometry),
        geometryType: geometryType,
        spatialRel: 'esriSpatialRelIntersects',
        returnGeometry: true,
        returnDistinctValues: false,
        inSR: 4326,
        outFields: 'STATE_NAME',
      });

      try {
        const response = await fetch(`${url}?${params}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        const data = await response.json();
  
        if (data?.features?.length > 0) {
          return data.features[0].attributes.STATE_NAME.toUpperCase();
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error retrieving state:', error);
        return false;
      }
    },

  doResetValidationState: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_VALIDATION_STATE' });
  },
  selectStateResults: (state) => state.geostatevalidationapi.validationState,
};
export default geoStateValidationApiBundle;
