const arcgisServicesBundle = {
  name: 'arcgisservices',
  getReducer: () => {
    const initialData = [];
    return (state = initialData, { type, payload }) => state;
  },
  doGetCountyListByState: (state) => async () => {
 
    const url = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Census_Counties/FeatureServer/0/query';
    const params = new URLSearchParams({
      f: 'json',
      where: `STATE_NAME='${state}'`,
      returnGeometry: false,
      returnDistinctValues: true,
      outFields: 'NAME'
    });

    try {
      const response = await fetch(`${url}?${params}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      const data = await response.json();

      const counties = data?.features?.map(feature => feature?.attributes?.NAME);

      return counties;

    } catch (error) {
      console.error('Error retrieving county list for selected state: ', error);
      return false;
    }

  }
};
export default arcgisServicesBundle;