import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const ImpactTableCell = ({
  getValue,
  row,
  column,
  table,
  cell
}) => {
  const columnMeta = column.columnDef.meta;
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const type = columnMeta?.type ?? 'text';
  const initialOptions = columnMeta?.options;
  const [value, setValue] = useState(initialValue);
  const isPermanentLoss = useMemo(() => row.getValue('isPermanentLoss'), [row]);
  const debouncedUpdateRef = useRef();
  const [impactOptions, setImpactOptions] = useState(initialOptions);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef?.current?.(newValue);
  }, []);

  useEffect(() => {
    if (isPermanentLoss === 'true' && value !== initialOptions?.[1]?.value) {
      setImpactOptions([initialOptions?.[1]]);
      setValue(initialOptions?.[1]?.value);
      updateValue(initialOptions?.[1]?.value);
    }
    else if (isPermanentLoss === 'false' && impactOptions !== initialOptions) {
      setImpactOptions(initialOptions);
    }
  }, [isPermanentLoss, initialOptions, updateValue, value, impactOptions]);

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta?.updateData(row.index, column.id, type === 'number' ? Number(newValue) : newValue ?? newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, type, tableMeta]);

  const handleBlur = (e) => {
    //tableMeta?.updateData(row.index, column.id, columnMeta?.type === 'number' ? Number(e.target.value) : e.target.value ?? e.target.value);
  };

  const handleChange = (e) => {
    setValue(e?.target?.value);
    updateValue(e?.target?.value);
  };

  return type === 'select' && (
    <select
      aria-label={'Impact Duration'}
      disabled={columnMeta?.readOnly}
      id={cell.id}
      onBlur={handleBlur}
      onChange={handleChange}
      required={columnMeta?.required}
      style={{ width: '100%' }}
      value={value ?? ''}>
      <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
      {(impactOptions).map((option) => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};