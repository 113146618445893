import { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Alert, Label } from '@trussworks/react-uswds';

import LinkButton from '@components/link/linkButton';
import FileInput from '@components/file-input/FileInput';
import TextAreaInput from '@components/textarea/TextArea';

import { useFormContext } from 'react-hook-form';

import { ContactsFormNames, ErrorMessages, FileTypes, ResourceFiles } from '@src/utils/enums';

const AgentAuthorizationUpload = connect(
  'doDeleteFile',
  'doDownloadFile',
  'doDownloadPopulatedFile',
  'doResetFileSection',
  'doUpdateRequestObject',
  'doUpdateSelectedFiles',
  'selectRightOfEntryFile',
  'selectAgentAuthorizationFile',
  'selectSupportingDocFiles',
  'selectIsReadOnly',
  'selectSelectedRequest',
  ({
    doDeleteFile,
    doDownloadFile,
    doDownloadPopulatedFile,
    doResetFileSection,
    doUpdateRequestObject,
    doUpdateSelectedFiles,
    rightOfEntryFile,
    agentAuthorizationFile,
    supportingDocFiles,
    isReadOnly,
    selectedRequest,
    componentID
  }) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileErrors, setFileErrors] = useState(false);
    const isSelectedFile = selectedFile?.length > 0;
    const isS3File = !!agentAuthorizationFile?.[0]?.key;

    const { setValue, watch, trigger } = useFormContext();
    const agentAuth = watch('agentAuth');
    const agentAuthComments = watch('agentAuthComments');

    const setFileValue = useCallback(() => {
      if (agentAuth?.length === 0) {
        return null;
      }
      if (agentAuthorizationFile?.length > 0) {
        return fileErrors === true ? 'error' : 'true';
      } else {
        return null;
      }
    }, [agentAuth?.length, fileErrors, agentAuthorizationFile]);

    const handleFileInput = e => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setSelectedFile(filesArr);
      trigger('agentAuth');
    };

    const handleDeleteFile = () => {
      isS3File && doDeleteFile(agentAuthorizationFile?.[0]?.fileName, componentID);
      doResetFileSection(ContactsFormNames.AgentAuthorization);
      setSelectedFile([]);
      setValue('agentAuth', '', { shouldValidate: true });
    };

    // Set selected file if valid
    useEffect(() => {
      const doSelectFile = () => {
        const agentFile = {
          componentID: componentID,
          file: selectedFile?.[0],
          fileName: selectedFile?.[0]?.name,
          isInvalid: fileErrors === true,
          section: ContactsFormNames.AgentAuthorization
        };
        doUpdateSelectedFiles(agentFile);
      };
      if (isSelectedFile === true) {
        doResetFileSection(ContactsFormNames.AgentAuthorization);
        doSelectFile();
      }
    }, [selectedFile, doResetFileSection, componentID, isSelectedFile, doUpdateSelectedFiles, fileErrors]);

    useEffect(() => {
      setValue('agentAuth', setFileValue(), { shouldValidate: true });
    }, [setFileValue, setValue]);

    // Set agentAuthComments value, if any
    useEffect(() => {
      doUpdateRequestObject({ agentAuthComments: agentAuthComments });
    }, [agentAuthComments, doUpdateRequestObject]);

    useEffect(() => {
      // Reset section validation and file input validation
      setFileErrors(false);
    }, [rightOfEntryFile.length, supportingDocFiles.length]);

    return (
      <>
        <Label className='h6 border-bottom w-100 padding-bottom-1 margin-top-2' htmlFor='agentAuth'>
          <span id='agentAuth_label'>Agent Authorization</span>
          <span className='asterisk-color'>*</span>
        </Label>
        <Alert type='info'>
          <p>The agent must provide an Agent Authorization form from the applicant. {(selectedRequest !== '3' || selectedRequest !== '3') && <>If a site visit is needed, you, as the agent must also provide a <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6294, FileTypes.Resource)} title='Right of Entry form' content='Right of Entry form' />.</>}</p>
          <p><LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6295, FileTypes.Resource)} title='Download a blank Agent Authorization form' content='Download a blank Agent Authorization form' /> to complete or <LinkButton onClick={() => doDownloadPopulatedFile(ContactsFormNames.AgentAuthorization, ResourceFiles.Eng6295)} title='download an Agent Authorization form populated' content='download an Agent Authorization form populated' /> with the information you entered. Note that both documents will need to be signed by the applicant prior to submittal.</p>
          <p className='text-bold'>{ErrorMessages.UploadNotice}</p>
        </Alert>
        <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-3'>
          <FileInput
            accept='.docx,.pdf'
            doDownloadFile={() => doDownloadFile(agentAuthorizationFile?.[0]?.fileName, FileTypes.Request)}
            doDeleteFile={() => handleDeleteFile()}
            dragText='Drag your Agent Authorization Form here or '
            hint='Select a .DOCX or .PDF file'
            id='agentAuth'
            label='Input accepts a single, DOCX or PDF file'
            name='agentAuth'
            onChange={handleFileInput}
            onDrop={handleFileInput}
            readOnly={isS3File || isReadOnly}
            required
            setFileErrors={setFileErrors}
            resetFileErrors={!fileErrors}
            showDownloadButton={isS3File}
            showDeleteButton={(isSelectedFile === true || isS3File) && !isReadOnly}
            value={agentAuthorizationFile}
          />
        </div>

        <div className='row'>
          <div className='col'>
            <TextAreaInput name='agentAuthComments' label='Agent Authorization Comment' readOnly={isReadOnly} />
          </div>
        </div>
      </>
    );}
);

export default AgentAuthorizationUpload;
