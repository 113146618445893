import * as yup from 'yup';
import { ErrorMessages } from '@src/utils/enums';
import { usPhoneRegex, phoneRegex, emailRegex, addressRegex, addressTwoRegex, cityRegex, zipCodeRegex, phoneExtRegex, countryCodeRegex } from '@src/utils/regex';

const addressRequired = {
  address: yup.string().required(ErrorMessages.Required).matches(addressRegex, ErrorMessages.RestrictedChars),
  addressTwo: yup.string().nullable().matches(addressTwoRegex, ErrorMessages.RestrictedChars),
  city: yup.string().matches(cityRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }).required(ErrorMessages.Required),
  state: yup.string().when('country', {
    is: 'US',
    then: (schema) => schema.required(ErrorMessages.SelectOption),
    otherwise: (schema) => schema.required(ErrorMessages.SelectOption),
  }),
  zipcode: yup.string().when('country', {
    is: 'US',
    then: (schema) => schema.matches(zipCodeRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }).required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().required(ErrorMessages.SelectOption),
};

const addressOptional = {
  address: yup.string().when(['city', 'state', 'country', 'zipcode'], {
    is:(a, b, c, d) => !!a?.length || !!b?.length || !!c?.length || !!d?.length,
    then: (schema) => schema.required(ErrorMessages.Required).matches(addressRegex, ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  country: yup.string().when(['address', 'city', 'state', 'zipcode'], {
    is:(a, b, c, d) => !!a?.length || !!b?.length || !!c?.length || !!d?.length,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  city: yup.string().when(['address', 'state', 'country', 'zipcode'], {
    is:(a, b, c, d) => !!a?.length || !!b?.length || !!c?.length || !!d?.length,
    then: (schema) => schema.required(ErrorMessages.Required).matches(cityRegex, ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  state: yup.string().when(['address', 'city', 'country', 'zipcode'], {
    is:(a, b, c, d) => !!a?.length || !!b?.length || !!c?.length || !!d?.length,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  zipcode: yup.string().when(['address', 'state', 'city', 'country'], {
    is:(a, b, c, d) => (!!a?.length || !!b?.length || !!c?.length || !!d?.length) && (d === 'US' || !d),
    then: (schema) => schema.required(ErrorMessages.Required).matches(zipCodeRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable(),
  }),
};

const addressOptionalDependencies = [
  // all permutations are required as dependencies to avoid cyclic dependency error
  //   1        2        3        4           5
  ['address', 'city', 'state', 'country', 'zipcode'], // 1, 2, 3, 4, 5
  ['address', 'city', 'state', 'zipcode', 'country'], // 1, 2, 3, 5, 4
  ['address', 'city', 'country', 'state', 'zipcode'], // 1, 2, 4, 3, 5
  ['address', 'city', 'country', 'zipcode', 'state'], // 1, 2, 4, 5, 3
  ['address', 'city', 'zipcode', 'state', 'country'], // 1, 2, 5, 3, 4
  ['address', 'city', 'zipcode', 'country', 'state'], // 1, 2, 5, 4, 3
  ['address', 'state', 'city', 'country', 'zipcode'], // 1, 3, 2, 4, 5
  ['address', 'state', 'city', 'zipcode', 'country'], // 1, 3, 2, 5, 4
  ['address', 'state', 'country', 'city', 'zipcode'], // 1, 3, 4, 2, 5
  ['address', 'state', 'country', 'zipcode', 'city'], // 1, 3, 4, 5, 2
  ['address', 'state', 'zipcode', 'city', 'country'], // 1, 3, 5, 2, 4
  ['address', 'state', 'zipcode', 'country', 'city'], // 1, 3, 5, 4, 2
  ['address', 'country', 'city', 'state', 'zipcode'], // 1, 4, 2, 3, 5
  ['address', 'country', 'city', 'zipcode', 'state'], // 1, 4, 2, 5, 3
  ['address', 'country', 'state', 'city', 'zipcode'], // 1, 4, 3, 2, 5
  ['address', 'country', 'state', 'zipcode', 'city'], // 1, 4, 3, 5, 2
  ['address', 'country', 'zipcode', 'city', 'state'], // 1, 4, 5, 2, 3
  ['address', 'country', 'zipcode', 'state', 'city'], // 1, 4, 5, 3, 2
  ['address', 'zipcode', 'city', 'state', 'country'], // 1, 5, 2, 3, 4
  ['address', 'zipcode', 'city', 'country', 'state'], // 1, 5, 2, 4, 3
  ['address', 'zipcode', 'state', 'city', 'country'], // 1, 5, 3, 2, 4
  ['address', 'zipcode', 'state', 'country', 'city'], // 1, 5, 3, 4, 2
  ['address', 'zipcode', 'country', 'city', 'state'], // 1, 5, 4, 2, 3
  ['address', 'zipcode', 'country', 'state', 'city'], // 1, 5, 4, 3, 2
  ['city', 'address', 'state', 'country', 'zipcode'], // 2, 1, 3, 4, 5
  ['city', 'address', 'state', 'zipcode', 'country'], // 2, 1, 3, 5, 4
  ['city', 'address', 'country', 'state', 'zipcode'], // 2, 1, 4, 3, 5
  ['city', 'address', 'country', 'zipcode', 'state'], // 2, 1, 4, 5, 3
  ['city', 'address', 'zipcode', 'state', 'country'], // 2, 1, 5, 3, 4
  ['city', 'address', 'zipcode', 'country', 'state'], // 2, 1, 5, 4, 3
  ['city', 'state', 'address', 'country', 'zipcode'], // 2, 3, 1, 4, 5
  ['city', 'state', 'address', 'zipcode', 'country'], // 2, 3, 1, 5, 4
  ['city', 'state', 'country', 'address', 'zipcode'], // 2, 3, 4, 1, 5
  ['city', 'state', 'country', 'zipcode', 'address'], // 2, 3, 4, 5, 1
  ['city', 'state', 'zipcode', 'address', 'country'], // 2, 3, 5, 1, 4
  ['city', 'state', 'zipcode', 'country', 'address'], // 2, 3, 5, 4, 1
  ['city', 'country', 'address', 'state', 'zipcode'], // 2, 4, 1, 3, 5
  ['city', 'country', 'address', 'zipcode', 'state'], // 2, 4, 1, 5, 3
  ['city', 'country', 'state', 'address', 'zipcode'], // 2, 4, 3, 1, 5
  ['city', 'country', 'state', 'zipcode', 'address'], // 2, 4, 3, 5, 1
  ['city', 'country', 'zipcode', 'address', 'state'], // 2, 4, 5, 1, 3
  ['city', 'country', 'zipcode', 'state', 'address'], // 2, 4, 5, 3, 1
  ['city', 'zipcode', 'address', 'state', 'country'], // 2, 5, 1, 3, 4
  ['city', 'zipcode', 'address', 'country', 'state'], // 2, 5, 1, 4, 3
  ['city', 'zipcode', 'state', 'address', 'country'], // 2, 5, 3, 1, 4
  ['city', 'zipcode', 'state', 'country', 'address'], // 2, 5, 3, 4, 1
  ['city', 'zipcode', 'country', 'address', 'state'], // 2, 5, 4, 1, 3
  ['city', 'zipcode', 'country', 'state', 'address'], // 2, 5, 4, 3, 1
  ['state', 'address', 'city', 'country', 'zipcode'], // 3, 1, 2, 4, 5
  ['state', 'address', 'city', 'zipcode', 'country'], // 3, 1, 2, 5, 4
  ['state', 'address', 'country', 'city', 'zipcode'], // 3, 1, 4, 2, 5
  ['state', 'address', 'country', 'zipcode', 'city'], // 3, 1, 4, 5, 2
  ['state', 'address', 'zipcode', 'city', 'country'], // 3, 1, 5, 2, 4
  ['state', 'address', 'zipcode', 'country', 'city'], // 3, 1, 5, 4, 2
  ['state', 'city', 'address', 'country', 'zipcode'], // 3, 2, 1, 4, 5
  ['state', 'city', 'address', 'zipcode', 'country'], // 3, 2, 1, 5, 4
  ['state', 'city', 'country', 'address', 'zipcode'], // 3, 2, 4, 1, 5
  ['state', 'city', 'country', 'zipcode', 'address'], // 3, 2, 4, 5, 1
  ['state', 'city', 'zipcode', 'address', 'country'], // 3, 2, 5, 1, 4
  ['state', 'city', 'zipcode', 'country', 'address'], // 3, 2, 5, 4, 1
  ['state', 'country', 'address', 'city', 'zipcode'], // 3, 4, 1, 2, 5
  ['state', 'country', 'address', 'zipcode', 'city'], // 3, 4, 1, 5, 2
  ['state', 'country', 'city', 'address', 'zipcode'], // 3, 4, 2, 1, 5
  ['state', 'country', 'city', 'zipcode', 'address'], // 3, 4, 2, 5, 1
  ['state', 'country', 'zipcode', 'address', 'city'], // 3, 4, 5, 1, 2
  ['state', 'country', 'zipcode', 'city', 'address'], // 3, 4, 5, 2, 1
  ['state', 'zipcode', 'address', 'city', 'country'], // 3, 5, 1, 2, 4
  ['state', 'zipcode', 'address', 'country', 'city'], // 3, 5, 1, 4, 2
  ['state', 'zipcode', 'city', 'address', 'country'], // 3, 5, 2, 1, 4
  ['state', 'zipcode', 'city', 'country', 'address'], // 3, 5, 2, 4, 1
  ['state', 'zipcode', 'country', 'address', 'city'], // 3, 5, 4, 1, 2
  ['state', 'zipcode', 'country', 'city', 'address'], // 3, 5, 4, 2, 1
  ['country', 'address', 'city', 'state', 'zipcode'], // 4, 1, 2, 3, 5
  ['country', 'address', 'city', 'zipcode', 'state'], // 4, 1, 2, 5, 3
  ['country', 'address', 'state', 'city', 'zipcode'], // 4, 1, 3, 2, 5
  ['country', 'address', 'state', 'zipcode', 'city'], // 4, 1, 3, 5, 2
  ['country', 'address', 'zipcode', 'city', 'state'], // 4, 1, 5, 2, 3
  ['country', 'address', 'zipcode', 'state', 'city'], // 4, 1, 5, 3, 2
  ['country', 'city', 'address', 'state', 'zipcode'], // 4, 2, 1, 3, 5
  ['country', 'city', 'address', 'zipcode', 'state'], // 4, 2, 1, 5, 3
  ['country', 'city', 'state', 'address', 'zipcode'], // 4, 2, 3, 1, 5
  ['country', 'city', 'state', 'zipcode', 'address'], // 4, 2, 3, 5, 1
  ['country', 'city', 'zipcode', 'address', 'state'], // 4, 2, 5, 1, 3
  ['country', 'city', 'zipcode', 'state', 'address'], // 4, 2, 5, 3, 1
  ['country', 'state', 'address', 'city', 'zipcode'], // 4, 3, 1, 2, 5
  ['country', 'state', 'address', 'zipcode', 'city'], // 4, 3, 1, 5, 2
  ['country', 'state', 'city', 'address', 'zipcode'], // 4, 3, 2, 1, 5
  ['country', 'state', 'city', 'zipcode', 'address'], // 4, 3, 2, 5, 1
  ['country', 'state', 'zipcode', 'address', 'city'], // 4, 3, 5, 1, 2
  ['country', 'state', 'zipcode', 'city', 'address'], // 4, 3, 5, 2, 1
  ['country', 'zipcode', 'address', 'city', 'state'], // 4, 5, 1, 2, 3
  ['country', 'zipcode', 'address', 'state', 'city'], // 4, 5, 1, 3, 2
  ['country', 'zipcode', 'city', 'address', 'state'], // 4, 5, 2, 1, 3
  ['country', 'zipcode', 'city', 'state', 'address'], // 4, 5, 2, 3, 1
  ['country', 'zipcode', 'state', 'address', 'city'], // 4, 5, 3, 1, 2
  ['country', 'zipcode', 'state', 'city', 'address'], // 4, 5, 3, 2, 1
  ['zipcode', 'address', 'city', 'state', 'country'], // 5, 1, 2, 3, 4
  ['zipcode', 'address', 'city', 'country', 'state'], // 5, 1, 2, 4, 3
  ['zipcode', 'address', 'state', 'city', 'country'], // 5, 1, 3, 2, 4
  ['zipcode', 'address', 'state', 'country', 'city'], // 5, 1, 3, 4, 2
  ['zipcode', 'address', 'country', 'city', 'state'], // 5, 1, 4, 2, 3
  ['zipcode', 'address', 'country', 'state', 'city'], // 5, 1, 4, 3, 2
  ['zipcode', 'city', 'address', 'state', 'country'], // 5, 2, 1, 3, 4
  ['zipcode', 'city', 'address', 'country', 'state'], // 5, 2, 1, 4, 3
  ['zipcode', 'city', 'state', 'address', 'country'], // 5, 2, 3, 1, 4
  ['zipcode', 'city', 'state', 'country', 'address'], // 5, 2, 3, 4, 1
  ['zipcode', 'city', 'country', 'address', 'state'], // 5, 2, 4, 1, 3
  ['zipcode', 'city', 'country', 'state', 'address'], // 5, 2, 4, 3, 1
  ['zipcode', 'state', 'address', 'city', 'country'], // 5, 3, 1, 2, 4
  ['zipcode', 'state', 'address', 'country', 'city'], // 5, 3, 1, 4, 2
  ['zipcode', 'state', 'city', 'address', 'country'], // 5, 3, 2, 1, 4
  ['zipcode', 'state', 'city', 'country', 'address'], // 5, 3, 2, 4, 1
  ['zipcode', 'state', 'country', 'address', 'city'], // 5, 3, 4, 1, 2
  ['zipcode', 'state', 'country', 'city', 'address'], // 5, 3, 4, 2, 1
  ['zipcode', 'country', 'address', 'city', 'state'], // 5, 4, 1, 2, 3
  ['zipcode', 'country', 'address', 'state', 'city'], // 5, 4, 1, 3, 2
  ['zipcode', 'country', 'city', 'address', 'state'], // 5, 4, 2, 1, 3
  ['zipcode', 'country', 'city', 'state', 'address'], // 5, 4, 2, 3, 1
  ['zipcode', 'country', 'state', 'address', 'city'], // 5, 4, 3, 1, 2
  ['zipcode', 'country', 'state', 'city', 'address'], // 5, 4, 3, 2, 1
];

const emailOptional = {
  emailAddress: yup.string().matches(emailRegex, { message: 'Field is invalid', excludeEmptyString: true }).nullable(),
};

const emailRequired = {
  emailAddress: yup.string().required('Field is required').matches(emailRegex, 'Field is invalid'),
};

const faxOptional = {
  faxCountryCode: yup.string().when('faxPhone', {
    is: (val) => !!val?.length,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable(),
  }),
  faxPhone: yup.string().when('faxCountryCode', {
    is: (val) => val === '1',
    then: (schema) => schema.matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}).nullable(),
    otherwise: (schema) => schema.matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}).nullable(),
  }),
};
const faxOptionalDependencies = ['faxCountryCode', 'faxPhone'];

const nameRequired = {
  firstName: yup.string().required(ErrorMessages.Required),
  lastName: yup.string().required(ErrorMessages.Required),
};

// extensions defined temporarily until all phone fields can be refactored
const phoneOneExtensionOptional = {
  phoneOneExtension: yup.string().nullable().matches(phoneExtRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true })
};
const phoneTwoExtensionOptional = {
  phoneTwoExtension: yup.string().nullable().matches(phoneExtRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true })
};

const phoneOneOptional = {
  phoneOneType: yup.string().when(['phoneOneCountryCode', 'phoneOne'], {
    is:(a, b) => !!a?.length || !!b?.length,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  phoneOneCountryCode: yup.string().when(['phoneOneType', 'phoneOne'], {
    is:(a, b) => !!a?.length || !!b?.length,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable(),
  }),
  phoneOne: yup.string().when(['phoneOneType', 'phoneOneCountryCode'], {
    is: (type, code) => !!type?.length && code === '1',
    then: (schema) => schema.required(ErrorMessages.Required).matches(usPhoneRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.when(['phoneOneType', 'phoneOneCountryCode'], {
      is: (type, code) => !!type?.length || !!code?.length,
      then: () => schema.required(ErrorMessages.Required).matches(phoneRegex, ErrorMessages.Invalid),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
  ...phoneOneExtensionOptional,
};
const phoneOneOptionalDependencies = [
  //     1                   2                3
  ['phoneOneType', 'phoneOneCountryCode', 'phoneOne'], // 1 2 3
  ['phoneOneType', 'phoneOne', 'phoneOneCountryCode'], // 1 3 2
  ['phoneOneCountryCode', 'phoneOneType', 'phoneOne'], // 2 1 3
  ['phoneOneCountryCode', 'phoneOne', 'phoneOneType'], // 2 3 1
  ['phoneOne', 'phoneOneType', 'phoneOneCountryCode'], // 3 1 2
  ['phoneOne', 'phoneOneCountryCode', 'phoneOneType'], // 3 2 1
];

const phoneTwoOptional = {
  phoneTwoType: yup.string().when(['phoneTwoCountryCode', 'phoneTwo'], {
    is:(a, b) => !!a?.length || !!b?.length,
    then: (schema) => schema.required(ErrorMessages.Required),
    otherwise: (schema) => schema.nullable(),
  }),
  phoneTwoCountryCode: yup.string().when(['phoneTwoType', 'phoneTwo'], {
    is:(a, b) => !!a?.length || !!b?.length,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.nullable(),
  }),
  phoneTwo: yup.string().when(['phoneTwoType', 'phoneTwoCountryCode'], {
    is: (type, code) => !!type?.length && code === '1',
    then: (schema) => schema.required(ErrorMessages.Required).matches(usPhoneRegex, ErrorMessages.Invalid),
    otherwise: (schema) => schema.when(['phoneTwoType', 'phoneTwoCountryCode'], {
      is: (type, code) => !!type?.length || !!code?.length,
      then: () => schema.required(ErrorMessages.Required).matches(phoneRegex, ErrorMessages.Invalid),
      otherwise: (schema) => schema.nullable(),
    }),
  }),
  ...phoneTwoExtensionOptional,
};
const phoneTwoOptionalDependencies = [
  //     1                   2                3
  ['phoneTwoType', 'phoneTwoCountryCode', 'phoneTwo'], // 1 2 3
  ['phoneTwoType', 'phoneTwo', 'phoneTwoCountryCode'], // 1 3 2
  ['phoneTwoCountryCode', 'phoneTwoType', 'phoneTwo'], // 2 1 3
  ['phoneTwoCountryCode', 'phoneTwo', 'phoneTwoType'], // 2 3 1
  ['phoneTwo', 'phoneTwoType', 'phoneTwoCountryCode'], // 3 1 2
  ['phoneTwo', 'phoneTwoCountryCode', 'phoneTwoType'], // 3 2 1
];

const phoneRequired = {
  phoneOneType: yup.string().required(ErrorMessages.Required),
  phoneOneCountryCode: yup.string().required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
  phoneOne: yup.string().when('phoneOneCountryCode', {
    is: '1',
    then: (schema) => schema.matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
    otherwise: (schema) => schema.matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
  }).required(ErrorMessages.Required),
  ...phoneOneExtensionOptional,
  showPhoneTwo: yup.boolean().nullable(),
  phoneTwoType: yup.string().nullable().when('showPhoneTwo', {
    is: true,
    then: (schema) => schema.required(ErrorMessages.Required)
  }),
  phoneTwoCountryCode: yup.string().nullable().when('showPhoneTwo', {
    is: true,
    then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
  }),
  phoneTwo: yup.string().nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
    is: (countryCode, showPhoneTwo) => countryCode === '1' && showPhoneTwo === true,
    then: (schema) => schema.required(ErrorMessages.Required).matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
    otherwise: (schema) => schema.nullable().when(['phoneTwoCountryCode', 'showPhoneTwo'], {
      is: (countryCode, showPhoneTwo) => countryCode !== '1' && showPhoneTwo === true,
      then: (schema) => schema.required(ErrorMessages.Required).matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}),
    }),
  }),
  ...phoneTwoExtensionOptional,
};


const zipCodeOptional = {
  zipcode: yup
    .string()
    .nullable()
    .when('country', {
      is: 'US',
      then: () => yup.string().matches(zipCodeRegex, ErrorMessages.Invalid)
    }),
};
const zipCodeOptionalDependencies = ['zipcode', 'country', 'address', 'city', 'state'];

export const contactInputValidation = {
  addressOptional,
  addressOptionalDependencies,
  addressRequired,
  emailOptional,
  emailRequired,
  faxOptional,
  faxOptionalDependencies,
  nameRequired,
  phoneOneExtensionOptional, // temporarily exported until all phone validations are refactored
  phoneOneOptional,
  phoneOneOptionalDependencies,
  phoneRequired,
  phoneTwoExtensionOptional, // temporarily exported until all phone validations are refactored
  phoneTwoOptional,
  phoneTwoOptionalDependencies,
  zipCodeOptional,
  zipCodeOptionalDependencies,
};
