import { useEffect } from 'react';

import { ModalContent, ModalFooter } from '@components/modal';
import ContactFields from '@forms/components/Form/contact-fields/ContactFields';

import { connect } from 'redux-bundler-react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContactTypes } from '@src/utils/enums';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

const schema = yup.object().shape({
  ...contactInputValidation.nameRequired,
  ...contactInputValidation.addressRequired,
});

const AddAdjoiningPropertyOwnerModal = connect(
  'doModalClose',
  ({
    doModalClose,
    edit,
    data,
    setRowData,
    rowData,
    id,
    isReadOnly
  }) => {
    const defaultValues = edit ? {
      contactType: ContactTypes.AdjoiningPropertyOwner,
      address: data?.address ?? null,
      addressTwo: data?.addressTwo ?? null,
      city: data?.city ?? null,
      firstName: data?.firstName ?? null,
      lastName: data?.lastName ?? null,
      middleName: data?.middleName ?? null,
      state: data?.state ?? null,
      zipcode: data?.zipcode ?? null,
      country: data?.country ?? 'US',
    } : {
      contactType: ContactTypes.AdjoiningPropertyOwner,
      country: 'US'
    };

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur' });
    const { formState: { errors, isValid }, setFocus, getValues, trigger } = methods;

    const onSave = () => {
      if (isValid) {
        const contactFields = getValues();
        const contactFieldsWithIDs = {
          ...contactFields,
          contactID: data?.contactID ?? undefined,
          requestID: data?.requestID ?? undefined,
          version: data?.version ?? undefined,
          contactType: ContactTypes.AdjoiningPropertyOwner,
          createdBy: data?.createdBy ?? undefined,
        };
        if (edit) {
          let dataArr = [...rowData];
          const index = data.contactID ? dataArr.findIndex(el => el.contactID === id) : id;
          dataArr[index] = contactFieldsWithIDs;
          setRowData(dataArr);
        }
        else {
          setRowData((rowData) => [...rowData, contactFieldsWithIDs]);
        }
        doModalClose();
      }
      else {
        trigger();
        toast.error('Please fill out all required fields!');
      }
    };

    useEffect(() => {
      if (errors?.[Object.keys(errors)[0]]?.['ref']?.focus) {
        errors?.[Object.keys(errors)[0]]?.['ref']?.focus();
      }

      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <FormProvider {...methods}>
        <ModalContent hasCloseButton={isReadOnly} size='lg' title={`${edit ? 'Edit' : 'Add'} ${ContactTypes.AdjoiningPropertyOwner}`}>
          {errors && <ErrorSummary errors={errors} modalID='adjoiningPropertyOwnerModal' type='modal' />}
          <section className='modal-body' id='adjoiningPropertyOwnerModal'>
            <div className='container-fluid'>
              <ContactFields type={ContactTypes.AdjoiningPropertyOwner} label={ContactTypes.AdjoiningPropertyOwner} showContactInfoSection={false} showSalutation={false} edit={edit} isReadOnly={isReadOnly} />
            </div>
          </section>
          <ModalFooter
            showCancelButton={!isReadOnly}
            showSaveButton={!isReadOnly}
            saveText={edit ? 'Apply Changes' : 'Add'}
            customClosingLogic
            onSave={onSave}
          />
        </ModalContent>
      </FormProvider >
    );
  }
);

export default AddAdjoiningPropertyOwnerModal;
