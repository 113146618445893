import { Accordion } from '@trussworks/react-uswds';
import NWO_OMAHA from './DistrictContent/NWD/NWD.NWO_Omaha';
import NWP_PORTLAND from './DistrictContent/NWD/NWD.NWP_Portland';
import NWS_SEATTLE from './DistrictContent/NWD/NWD.NWS_Seattle';
import NWW_WALLA_WALLA from './DistrictContent/NWD/NWD.NWW_Walla_Walla';


const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Omaha District (NWO)',
    content: <NWO_OMAHA />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'Portland District (NWP)',
    content: <NWP_PORTLAND/>,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'Seattle District (NWS)',
    content: <NWS_SEATTLE />,
  },
  {
    id: '4',
    headingLevel: 'h3',
    title: 'Walla Walla District (NWW)',
    content: <NWW_WALLA_WALLA/>,
  },
];


const NWD = () => (
  <>
    <section id='nwd'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default NWD;
