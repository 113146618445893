import { useEffect} from 'react';
import { useMap } from 'react-leaflet';

import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import * as ELG from 'esri-leaflet-geocoder';

const Geocoder = () => {
  const map = useMap();
  const resultZoomLevel = 10;

  useEffect(() => {
    const getEsriToken = async () => {
      const clientId = import.meta.env.VITE_ESRI_CLIENT_ID;
      const clientSecret = import.meta.env.VITE_ESRI_CLIENT_SECRET;
      const url = 'https://www.arcgis.com/sharing/rest/oauth2/token';

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            'grant_type': 'client_credentials',
            'client_id': clientId,
            'client_secret': clientSecret,
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching Esri token:', error);
        return false;
      }
    };

    getEsriToken().then((token) => {
      if (!token) return;

      const geosearchControl = ELG.geosearch({
        position: 'topright',
        allowMultipleResults: true,
        zoomToResult: false,
        useMapBounds: false,
        expanded: true,
        providers: [
          ELG.arcgisOnlineProvider({
            label: 'Search Results',
            countries: 'USA,ASM,PRI,UMI,VIR,GUM,FSM',
            apikey: token.access_token,
          }),
        ],
      }).addTo(map);

      geosearchControl.on('results', (event) => {
        if (event.results && event.results.length > 0) {
          const resultLatLng = event.results[0].latlng;
          map.setView(resultLatLng, resultZoomLevel, { animate: true });
        }
      });
    });

    return () => {
      map.eachLayer((layer) => {
        if (layer instanceof ELG.Geosearch) {
          map.removeControl(layer);
        }
      });
    };
  }, [map]);

  return null;
};

export default Geocoder;