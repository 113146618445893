import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';

const SAAContent = <div>
  <b>I. INFORMATION FOR PUERTO RICO APPLICANTS/AGENTS</b>
  <p>
On July 15, 1999,  the U.S. Army Corps of Engineers (USACE), the Puerto Rico Planning Board (PRPB), the Puerto Rico Department of Natural and Environmental Resources (PRDNER), and the Environmental Quality Board (EQB) entered into a Memorandum of Understanding to establish a Joint Permit Application (JPA) process for activities which affect or alter water resources, including wetlands, within the Commonwealth of Puerto Rico in accordance with Sections   401 and 404 of the Clean Water Act, Section 10 of the Rivers and Harbors Act of 1899, and Section 103 of the Marine Protection, Research and Sanctuaries Act.  The JPA also satisfies Commonwealth’s application requirements pursuant to the Regulation for the Use, Surveillance, Conservation and Management of the Territorial Waters, Submerged Lands thereunder and the Maritime Zone (Law 144 of June 3, 1976); Conservation, Development and Use of Water Resources Act (Law 136 of June 3, 1976, as amended); the Organic Law of the DNER, Act 23 of June 20, 1972, as amended; and the Coastal Zone Management Act (CZMA) of 1972, as amended.  This process does not change any of the regulatory requirements, responsibilities, or jurisdiction of each party.
  </p>
  <p>
The USACE and the PRDNER have developed the JPA process to assist individuals or entities applying for the applicable permits for activities that will affect water resources, including wetlands, within the Commonwealth of Puerto Rico.  The JPA process has been designed to minimize the delays and effort associated with applying for a permit, and to ensure consistency during review.
  </p>
  <p>
The new Regulatory Request System (RRS) is now the mechanism for filing joint permit applications.  Using the RRS system to submit your application, you can apply for the following permits and/or certifications required for projects affecting water resources in Puerto Rico:
  </p>
  <ul>
    <li>
    Department of the Army (DA) permits for: 
      <ol>
        <li>Discharges of dredged or fill material into waters of the United States (Section 404 of the Clean Water Act),</li>
        <li>Work or structures in navigable waters of the United States (Section 10 of the Rivers and Harbors Act),</li>
        <li>Transport of dredged material for ocean disposal (Section 103 of the Marine Protection, Research, and Sanctuaries Act).</li>
      </ol>
    </li>
    <li>Federal Consistency Certification with the Puerto Rico Coastal Zone Management Program from the PRPB.</li>
    <li>Water Quality Certification from the PRDNER, Point Sources Permits Division</li>
    <li>Submerged Land Use Concession from the PRDNER.</li>
  </ul>
  <b>CLEAN WATER ACT SECTION 401 WATER QUALITY CERTIFICATE </b>
  <p>
  On November 27, 2023, the Clean Water Act (CWA) Section 401 Water Quality Certification Improvement Rule came into effect ( published in the Federal Register, Vol. 88, No. 186 of September 27, 2023 (40 CFR, Part 121).  This rule revised and replaced the 2020 regulatory requirements for water quality certification (WQC) under Section 401 of the CWA, that had superseded the 1971 Rule.  The 2023 rule requires that all project proponents request a pre-filing meeting with the appropriate certifying authority at least 30 days prior to submitting a request for certification unless the certifying authority waives or shortens this requirement (40 CFR, Part 121.4).  The 2023 rule also establishes the minimum contents of the request for certification and authorizes the certifying authorities to define other contents necessary for a request for certification (40 CFR, Part 121.5).
  </p>
  <p>
  Therefore, a pre-filing meeting must be requested at least 30 days prior to submitting the WQC request in accordance with the provisions of 40 CFR Part 121.4, if a CWA Section 401 certification is required for a DA permit.  Although federal regulations do not set the agenda for the pre-filing meeting, a brief description of the proposed project focused on those activities subject to the DA permit must be provided.
  </p>
  <p>
  You can find more information and copy of the 2023 Rule through the following link: <ExternalLink content='https://www.epa.gov/cwa-401' title='https://www.epa.gov/cwa-401' href='https://www.epa.gov/cwa-401' />
  </p>
  <p>
  To obtain any additional information regarding the CWA Section 401 WQC procedure in Puerto
Rico, contact the PRDNER - Point Sources Permits Division at (787) 999-2200 extension 6171 or
via email at <InternalLink content='fuentesprecisadas@drna.pr.gov' title={'MailTo fuentesprecisadas@drna.pr.gov'} href={'mailto:fuentesprecisadas@drna.pr.gov'} />
  </p>
  <b>JOINT PERMIT APPLICATION COORDINATION: </b>
  <p>
  The JPA process begins by submitting an application via the RRS system. The USACE will receive
the applications submitted through RRS, assign them a DA application number, and will digitally
distribute the applications (and supporting documentation) to the agencies involved. The NEW
JPA number would be the assigned DA permit number. All applications must be submitted
digitally via RRS and there is no need to hand-deliver printed documents. Additional
information could be required from each respective agency once they conduct their initial
review of the application package.
  </p>
  <p>
Applicants must provide the identification number corresponding to Commonwealth permits
and endorsements for the proposed project, and upload copy of these permits/endorsements
to RRS. Currently, this information is required in block 26 of the permit application form (ENG
FORM 4345). Among the most important Commonwealth requirements for projects in Puerto
Rico are the following: 
    <ul>
      <li>Environmental document filed with the P.R. Permits Management Office (OGPe)</li>
      <li>Environmental Compliance Determination issued by OGPe</li>
      <li>Delineation of the Maritime Terrestrial Zone prepared by a land surveyor and certified by
  the PRDNER, if required.</li>
    </ul>
  </p>
  <p>
To consider your application for a Submerged Land Use Concession complete, the PRDNER
requires submittal of evidence of the corresponding paid fee. Payments must be made by
certified check or money order made payable to the Secretary of Treasury. The fee must be
paid at the Cashiers Office (Oficina del Recaudador) at PRDNER. For additional information
please contact PRDNER at:
  </p>
  <p>
In person: P.R. Department of Natural and Environmental Resources, Secretariat Office, Floor 1,
Street 8839, km. 6.3, Sector El Cinco, San Juan, 00927, Puerto Rico 
  </p>
  <p>
By Mail: P.R. Department of Natural and Environmental Resources, Secretariat Office, San José
Industrial Park, 1375 Ponce de León Avenue, San Juan, Puerto Rico 00926 <br/>
E-mail: <InternalLink content='ayudaciudadano@drna.pr.gov' title={'MailTo ayudaciudadano@drna.pr.gov'} href={'mailto:ayudaciudadano@drna.pr.gov'} /> <br/>
Phone: (787) 999-2200 <br/>
Webpage: <ExternalLink content='http://www.drna.pr.gov/' title='http://www.drna.pr.gov/' href='http://www.drna.pr.gov/' />
  </p>
  <b>II. INFORMATION FOR APPLICANTS/AGENTS FOR ACTIONS IN THE US VIRGIN ISLANDS (USVI):</b>
  <p>
  In the USVI, permit applications for proposed activities that require DA authorization must be
submitted via RRS. For information on how to submit applications to the VI Department of
Planning and Natural Resources (DPNR) for CZM and WQ permits, you should contact the DPNR
- Division of Coastal Zone Management and DPNR - Division of Environmental Protection,
respectively. 
  </p>
  <b>III. DA NATIONWIDE PERMITS PRE-CONSTRUCTION NOTIFICATION: </b>
  <p>Regarding the use of the Pre-Construction Notification (PCN) as defined in the Nationwide
Permits (NWPs) General Condition 32, be aware that the FINAL REGIONAL CONDITIONS FOR
THE 2021 NATIONWIDE PERMITS IN JACKSONVILLE DISTRICT (Effective Date for Regional
Conditions for 16 NWPs: March 15, 2021; Effective Date for Modified Regional Conditions for 16
NWPs: February 25, 2022; Effective Date for Regional Conditions for 41 NWPs: February 25,
2022; Expiration Date for Regional Conditions for all NWPs: March 14, 2026), at part (A)(6)(b)
states the following:
  </p>
  <p><i>“PCN for activities in the Antilles shall be made through the Joint Permit Process using the most
recent and valid Application for Department of the Army Permits (ENG Form 4345), and the
form must indicate it is an NWP PCN. The application must also contain the information
described in General Condition 32. If the joint process changes before the expiration of these
Regional Conditions, then follow the instructions in the announcement for such change.”</i></p>
  <b>VI. SUPPORT FROM THE CARIBBEAN DISTRICT REGULATORY BRANCH: </b>
  <p>
Please feel free to contact us for additional information or questions. However, we encourage
our applicants to please visit and review first the references information at our USACE
Regulatory Division Webpage under “Source Book” to prepare a complete application for our
consideration. Visit the following link to access the location and click on “Expand All” to see the
content of each section: <ExternalLink content='https://www.saj.usace.army.mil/Missions/Regulatory/Source-Book/' title='https://www.saj.usace.army.mil/Missions/Regulatory/Source-Book/' href='https://www.saj.usace.army.mil/Missions/Regulatory/Source-Book/' />
  </p>
  <p>
You may contact us for general pre-application questions at: <InternalLink content='RD-Antilles@USACE.ARMY.MIL' title={'MailTo RD-Antilles@USACE.ARMY.MIL'} href={'mailto:RD-Antilles@USACE.ARMY.MIL'} />.
Follow the instructions in RRS and above to submit a new application. <b>Applications received at
this email directly from applicants will be returned.</b> We can also be reached by telephone at
(787) 289-7040 (Monday to Friday from 9:00am to 4:00pm). 
  </p>
  <b>Caribbean District Regulatory Branch Team</b>
  <p> 
U.S. Army Corps of Engineers <br/>
Caribbean District, Regulatory and Operations Division <br/>
Fund. Ángel Ramos Annex Bldg., Suite 202 <br/>
383 F.D. Roosevelt Ave. <br/>
San Juan, Puerto Rico 00918 <br/>
Visitors Hours: 9:00am to 3:00pm (please call or email us in advance for an appointment).<br/>
WEB: <ExternalLink content='https://www.saj.usace.army.mil/About/Divisions-Offices/Antilles-Office/' title='https://www.saj.usace.army.mil/About/Divisions-Offices/Antilles-Office/' href='https://www.saj.usace.army.mil/About/Divisions-Offices/Antilles-Office/' /> 
  </p>
</div>;

export default SAAContent;