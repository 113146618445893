import React from 'react';

import { connect } from 'redux-bundler-react';
import { Button } from '@trussworks/react-uswds';

import './hero.scss';

const Hero = connect('doUpdateRelativeUrl', 'doAuthLogin', 'selectAuthIsLoggedIn', ({ doUpdateRelativeUrl, doAuthLogin, authIsLoggedIn }) => (
  <div className='hero-container'>
    <div className='hero'>
      <div className='row'>
        <div className='col-12'>
          <div className='container text-container'>
            <h1 className='inner-text'>Welcome to the Regulatory Request System</h1>
            <h3 className='subtitle-text inner-text'>
              Apply Online  •  Learn about the Regulatory Program  •  Track Request Status
            </h3>
          </div>
        </div>
      </div>
      <div className='row'>
        <Button className='ml-3 mr-5 mb-3 usa-button' onClick={authIsLoggedIn ? () => doUpdateRelativeUrl('/') : () => doAuthLogin()} tabIndex={0}>Get Started</Button>
      </div>
    </div>
  </div>
));

export default Hero;
