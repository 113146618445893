import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NWW_WALLA_WALLA = () => (
  <>
    <section id='nww-walla-walla'>
      <DistrictInfoTable district='NWW'/>
      <p>
      Please do not use RRS to apply for a permit in Idaho.  The <ExternalLink content='Walla Walla District' title='Walla Walla District' href='https://www.nww.usace.army.mil/Business-With-Us/Regulatory-Division/' /> employs a joint permit application process with the State of Idaho.  
      </p>
    </section>
  </>
);

export default NWW_WALLA_WALLA;
