import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const SAW_WILMINGTON = () => (
  <>
    <section id='saw-wilmington'>
      <DistrictInfoTable district='SAW'/>
      <p>
      Please do not use RRS to apply for a permit in the Wilmington District, as the state of North Carolina has a joint application process.  The <ExternalLink content='Wilmington District website' title='Wilmington District Website' href='https://www.saw.usace.army.mil/Missions/Regulatory-Permit-Program/Permits/2017-Nationwide-Permits/Pre-construction-Notification/' /> contains more information on how to obtain a permit and the application form.
      </p>
    </section>
  </>
);

export default SAW_WILMINGTON;
