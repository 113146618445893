const tableBundle = {
  name: 'table',
  getReducer: () => {
    const initialData = {
      propOwners: [],
      agents: [],
      adjoiningPropOwner: [],
      aquaticResources: [],
      impacts: [],
      mitigations: [],
      certifications: [],
    };

    return (state = initialData, { type, payload }) => {

      switch (type) {
        case 'SAVE_TABLE_DATA': {

          switch (payload.tablename) {
            case 'propOwners':
              return { ...state, propOwners: payload.data };
            case 'agents':
              return { ...state, agents: payload.data };
            case 'adjoiningPropOwner':
              return { ...state, adjoiningPropOwner: payload.data };
            case 'aquaticResources':
              return { ...state, aquaticResources: payload.data };
            case 'impacts':
              return { ...state, impacts: payload.data };
            case 'mitigations':
              return { ...state, mitigations: payload.data };
            case 'certifications':
              return { ...state, certifications: payload.data };
            default:
              return state;
          }
        }
        case 'CLEAR_TABLE': {
          switch (payload.tablename) {
            case 'propOwners':
              return { ...state, propOwners: null };
            case 'agents':
              return { ...state, agents: payload.data };
            case 'adjoiningPropOwner':
              return { ...state, adjoiningPropOwner: null };
            case 'aquaticResources':
              return { ...state, aquaticResources: [] };
            case 'impacts':
              return { ...state, impacts: null };
            case 'mitigations':
              return { ...state, mitigations: null };
            case 'certifications':
              return { ...state, certifications: [] };
            default:
              return state;
          }
        }

        case 'RESET_TABLES': {
          return initialData;
        }
        default:
          return state;
      }
    };
  },
  doSaveTable: (tablename, data) => ({ dispatch }) => {
    dispatch({
      type: 'SAVE_TABLE_DATA',
      payload: {
        tablename: tablename,
        data: data
      },
    });
  },
  doClearTable: (tablename) => ({ dispatch }) => {
    dispatch({
      type: 'CLEAR_TABLE',
      payload: {
        tablename: tablename
      },
    });
  },
  doResetTables: () => ({ dispatch }) => {
    dispatch({
      type: 'RESET_TABLES',
    });
  },
  selectTableData: (state) => state.table,
  selectPropOwnersTableData: (state) => state.table.propOwners,
  selectAgentsTableData: (state) => state.table.agents,
  selectAdjoiningPropOwnerTableData: (state) => state.table.adjoiningPropOwner,
  selectMitigationsTableData: (state) => state.table.mitigations,
  selectImpactsTableData: (state) => state.table.impacts,
  selectCertificationsTableData: (state) => state.table.certifications,
  selectAquaticResourcesTableData: (state) => state.table.aquaticResources,
};

export default tableBundle;
