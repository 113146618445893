const mapBundle = {
  name: 'map',
  getReducer: () => {
    const initialData = {
      marker_lat: null,
      marker_lng: null,
      entered_lat: null,
      entered_lng: null,
      center: [37.612345, -95.661234],
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FORM_UPDATED') {
        return Object.assign({}, state);
      }

      if (type === 'MAP_RESET') {
        return initialData;
      }

      if (type === 'MARKER_LATITUDE_UPDATED') {
        return {
          ...state,
          marker_lat: payload.value,
        };
      }

      if (type === 'MARKER_LONGITUDE_UPDATED') {
        return {
          ...state,
          marker_lng: payload.value,
        };
      }

      if (type === 'MARKER_POSITION_RESET') {
        return {
          ...state,
          marker_lat: null,
          marker_lng: null,
        };
      }

      if (type === 'ENTERED_LATITUDE_UPDATED') {
        return {
          ...state,
          entered_lat: payload.value,
        };
      }

      if (type === 'ENTERED_LONGITUDE_UPDATED') {
        return {
          ...state,
          entered_lng: payload.value,
        };
      }

      if (type === 'CENTER_UPDATED') {
        return {
          ...state,
          center: payload.value,
        };
      }

      if (type === 'ENTERED_POSITION_RESET') {
        return {
          ...state,
          entered_lat: null,
          entered_lng: null,
        };
      }
      return state;
    };
  },
  doUpdateMarkerLat:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'MARKER_LATITUDE_UPDATED',
          payload: {
            value: props.value,
          },
        });
      },
  doUpdateMarkerLng:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'MARKER_LONGITUDE_UPDATED',
          payload: {
            value: props.value,
          },
        });
      },
  doUpdateEnteredLat:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'ENTERED_LATITUDE_UPDATED',
          payload: {
            value: props.value,
          },
        });
      },
  doUpdateEnteredLng:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'ENTERED_LONGITUDE_UPDATED',
          payload: {
            value: props.value,
          },
        });
      },
  doUpdateCenter:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'CENTER_UPDATED',
          payload: {
            value: props.value,
          },
        });
      },

  doResetMarkerPosition:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'MARKER_POSITION_RESET',
        });
      },

  doResetEnteredPosition:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'ENTERED_POSITION_RESET',
        });
      },
  doResetMap:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'MAP_RESET',
        });
      },

  selectMarkerLat: (state) => state.map.marker_lat,
  selectMarkerLng: (state) => state.map.marker_lng,
  selectEnteredLat: (state) => state.map.entered_lat,
  selectEnteredLng: (state) => state.map.entered_lng,
  selectCenter: (state) => state.map.center,
};

export default mapBundle;
