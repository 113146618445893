import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRC_CHICAGO = () => (
  <>
    <section id='lrc-chicago'>
      <DistrictInfoTable district='LRC' orgEmail='ChicagoRequests@usace.army.mil'/>
      <p>
      Please note that the <ExternalLink content='Regional General Permit (RGP) Notification Form for Indiana' href='https://www.in.gov/idem/forms/idem-agency-forms/#owq_stormwater' title='Indiana RGP' /> and the <ExternalLink content='Joint Application Form for Illinois' href='https://dnr.illinois.gov/waterresources/permitapplicationandinstructions.html' title='Illinois JPA' /> are currently not available in RRS.
       To submit an Indiana RGP Notification Form or a Joint Application Form for Illinois (e.g., for Shoreline Activities RGP and Lake Michigan RGP applications), please email the completed application materials to <InternalLink content='ChicagoRequests@usace.army.mil' title='MailTo ChicagoRequests@usace.army.mil' href='mailto:ChicagoRequests@usace.army.mil' />
      </p>
    </section>
  </>
);

export default LRC_CHICAGO;
