import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NWS_SEATTLE = () => (
  <>
    <section id='nws-seattle'>
      <DistrictInfoTable district='NWS'/>
      <p>
      The Regulatory Request System (RRS) may be used to apply for a Section 10 or Section 404 permit within the State of Washington.  As an alternative, you may also use the Joint Aquatic Resource Permit Application (JARPA) to apply for a permit.  The Seattle District partners with local, state, and federal governments to manage the JARPA application form.  The <ExternalLink content='Seattle District website' title='Seattle District Website' href='https://www.nws.usace.army.mil/Missions/Civil-Works/Regulatory/' /> contains links to the JARPA, RRS, and Corps Application forms.
      </p>
    </section>
  </>
);

export default NWS_SEATTLE;
