

const formValidationBundle = {
  name: 'formvalidation',

  getReducer: () => {
    const initialState = {};

    return (state = initialState, payload) => {

      switch (payload.type) {
        case 'ADD_VALIDATION_SECTION': {
          return { ...state, [payload.step]: { section: payload.section, validity: payload.validity } };
        }
        case 'UPDATE_VALIDATION_SECTION': {
          return { ...state, [payload.step]: { section: payload.section, validity: payload.isReadOnly ? true : payload.validity } };
        }
        case 'RESET_FORM_VALIDITY': {
          return initialState;
        }
        default: return state;
      }

    };
  },

  doAddSectionValidity:
    (section, validity, step) =>
      ({ dispatch }) => {
        dispatch({ type: 'ADD_VALIDATION_SECTION', section, validity, step });
      },
  doUpdateSectionValidity:
    (section, validity, step, isReadOnly) =>
      ({ dispatch }) => {
        dispatch({ type: 'UPDATE_VALIDATION_SECTION', section, validity, step, isReadOnly });
      },
  doResetFormValidity: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_FORM_VALIDITY' });
  },

  selectFormValidation: (state) => state.formvalidation,

};
export default formValidationBundle;
