import { connect } from 'redux-bundler-react';
import { TableWrapper } from './FormAndAppendices';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ResourceFiles } from '@src/utils/enums';

const FormsAndAppendicesDelineationRequest = connect('doDownloadFile', ({ doDownloadFile }) => (
  <>
    <p>
      This path can be used to report a potential unauthorized activity or permit non-compliance for activities
      undertaken in Waters of the US
    </p>
    <TableWrapper>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6284, FileTypes.Resource)}>
            ENG 6284 Regulatory Violation Complaint [PDF, 2 pages]
          </LinkButton>
        </td>
        <td>
          This form provides information provided by the individual submitting the report. It can include the suspected
          violators name and address, contractor information (if any), location of activity, and the type of work being
          done.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixH, FileTypes.Resource)}>
            App H. Supporting Information [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Information that is uploaded by the requestor to support and/or clarify the request that is being made. This
          information can include, but is not limited to, photos, maps, drawings, other authorizations, mitigation
          plans, etc.
        </td>
      </tr>
    </TableWrapper>
  </>
));

export default FormsAndAppendicesDelineationRequest;
