import { toast } from 'react-toastify';

export const tLoading = message => toast.loading(message, { closeOnClick: true });

export const tSuccess = (id, message) => {
  toast.update(id, { render: message, type: 'success', isLoading: false, autoClose: 2500, closeOnClick: true, draggable: true });
};

export const tError = (id, message) => {
  toast.update(id, { render: message, type: 'error', isLoading: false, autoClose: 5000, closeOnClick: true, draggable: true });
};

export const tWarning = (id, message) => {
  toast.update(id, { render: message, type: 'warning', isLoading: false, autoClose: 4000, closeOnClick: true, draggable: true });
};
