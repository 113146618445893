const projectAddressBundle = {
  name: 'projectaddress',
  getReducer: () => {
    const initialData = {};
    //   projectName: null,
    //   latitude: null,
    //   longitude: null,
    //   address: null,
    //   addressTwo: null,
    //   projectArea: null,
    //   city: null,
    //   state: null,
    //   zipcode: null,
    //   county: null,
    //   district: null,
    //   plssSection: null,
    //   plssTownship: null,
    //   plssRange: null,
    //   fieldOfficeCode: null,
    //   siteDirections: null,
    //   parcelNumber: null,
    //   nearestWaterbody: null,
    //   projectID: null,
    // };

    return (state = initialData, { type, payload }) => {
      if (type === 'FORM_UPDATED') {
        return Object.assign({}, state);
      }

      if (type === 'PROJECT_ADDRESS_RESET') {
        return initialData;
      }

      if (type === 'PROJECT_ADDRESS_OBJECT_UPDATED') {
        return {
          ...state,
          ...payload
        };
      }

      if (type === 'PROJECT_ADDRESS_UPDATED') {
        if (payload.address1 !== undefined + ' ' + undefined) {
          return {
            ...state,
            address: payload.address ? payload.address : null,
            addressTwo: payload.addressTwo ? payload.addressTwo : null,
            city: payload.city ? payload.city : null,
            state: payload.state ? payload.state : null,
            zipcode: payload.zipcode ? payload.zipcode : null,
            county: payload.county ? payload.county : null,
          };
        } else {
          return {
            ...state,
            address: null,
            addressTwo: null,
            city: payload.city ? payload.city : null,
            state: payload.state ? payload.state : null,
            zipcode: payload.zipcode ? payload.zipcode : null,
            county: payload.county ? payload.county : null,
          };
        }
      }

      if (type === 'PROJECT_ADDRESS_FIELD_UPDATED') {
        return { ...state, [payload.name]: payload.value };
      }

      return state;
    };
  },
  doResetProjectAddress:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'PROJECT_ADDRESS_RESET',
        });
      },
  doUpdateProjectAddress:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'PROJECT_ADDRESS_UPDATED',
          payload: {
            address: props.address,
            addressTwo: props.addressTwo,
            city: props.city,
            state: props.state,
            zipcode: props.zipcode,
            county: props.county,
          },
        });
      },

  doUpdateProjectAddressObject:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'PROJECT_ADDRESS_OBJECT_UPDATED',
          payload: props,
        });
      },
  doUpdateProjectAddressField:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'PROJECT_ADDRESS_FIELD_UPDATED',
          payload: {
            name: props.name,
            value: props.value,
          },
        });
      },
  selectProjectName: (state) => state.projectaddress.projectName,
  selectProjectAddress1: (state) => state.projectaddress.address,
  selectProjectAddress2: (state) => state.projectaddress.addressTwo,
  selectProjectArea: (state) => state.projectaddress.area,
  selectProjectCity: (state) => state.projectaddress.city,
  selectProjectState: (state) => state.projectaddress.state,
  selectProjectZipcode: (state) => state.projectaddress.zipcode,
  selectProjectCounty: (state) => state.projectaddress.county,
  selectProjectDistrict: (state) => state.projectaddress.district,
  selectProjectPLSSSection: (state) => state.projectaddress.plssSection,
  selectProjectPLSSTownship: (state) => state.projectaddress.plssTownship,
  selectProjectPLSSRange: (state) => state.projectaddress.plssRange,
  selectProjectFieldOffice: (state) => state.projectaddress.fieldOfficeCode,
  selectProjectDirections: (state) => state.projectaddress.siteDirections,
  selectProjectID: (state) => state.projectaddress.projectID,
  selectProjectLocation: (state) => state.projectaddress,
};

export default projectAddressBundle;
