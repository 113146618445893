import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { ToastContainer } from 'react-toastify';
import { Button, GovBanner } from '@trussworks/react-uswds';
import { mdiExclamationThick, mdiHelp } from '@mdi/js';

import Modal from '@components/modal';
import SecondaryModal from '@components/secondary-modal';
import NavBar from '@components/navigation/navBar';
import PageContent from '@components/page-content/pageContent';
import LandingModal from '@pages/common/modals/Landing';
import LoadingModal from '@pages/common/modals/Loading';
import RRSFooter from '@components/footer/Footer';
import Icon from '@components/icon/Icon';
import Drawer from '@components/drawer/Drawer';

import { helpContent, helpHeaders } from './utils/helpContent';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'react-toastify/dist/ReactToastify.css';
import '@styles/css/bootstrap/css/bootstrap.water.min.css';
import '@styles/bootstrap_overrides.css';
import '@styles/index.scss';
import '@styles/_buttons.scss';

const environment = import.meta.env.VITE_ENVIRONMENT;
const showEnvLabel = ['local', 'development', 'test'].includes(environment);

export default connect(
  'doModalOpen',
  'doFetchBannerInfo',
  'doFetchUserProfile',
  'doGetDistrictsUnAuth',
  'doSetLoadingState',
  'doSetLoadingMessage',
  'selectRoute',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  'selectLoadingState',
  'selectLoadingMessage',
  'selectBannerData',
  'selectRelativePathname',
  ({
    doModalOpen,
    doFetchBannerInfo,
    doFetchUserProfile,
    doGetDistrictsUnAuth,
    doSetLoadingState,
    doSetLoadingMessage,
    route: Route,
    authIsLoggedIn,
    userProfileData,
    loadingState,
    loadingMessage,
    bannerData,
    relativePathname
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const drawerContent = helpContent[relativePathname];
    const drawerHeader = helpHeaders[relativePathname];

    const toggleDrawer = () => {
      setIsOpen((prev) => !prev);
    };

    useEffect(() => {
      const fetchBanner = async () => await doFetchBannerInfo();

      fetchBanner();

      const intervalId = setInterval(() => {
        fetchBanner();
      }, 600000); // 10 minutes

      return () => clearInterval(intervalId);
    }, [doFetchBannerInfo]);

    useEffect(() => {
      if (!authIsLoggedIn) {
        const landingModalSeen = sessionStorage.getItem('landingModalSeen');
        if (!landingModalSeen || landingModalSeen === 'false') {
          doModalOpen(LandingModal);
        }
      } else {
        if (userProfileData.length === 0) {
          doFetchUserProfile();
        }
      }
    }, [authIsLoggedIn, userProfileData, doModalOpen, doFetchUserProfile]);

    useEffect(() => {
      window.addEventListener('pageshow', (event) => {
        // Check if page was restored from the bfcache
        if (event.persisted) {
          // Clear loading overlay
          doSetLoadingState(false);
          doSetLoadingMessage('Loading...');
        }
      });
    }, [doSetLoadingMessage, doSetLoadingState]);

    useEffect(()=>{
      doGetDistrictsUnAuth();
    },[doGetDistrictsUnAuth]);

    return (
      <>
        {loadingState && <LoadingModal text={loadingMessage} />}
        <ToastContainer autoClose={3500} hideProgressBar={false} style={{ zIndex: 999999999 }}/>
        {showEnvLabel && (
          <div className={`environment-label ${environment}`}>
            <Icon path={mdiExclamationThick} size='12px' focusable={false} /> {environment} environment <Icon path={mdiExclamationThick} size='12px' focusable={false} />
          </div>
        )}
        <div className={'gov-banner-container'}>
          <GovBanner language='english' tld='.mil' aria-label='Official government website' />
        </div>
        <NavBar className='margin-bottom-1' />
        <PageContent>
          <Drawer 
            content={drawerContent} 
            title={drawerHeader} 
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          <Route />
        </PageContent>
        <Modal closeWithEscape />
        <SecondaryModal closeWithEscape />
        {/* TODO: Only shows for public notices page for now */}
        {(drawerContent && authIsLoggedIn && !isOpen) && (
          <Button className='float' type='button' onClick={() => toggleDrawer()}>
            <Icon focusable={false} path={mdiHelp} size={'20px'} />
          </Button>
        )}
        <RRSFooter version={bannerData?.version} />
      </>
    );
  }
);
