import { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Alert, Label } from '@trussworks/react-uswds';

import FileInput from '@components/file-input/FileInput';
import TextAreaInput from '@components/textarea/TextArea';

import { useFormContext } from 'react-hook-form';

import { ContactsFormNames, ErrorMessages, FileTypes } from '@src/utils/enums';

const RightOfEntryUpload = connect(
  'doDeleteFile',
  'doDownloadFile',
  'doResetFileSection',
  'doUpdateRequestObject',
  'doUpdateSelectedFiles',
  'selectIsReadOnly',
  'selectRightOfEntryFile',
  'selectAgentAuthorizationFile',
  'selectSupportingDocFiles',
  ({
    doDeleteFile,
    doDownloadFile,
    doResetFileSection,
    doUpdateRequestObject,
    doUpdateSelectedFiles,
    isReadOnly,
    rightOfEntryFile,
    agentAuthorizationFile,
    supportingDocFiles,
    componentID,
    subText
  }) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileErrors, setFileErrors] = useState(false);
    const isSelectedFile = selectedFile?.length > 0;
    const isS3File = !!rightOfEntryFile?.[0]?.key;

    const { setValue, watch } = useFormContext();
    const ownerEntryComments = watch('ownerEntryComments');
    const rightOfEntry = watch('rightOfEntryFile');

    const handleFileInput = e => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setSelectedFile(filesArr);
    };

    const handleDeleteFile = () => {
      isS3File && doDeleteFile(rightOfEntryFile?.[0]?.fileName, componentID);
      doResetFileSection(ContactsFormNames.RightOfEntry);
      setSelectedFile([]);
    };
    
    const setFileValue = useCallback(() => {
      if (rightOfEntryFile?.length > 0) {
        return fileErrors === true ? 'error' : 'true';
      } else {
        return 'false';
      }
    }, [fileErrors, rightOfEntryFile]);

    // Set selected file if valid
    useEffect(() => {
      const doSelectFile = () => {
        const ROEFile = {
          componentID: componentID,
          file: selectedFile?.[0],
          fileName: selectedFile?.[0]?.name,
          isInvalid: fileErrors === true,
          section: ContactsFormNames.RightOfEntry
        };
        doUpdateSelectedFiles(ROEFile);
      };
      if (isSelectedFile === true) {
        doResetFileSection(ContactsFormNames.RightOfEntry);
        doSelectFile();
      }
    }, [selectedFile, doResetFileSection, componentID, isSelectedFile, doUpdateSelectedFiles, fileErrors]);

    // Set rightOfEntryFile
    useEffect(() => {
      setValue('rightOfEntryFile', setFileValue(), { shouldValidate: true });
    }, [setFileValue, setValue]);

    // EDGE CASE: rightOfEntryFile being set to a fileList object - reset rightOfEntryFile to appropriate value
    useEffect(() => {
      (rightOfEntry && fileErrors === true) && setValue('rightOfEntryFile', 'error', { shouldValidate: true });
    }, [rightOfEntry, fileErrors, setValue]);

    // Set ownerEntryComments value, if any
    useEffect(() => {
      doUpdateRequestObject({ ownerEntryComments: ownerEntryComments });
    }, [ownerEntryComments, doUpdateRequestObject]);

    useEffect(() => {
      // Reset section validation and file input validation
      setFileErrors(false);
    }, [agentAuthorizationFile.length, supportingDocFiles.length]);
    
    return (
      <>
        <Label className='h6 border-bottom w-100 padding-bottom-1 margin-top-2' htmlFor='rightOfEntry'>
          <span id='rightOfEntry_label'>Right of Entry</span>
        </Label>
        <Alert type='info' slim > 
          <>{subText && subText}</>
          <p>For multiple property owners, please consolidate right-of-entry forms into one file before uploading. Alternatively, you can upload additional right-of-entry forms later in the Supporting Documentation section.</p>
          <p className='text-bold'>{ErrorMessages.UploadNotice}</p>
        </Alert>
        <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-3'>
          <FileInput
            accept='.docx,.pdf'
            doDownloadFile={() => doDownloadFile(rightOfEntryFile?.[0]?.fileName, FileTypes.Request)} 
            doDeleteFile={() => handleDeleteFile()}
            dragText='Drag your Right-of-Entry Form here or '
            hint='Select a .DOCX or .PDF file'
            id='rightOfEntryFile' 
            label='Input accepts a single, DOCX or PDF file'
            name='rightOfEntryFile' 
            onChange={handleFileInput} 
            onDrop={handleFileInput}
            readOnly={isS3File || isReadOnly}
            setFileErrors={setFileErrors}
            resetFileErrors={!fileErrors}
            showDownloadButton={isS3File}
            showDeleteButton={(isSelectedFile === true || isS3File) && !isReadOnly}
            value={rightOfEntryFile}
          />
        </div>
        <div className='row margin-bottom-2'>
          <div className='col'>
            <TextAreaInput name='ownerEntryComments' label='Right of Entry Comment' readOnly={isReadOnly} />
          </div>
        </div>
      </>
    );}
);

export default RightOfEntryUpload;
