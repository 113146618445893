export const polygonSVG = (
  <svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
    <path id='polygon' d='M 24.003 6.508 L 21.907 21.338 L 7.073 23.943 L 0.003 10.716 L 10.466 -0.057 L 24.003 6.508 Z' style={{ fill: '#464646', fillOpacity: 1 }} />
  </svg>
);

export const polyLineSVG = (
  <svg viewBox='0 0 24 24' width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
    <g id='polyline' style={{ fill: 'rgb(70, 70, 70)', fillOpacity: 1 }} transform='matrix(0.999576985836029, 0, 0, 0.9995550513267517, -17.987300872802734, -17.986669540405273)'>
      <path d='m 18,36 0,6 6,0 0,-6 -6,0 z m 4,4 -2,0 0,-2 2,0 0,2 z' id='path4229' style={{ fill: '#464646', fillOpacity: 1 }} />
      <path d='m 36,18 0,6 6,0 0,-6 -6,0 z m 4,4 -2,0 0,-2 2,0 0,2 z' id='path4231' style={{ fill: '#464646', fillOpacity: 1 }} />
      <path d='m 23.142,39.145 -2.285,-2.29 16,-15.998 2.285,2.285 z' id='path4233' style={{ fill: '#464646', fillOpacity: 1 }} />
    </g>
  </svg>
);

export const fullscreenSVG = <svg width='24px' height='24px' xmlns='http://www.w3.org/2000/svg'>
  <image href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAQAAAADQ4RFAAAAUklEQVR4AWOgGhgF/8HwPIrYeYgoIU0OKGIOxGm6jyJ2n5Cm8wwOQEUGKGIGQBEHoAwB0AA0FwEbSAgOBBwWmggHBOVBTjhyKU9GhBMslcAoAABPu2Hh6JIyeQAAAABJRU5ErkJggg==' x='0' y='0' width='24px' height='24px' />
</svg>;