import React from 'react';

import './Progress.scss';

const Progress = (props) => {
  const { complete, error, id } = props;

  return <div id={id} className={`form-progress-bar ${complete ? 'complete' : error ? 'error' : ''}`}></div>;
};
export default Progress;
