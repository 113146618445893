import React from 'react';
import { connect } from 'redux-bundler-react';

import { Accordion } from '@trussworks/react-uswds';
import NewSectionCard from '../_shared/NewSectionCard';
import NewApplicationModal from '@pages/Home/components/modals/NewApplication';
import JointPermitApplications from '../JointPermitApplications/JointPermitApplications';
import InternalLink from '@components/internal-link/InternalLink';
import LinkButton from '@components/link/linkButton';

import { FileTypes, ProfileRoles } from '@src/utils/enums';
import { SectionHeader } from '../_shared/helper';
import { permitAccordionList, permitsAvailableAccordionList, examplePlansFileList } from './PermittingHelper';

import '@pages/Resources/resources.scss';
import '@styles/_accordion.scss';


const Permitting = connect(
  'doAuthLogin',
  'doDownloadFile',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectAuthIsLoggedIn',
  'selectUserProfileData',
  ({
    doAuthLogin,
    doDownloadFile,
    doModalOpen,
    doUpdateRelativeUrl,
    authIsLoggedIn,
    userProfileData
  }) => {
    const drawingsAccordionList = [
      {
        title: 'Expand to View Resources',
        content: <>
          <p>These sample drawings will aid you in developing a plan for your project that is sufficiently clear for the USACE District to make a permit decision.</p>
          <p>While plans do not need to be professionally produced, they should be clear, accurate, and contain all necessary information. The example plans included in these instructions could be used as a guide when developing project plans. Following these examples will help facilitate an efficient review of the application and prevent the need for the USACE to request additional information.</p>
          <p><span className='text-bold'>Example Plans:</span></p>
          {examplePlansFileList.map((file, index) => (
            <p key={index} className={`margin-bottom-0 ${index !== 0 && 'margin-top-0'}`}>
              <LinkButton onClick={() => doDownloadFile(file.filename, FileTypes.Resource)} title={file.label} content={file.label} />
            </p>
          ))}
        </>,
        id: '6',
        headingLevel: 'h3'
      }
    ];

    const handleLink = () => {
      sessionStorage.setItem('PermitButtonSession', true);
      if (authIsLoggedIn) {
        const PermitButtonSession = sessionStorage.getItem('PermitButtonSession');
        doUpdateRelativeUrl('/home');
        PermitButtonSession && doModalOpen(NewApplicationModal);
        sessionStorage.removeItem('PermitButtonSession');
      } else {
        doAuthLogin();
      }
    };

    return (
      <>
        <section id='why-id'>
          <SectionHeader>Do I need a permit?</SectionHeader>
          <p>The U.S. Army Corps of Engineers (USACE) Regulatory Program administers and enforces Section 10 of the Rivers and Harbors Act of 1899; Section 404 of the Clean Water Act of 1972, as amended; and Section 103 of the Marine Protection, Research, and Sanctuaries Act of 1972.</p>
          <Accordion bordered items={permitAccordionList} multiselectable={true}  />
          <p className='margin-top-3'>If your project area contains waters of the United States and your project would impact these waters, you may need a permit. The size/extent of proposed project impacts to waters of the United States and the type of work you're conducting determines whether you need permit and the type of permit (general or standard) that is required.</p>
          <p>Read more about how the USACE determines the location of waters of the United States by visiting the <InternalLink href='/home/jurisdiction' title='Jurisdiction page' content='Jurisdiction page' />.</p>
          <p>All USACE permit decisions are subject to various other federal laws applicable to federal actions, such as the Endangered Species Act, the National Historic Preservation Act, tribal treaties, and the Magnuson-Stevens Fisheries Conservation and Management. Additional permits, licenses, variances, or similar authorization may be required by other federal, state, and local laws for the proposed activity.</p>
        </section >

        <section id='permit-types-id'>
          <SectionHeader>What types of permits are available?</SectionHeader>
          <p>The USACE has two types of permits, <span className='text-bold'>general</span> and <span className='text-bold'>standard</span> permits:</p>
          <Accordion bordered items={permitsAvailableAccordionList} multiselectable={true}  />
          <NewSectionCard
            className='margin-top-1'
            title='Unsure of what type of permit you need?'
            content={`If you're unsure of what type of permit you need, you can request a pre-application meeting with the USACE${authIsLoggedIn ? '.' : ' by signing up/logging in.'}`}
            onClick={() => handleLink()}
            buttonText={authIsLoggedIn ? 'Request a Pre-application Meeting' : 'Login Here'}
            buttonTitle={authIsLoggedIn ? 'Request a Pre-application Meeting' : 'Login Here'}
            isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
          />
        </section>

        <section id='clean-water-act-id' className='margin-top-3'>
          <SectionHeader>State Assumption of Clean Water Act, Section 404 Permitting Program (Michigan and New Jersey)</SectionHeader>
          <p>Under Section 404(g) of the Clean Water Act 33 U.S.C. 1344(g), any State or Tribe can administer its own individual and general permit program for the
            discharge of dredged or fill material into certain waters subject to Section 404 of the Clean Water Act in place of the USACE after the State or Tribal
            program is approved by the U.S. Environmental Protection Agency. The States of Michigan and New Jersey currently administer the Clean Water Act Section
            404 program in the assumed waters within their respective states, however the USACE retains Section 404 permitting authority over some of the waters within
            these states. The State of Florida had been approved to administer its own Section 404 program but that approval was vacated by a Federal court on 15 Feb 2024.
            The USACE is currently the only entity authorized to issue Section 404 permits within Florida, although this is subject to change as the litigation challenging
            Florida's assumption is subject to change as the litigation challenging Florida's assumption is ongoing.
          </p>
        </section>

        <JointPermitApplications />

        <section id='drawings-id' className='margin-top-3'>
          <SectionHeader>Permit Application Drawings - Examples by Project Type</SectionHeader>
          <p>Please review the sample drawing for typical projects requiring a permit from the U.S. Army Corps of Engineers.</p>
          <Accordion bordered items={drawingsAccordionList} multiselectable={true}  />
        </section>

        <section id='next-steps-id' className='margin-top-3'>
          <SectionHeader>What are my next steps?</SectionHeader>
          <div className='row margin-top-3'>
            <div className='col-lg-6 col-md-6 col-xs-12 margin-top-1'>
              <NewSectionCard
                title='Apply for a Permit'
                content={`If you are ready to apply for a permit, click here to ${authIsLoggedIn ? 'start a new request' : 'login'}.`}
                onClick={() => handleLink()}
                buttonText={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                buttonTitle={authIsLoggedIn ? 'Start a New Request' : 'Login'}
                isDisabled={userProfileData?.role?.toLowerCase() === ProfileRoles.USACERegulator.toString().toLowerCase()}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-xs-12 margin-top-1'>
              <NewSectionCard
                title='Need Further Assistance'
                content='If you are unsure, review the information below or try our Permit Process Guide.'
                onClick={() => doUpdateRelativeUrl('/home/permit-process-guide')}
                buttonText='Permit Process Guide'
                buttonTitle='PermitProcessGuide'
              />
            </div>
          </div>
        </section>
      </>
    );
  });

export default Permitting;
