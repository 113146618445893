import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NWO_OMAHA = () => (
  <>
    <section id='nwo-omaha'>
      <DistrictInfoTable district='NWO'/>
      <p>
      If you are planning to do work on or near a waterway in the State of Montana, please use the <ExternalLink content='Montana Joint Application Form' title='Montana Joint Application Form' href='https://dnrc.mt.gov/Licenses-and-Permits/Stream-Permitting' />.  <ExternalLink content='The Omaha District website' title='The Omaha District Website' href='https://www.nwo.usace.army.mil/Missions/Regulatory-Program/' /> contains information on how to obtain a permit and the permit application forms.
      </p>
    </section>
  </>
);

export default NWO_OMAHA;
