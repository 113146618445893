import { useEffect, useMemo } from 'react';

// Custom hook definition
function useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger = false, isReadOnly }) {
  // Memoizing the step status based on steps and stepNo
  const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);

  useEffect(() => {
    if (thisSectionStepStatus === 'true' && activeStep?.id === stepNo) {
      (!isReadOnly && noTrigger === false) && trigger();
      !isReadOnly && document.getElementById('error_list').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [thisSectionStepStatus, trigger, activeStep, stepNo, noTrigger, isReadOnly]);
}

export default useErrorFocus;
