const ApplicantTableCell = ({ row }) => {
  const company = row.getValue('applicantCompany');
  const name = row.getValue('applicantName');
  const displayName = company || name;

  return (
    <>
      <span>{displayName}</span>
    </>
  );
};

export default ApplicantTableCell;
