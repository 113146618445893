import { Link } from '@trussworks/react-uswds';

/**
 * Reusable link component with many options to style and transform.
 *
 * @param {string} content - text do be display on the link
 * @param {string} title - text to be read be screen readers and onHover. if not provided, text prop will be used
 * @param {string} href - a uri for the user to be taken to onClick. overrides and removes handleClick prop if provided.
 * @param {boolean} openInNewTab - whether the link should open in a new tab
 */

const InternalLink = ({ target, rel, title, content, href, openInNewTab, ...customProps }) => {
  const currentEnv = import.meta.env.VITE_ENVIRONMENT;
  const base = () => {
    if (currentEnv === 'local' || currentEnv === 'development') {
      return '';
    } else {
      return !openInNewTab ? '' : '/rrs';
    }
  };

  const handleClick = () => {
    if (!openInNewTab && customProps.onClick) {
      customProps.onClick();
    }
  };

  const handleKeyDown = (e) => {
    e.key === 'Enter' && handleClick();
  };

  return (
    <Link
      href={base() + href}
      onClick={handleClick}
      onKeyDown={(e) => handleKeyDown(e)}
      rel={openInNewTab ? 'noopener noreferrer' : rel}
      tabIndex={0}
      target={openInNewTab ? '_blank' : target}
      title={title ?? undefined}
      {...customProps}
    >
      {content ?? href}
    </Link>
  );
};

export default InternalLink;
