export const questionSet1 = [
  { value: 1, label: 'I would like to know if U.S. Army Corps of Engineers jurisdictional wetlands, streams, or other waterways are present on property located in the United States or it\'s Territories.' },
  { value: 2, label: 'I am aware of U.S. Army Corps of Engineers jurisdictional waters on property located in the United States or it\'s Territories and would like to apply for a permit to impact these waters.' }
];

export const questionSet2 = {
  1: [
    { value: 1, label: 'I have a delineation report which includes potentially regulated waters which I would like to submit to the U.S. Army Corps of Engineers for review and/or approval.' },
    { value: 2, label: 'I would like to consult with the U.S. Army Corps of Engineers on whether I need to have a delineation of waters of the United States conducted on project site and/or a jurisdictional determination letter issued.' },
  ],
  2: [
    { value: 3, label: 'I have reviewed the "Getting Started Guide" and believe that my project requires an Individual Permit review (Standard or Letter of Permission).' },
    { value: 4, label: 'I have reviewed the "Getting Started Guide" and believe that my project qualifies for a General Permit (Nationwide or Regional General Permit).' },
    { value: 5, label: 'I am unclear of what is required for the U.S. Army Corps of Engineers to issue a public notice for an individual permit and would like to schedule a pre-application meeting.' }
  ]
};

// export const questionSet3 = {
//   3: [
//     { value: 3, label: 'I believe I possess sufficient information for the U.S. Army Corps of Engineers to issue a public notice. (See 33 CPR 325.1(d) and 325.3(a))' },
//     { value: 5, label: 'I am unclear of what is required for the U.S. Army Corps of Engineers to issue a public notice for an individual permit and would like to schedule a pre-application meeting.' }
//   ]
// };