import { connect } from 'redux-bundler-react';
import Icon from '@components/icon/Icon';
import { mdiNoteEdit, mdiFileCheckOutline } from '@mdi/js';

import './FormStatus.scss';

const FormStatus = connect('selectRequestStatus', ({ requestStatus }) => {
  const icon = requestStatus === 'Draft' ? mdiNoteEdit : mdiFileCheckOutline;
  let statusClass;

  switch (requestStatus) {
    case 'Draft':
      statusClass = 'draft';
      break;
    case 'Submitted':
      statusClass = 'submitted';
      break;
    case 'Acknowledged':
      statusClass = 'acknowledged';
      break;
    case 'Not Approved':
      statusClass = 'notApproved';
      break;
    case 'Lead Agency Changed':
      statusClass = 'lead-agency-changed';
      break;
    case 'Re-Routed':
      statusClass = 're-routed';
      break;
    case 'Assigned':
      statusClass = 'assigned';
      break;
    default:
      statusClass = 'draft';
      break;
  }

  return (
    <div className={`form-status ${statusClass}`}>
      <Icon focusable={false} className='form-status-icon' path={icon} size={1} /> {requestStatus}
    </div>
  );
});

export default FormStatus;
