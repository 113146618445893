import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const SPK_SACRAMENTO = () => (
  <>
    <section id='spk-sacramento'>
      <DistrictInfoTable district='SPK'/>
      <p>
      In order to apply for the Joint Permit between the U.S Army Corps of Engineers, for Section 404 CWA/Section 10 RHA and the Utah State Engineer's Office, for Section 73-3-29 of the Utah Code, (aka General Permit 10) for impacts to streams within the State of Utah, you must use the <ExternalLink content='Joint Application Form' title='Joint Application Form' href='https://waterrights.utah.gov/strmalt/JointPermitApplication.pdf' />.  This PDF document is an editable form used to apply for a stream alteration permit.  This form can be filed in person at any of the Utah Division of Water Rights offices, mailed to those offices, or submitted online with a credit card payment.  Impacts to other aquatic resources such as wetlands, lakes, ponds, playas, etc. are not eligible for use with General Permit 10.  Impacts to these aquatic resources will require an alternate form of <ExternalLink content='Department of the Army authorization' title='Department of the Army Authorization' href='https://www.spk.usace.army.mil/Missions/Regulatory.aspx' /> such as a Nationwide Permit, Letter of Permission, or Individual Permit.
      </p>
    </section>
  </>
);

export default SPK_SACRAMENTO;
