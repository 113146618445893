const arrayIze = (thing) => (!thing || Array.isArray(thing) ? thing : [thing]);

const shouldSkipToken = (method, path, unless) => {
  let skip = false;
  // check for method
  if (unless && unless.method) {
    const methods = arrayIze(unless.method);
    if (methods.indexOf(method) !== -1) skip = true;
  }

  // check for path
  if (!skip) {
    if (unless && unless.path) {
      const paths = arrayIze(unless.path);
      if (paths.indexOf(path) !== -1) skip = true;
    }
  }

  // check custom
  if (!skip) {
    if (unless && unless.custom) {
      if (typeof unless.custom === 'function') {
        skip = unless.custom({ method: method, path: path });
      }
    }
  }

  return skip;
};

const commonFetch = async (root, path, options, callback, type = 'json') => {
  let attempts = 0;
  const maxAttempts = 5;
  const retryInterval = 5000;

  const callFetch = async () => {
    attempts++;
    try {
      const res = await fetch(`${root}${path}`, options);
      const body = type === 'json' ? await res.json() : await res.blob();
      if (callback && typeof callback === 'function') {
        callback(null, body);
        return;
      }
    } catch (e) {
      if (options.method === 'GET' && attempts < maxAttempts) {
        console.error('The following error occurred:', e, `Retry ${attempts}`);
        await new Promise((resolve) => setTimeout(resolve, retryInterval));
        await callFetch();
        return;
      } else {
        console.error(e);
        if (callback && typeof callback === 'function') {
          callback(e, null);
          return;
        }
      }
    }
  };

  await callFetch();
};

const createJwtApiBundle = (opts) => {
  const defaults = {
    name: 'api',
    root: '',
    tokenSelector: 'selectAuthToken',
    unless: null,
  };

  const config = Object.assign({}, defaults, opts);

  const uCaseName = config.name.charAt(0).toUpperCase() + config.name.slice(1);

  // selectors
  const selectRoot = `select${uCaseName}Root`;
  const selectUnless = `select${uCaseName}Unless`;
  const selectTokenSelector = `select${uCaseName}TokenSelector`;

  return {
    name: config.name,

    getReducer: () => {
      const initialData = {
        root: config.root,
        unless: config.unless,
        tokenSelector: config.tokenSelector,
        sessionIdleCount: 0,
      };

      return (state = initialData, { type, payload }) => {
        if (import.meta.env.VITE_ENVIRONMENT === 'local' && import.meta.env.VITE_SHOW_REDUCER_LOGS === 'true')
          console.log(type, payload); // eslint-disable-line no-console

        if (type === 'APP_IDLE') {
          return {
            ...state,
            sessionIdleCount: state.sessionIdleCount + 1,
          };
        } else if (type === 'UPDATE_AUTH') {
          return state;
        } else {
          return {
            ...state,
            sessionIdleCount: 0,
          };
        }
      };
    },

    [selectRoot]: (state) => state[config.name].root,
    [selectUnless]: (state) => state[config.name].unless,
    [selectTokenSelector]: (state) => state[config.name].tokenSelector,
    selectSessionIdleCount: (state) => state[config.name].sessionIdleCount,

    getExtraArgs: (store) => {
      const getCommonItems = () => ({
        root: store[selectRoot](),
        unless: store[selectUnless](),
        tokenSelector: store[selectTokenSelector](),
      });

      const defaultHeaders = (token) => ({
        Authorization: `Bearer ${token}`,
      });

      return {
        apiFetch: (path, options = {}, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...options.headers,
                ...defaultHeaders(token),
              };
            }
          }
          return fetch(`${root}${path}`, options);
        },

        apiGet: (path, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'GET',
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...defaultHeaders(token),
              };
            }
          }
          commonFetch(root, path, options, callback);
        },

        apiPut: (path, payload, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...options.headers,
                ...defaultHeaders(token),
              };
            }
          }
          if (payload) {
            options.body = JSON.stringify(payload);
          }
          commonFetch(root, path, options, callback);
        },

        apiPost: (path, payload, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...options.headers,
                ...defaultHeaders(token),
              };
            }
          }
          if (payload) {
            options.body = JSON.stringify(payload);
          }

          commonFetch(root, path, options, callback);
        },

        apiPostPDF: (path, payload, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...options.headers,
                ...defaultHeaders(token),
              };
            }
          }
          if (payload) {
            options.body = JSON.stringify(payload);
          }

          commonFetch(root, path, options, callback, 'blob');
        },

        apiPostFile: (path, payload, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'POST',
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = {
                ...options.headers,
                ...defaultHeaders(token),
              };
            }
          }
          if (payload) {
            options.body = payload;
          }

          commonFetch(root, path, options, callback);
        },

        apiDelete: (path, callback, skipAuth) => {
          const { root, unless, tokenSelector } = getCommonItems();
          const options = {
            method: 'DELETE',
          };
          if (!shouldSkipToken(options.method, path, unless) && !skipAuth) {
            const token = store[tokenSelector]();
            if (!token) return null;
            else {
              options.headers = { ...defaultHeaders(token) };
            }
          }

          commonFetch(root, path, options, callback);
        },
      };
    },
  };
};

export default createJwtApiBundle;
