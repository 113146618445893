import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRL_LOUISVILLE = () => (
  <>
    <section id='lrl-louisville'>
      <DistrictInfoTable district='LRL' orgEmail='CELRL.Door.To.The.Corps@usace.army.mil'/>
      <p>
       In the <ExternalLink content='Louisville District' title='Louisville District' href='https://www.lrd.usace.army.mil/Wetlands-Permits/' />, the Regulatory Request System (RRS) can be used as an option to submit Department of the Army Individual or Nationwide permit application materials to the Louisville District for work within Waters of the U.S.  However, please note that the Indiana Regional General Permit (RGP) Notification Form is currently not available in RRS.  To submit an Indiana RGP Notification Form, please email the completed application materials to <InternalLink content='CELRL.Door.To.The.Corps@usace.army.mil' title='CELRL.Door.To.The.Corps@usace.army.mil' href='mailto:CELRL.Door.To.The.Corps@usace.army.mil' />.
      </p>
    </section>
  </>
);

export default LRL_LOUISVILLE;
