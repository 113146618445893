import React from 'react';
import { connect } from 'redux-bundler-react';
import LinkButton from '../../app-components/link/linkButton.jsx';

const NotLoggedIn = connect(
  'doAuthLogin',
  ({
    doAuthLogin,
  }) => (
    <div className='container mt-3'>
      <p>You are currently not logged in. To login, <LinkButton onClick={() => doAuthLogin()} title='click here' content='click here' /> or use the link on the Navigation Menu.</p>
    </div>

  )
);

export default NotLoggedIn;
