const fileUploadBundle = {
  name: 'fileupload',
  getReducer: () => {
    const initialData = {
      geometry: null,
      aquaticresources: null,
      aquaticresourcesgdb: null,
      propertyowners: null,
      adjoiningpropertyowners: null,
      agent_authorization: null,
      agent_authorization_file: null,
      agent_authorization_comment: null,
      right_of_entry: null,
      right_of_entry_file: null,
      right_of_entry_comment: null,
      permitteemitigations: null,
      bankilfmitigations: null,
      impacts: null,
      regulation: null,
    };

    return (state = initialData, action) => {

      switch (action.type) {
        case 'UPDATE_FILE_OBJECT':
          return {
            ...state,
            [action.field]: action.value,
          };
        case 'RESET_FILE_OBJECT_STATE':
          return initialData;
        case 'RESET_FILE_OBJECT':
          return { ...state, [action.field]: null, };
        default:
          return state;
      }

    };
  },
  doSetFileObject: (field, value) => ({ dispatch }) => {
    dispatch({
      type: 'UPDATE_FILE_OBJECT',
      field,
      value
    });
  },
  doRemoveFileObject: (field) => ({ dispatch }) => {
    dispatch({
      type: 'RESET_FILE_OBJECT',
      field
    });
  },
  doResetFileObjectState: (field) => ({ dispatch }) => {
    dispatch({
      type: 'RESET_FILE_OBJECT_STATE'
    });
  },
  selectFileObjectPropOwners: (state) => state.fileupload.propertyowners,
  selectFileObjectAdjoiningPropOwners: (state) => state.fileupload.adjoiningpropertyowners,
  selectFileObjectAqResources: (state) => state.fileupload.aquaticresources,
  selectFileObjectImpacts: (state) => state.fileupload.impacts,
  selectFileObjectPermitteeMitigations: (state) => state.fileupload.permitteemitigations,
  selectFileObjectBankILFMitigations: (state) => state.fileupload.bankilfmitigations,
  selectFileObjectRegulation: (state) => state.fileupload.regulation,
  selectFileObjectGeometry: (state) => state.fileupload.geometry,
  selectFileObjectRightOfEntry: (state) => state.fileupload.right_of_entry,
  selectFileObjectAgentAuthorization: (state) => state.fileupload.agent_authorization,
  selectFileObjectRightOfEntryComment: (state) => state.fileupload.right_of_entry_comment,
  selectFileObjectAgentAuthorizationComment: (state) => state.fileupload.agent_authorization_comment,
  selectFileObject: (state) => state.fileupload,

  selectFileObjectRightOfEntryFile: (state) => state.fileupload.right_of_entry_file,
  selectFileObjectAgentAuthorizationFile: (state) => state.fileupload.agent_authorization_file,



};

export default fileUploadBundle;
