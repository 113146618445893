import { useRef, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import DeleteModal from './DeleteModal';
import WithdrawModal from './WithdrawModal';
import Icon from '@components/icon/Icon';
import { mdiFileEye, mdiFileDocumentEdit, mdiCancel, mdiFolderText, mdiNoteOffOutline, mdiFilePdfBox } from '@mdi/js';
import FilesModal from './FilesModal';

import './../dashboard.scss';
import './../../Forms/components/gridCellRenderers/cellRenderers.scss';

const PopupRenderer = connect(
  'doFetchGPPermitsByDistrict',
  'doFetchCreditUnitsByDistrict',
  'doFetchViolationComplaint',
  'doFetchPreApp',
  'doFetchJD',
  'doFetchPermit',
  'doResetReduxFormData',
  'doUpdateComplexStateField',
  'doCancelProject',
  'doWithdrawProject',
  'doModalOpen',
  ({
    doFetchGPPermitsByDistrict,
    doFetchCreditUnitsByDistrict,
    doFetchViolationComplaint,
    doFetchPreApp,
    doFetchJD,
    doFetchPermit,
    doResetReduxFormData,
    doUpdateComplexStateField,
    doCancelProject,
    doWithdrawProject,
    doModalOpen,
    data,
    api,
    column,
    rowIndex,
  }) => {
    const iconRef1 = useRef(null);
    const iconRef2 = useRef(null);
    const iconRef3 = useRef(null);
    const iconRef4 = useRef(null);
    const iconRef5 = useRef(null);

    const isAcknowledged = data.requestStatus === 'Acknowledged';
    const isAdditionalInfo = data.requestStatus === 'Additional Information Requested';
    const isAssigned = data.requestStatus === 'Assigned';
    const isDraft = data.requestStatus === 'Draft';
    const isInsufficient = data.requestStatus === 'Insufficient';
    const isReRouted = data.requestStatus === 'Re-Routed';
    const isReSubmitted = data.requestStatus === 'Resubmitted';
    const isRevision = data.requestStatus === 'Revision';
    const isUnderRevision = data.requestStatus === 'Under Revision';
    const isSubmitted = data.requestStatus === 'Submitted';

    const isViewDocumentsVisible = !isDraft;
    const [hasTabbedViewDocuments, setHasTabbedViewDocuments] = useState(false);

    const isCancelVisible = isDraft;
    const [hasTabbedCancel, setHasTabbedCancel] = useState(false);

    const isWithdrawVisible = (isAcknowledged || isAdditionalInfo || isAssigned || isInsufficient || isReRouted
      || isReSubmitted || isRevision || isUnderRevision || isSubmitted);
    const [hasTabbedWithdraw, setHasTabbedWithdraw] = useState(false);

    const handleEditIconClick = (isGenerate) => {
      doResetReduxFormData();
      doUpdateComplexStateField({ name: 'isreadonly', value: !isDraft });
      switch (data?.requestType) {
        case 'Pre-application/Project Scoping':
          doFetchPreApp(data.projectID, data.requestID, data.id, data.requestStatus, isGenerate);
          break;
        case 'Violation Complaint':
          doFetchViolationComplaint(data.projectID, data.requestID, data.requestStatus, isGenerate);
          break;
        case 'Jurisdictional Determination':
          doFetchJD(data.projectID, data.requestID, data.id, '2', data.requestStatus, isGenerate);
          break;
        case 'Delineation Report':
          doFetchJD(data.projectID, data.requestID, data.id, '1', data.requestStatus, isGenerate);
          break;
        case 'Delineation Report and Jurisdictional Determination':
          doFetchJD(data.projectID, data.requestID, data.id, '1', data.requestStatus, isGenerate);
          break;
        case 'Nationwide Permit (ENG 6082)':
          doFetchPermit(data.projectID, data.requestID, data.id, '4', data.requestStatus, isGenerate);
          doFetchGPPermitsByDistrict(data.district);
          doFetchCreditUnitsByDistrict(data.district);
          break;
        case 'Individual Permit (ENG 4345)':
          doFetchPermit(data.projectID, data.requestID, data.id, '3', data.requestStatus, isGenerate);
          doFetchGPPermitsByDistrict(data.district);
          doFetchCreditUnitsByDistrict(data.district);
          break;
        default:
          alert('NO FETCH API IS AVAILABLE FOR THIS REQUEST TYPE!');
          break;
      }
    };

    const handleWithdraw = () => {
      doWithdrawProject(data.projectID, data.requestID);
    };

    const getClassName = () => {
      switch (data.requestStatus) {
        case 'Draft':
          return 'info';
        case 'Submitted':
          return 'submitted';
        case 'Acknowledged':
          return 'acknowledged';
        case 'Not Approved':
          return 'not-approved';
        case 'Lead Agency Changed':
          return 'lead-agency-changed';
        case 'Re-Routed':
          return 're-routed';
        case 'Assigned':
          return 'assigned';
        case 'Withdrawn':
          return 'info';
        default:
          break;
      }
    };

    const handleTab = (event) => {
      if (event.key === 'Tab') {
        if (document.activeElement === iconRef1.current) {
          event.preventDefault();
          event.stopPropagation();

          iconRef1.current.focus();
        }
        else if (isViewDocumentsVisible && !hasTabbedViewDocuments) {
          event.preventDefault();
          event.stopPropagation();

          iconRef2.current.focus();
          setHasTabbedViewDocuments(true);
        }
        else if (isCancelVisible && !hasTabbedCancel) {
          event.preventDefault();
          event.stopPropagation();

          iconRef3.current.focus();
          setHasTabbedCancel(true);
        }
        else if (isWithdrawVisible && !hasTabbedWithdraw) {
          event.preventDefault();
          event.stopPropagation();

          iconRef4.current.focus();
          setHasTabbedWithdraw(true);
        }
        else {
          document.activeElement.focus();
          setHasTabbedViewDocuments(false);
          setHasTabbedCancel(false);
          setHasTabbedWithdraw(false);
        }
      }
    };

    const handleDeleteButtonClick = () => {
      doCancelProject(data.projectID, data.requestID);
    };

    useEffect(() => {
      const onCellFocused = (params) => {
        if (params.column.colId === column.colId && params.rowIndex === rowIndex) {
          iconRef1.current.focus();
        }
      };
      api.addEventListener('cellFocused', onCellFocused);
      return () => {
        api.removeEventListener('cellFocused', onCellFocused);
      };
    }, [api, rowIndex, column.colId]);

    const handleKeyUp = (e, iconIndex) => {
      e.preventDefault();
      e.stopPropagation();

      if (e.key === 'Enter') {
        switch (iconIndex) {
          case 1:
            handleEditIconClick();
            break;
          case 2:
            doModalOpen(FilesModal, { data: data });
            break;
          case 3:
            doModalOpen(DeleteModal, { deleteClick: handleDeleteButtonClick, data: data });
            break;
          case 4:
            doModalOpen(WithdrawModal, { withdrawClick: handleWithdraw, data: data });
            break;
          case 5:
            handleEditIconClick(true);
            break;
          default:
            break;
        }
      }
    };

    return (
      <>
        {data.requestStatus !== 'Cancelled' && <div className='renderer-div'>
          <div role='button' title={isDraft ? 'Edit Request' : 'View Request'} onClick={() => handleEditIconClick()} onKeyUp={e => handleKeyUp(e, 1)} onKeyDown={handleTab} tabIndex={0} ref={iconRef1}>
            <Icon focusable={false} className={`cell-btn-icon ${getClassName()}`} path={isDraft ? mdiFileDocumentEdit : mdiFileEye} size={'28px'} />
          </div>
          {isViewDocumentsVisible && <div role='button' title='View Documents' onClick={() => doModalOpen(FilesModal, { data: data })} onKeyUp={e => handleKeyUp(e, 2)} onKeyDown={handleTab} ref={iconRef2} tabIndex={1}>
            <Icon focusable={false} className='cell-btn-icon info' path={mdiFolderText} size={'28px'} />
          </div>}
          {isDraft && <div role='button' title='Download all draft documents with currently entered information for this request' onClick={() => handleEditIconClick(true)} onKeyUp={e => handleKeyUp(e, 5)} onKeyDown={handleTab} ref={iconRef5} tabIndex={1}>
            <Icon focusable={false} className='cell-btn-icon info' path={mdiFilePdfBox} size={'28px'} />
          </div>}
          {isCancelVisible && <div role='button' title='Cancel Draft Request' onClick={() => doModalOpen(DeleteModal, { deleteClick: handleDeleteButtonClick, data: data })} onKeyDown={handleTab} onKeyUp={e => handleKeyUp(e, 3)} ref={iconRef3} tabIndex={2}>
            <Icon focusable={false} className='cell-btn-icon delete' path={mdiCancel} size={'28px'} />
          </div>}
          {isWithdrawVisible &&
            <div role='button' title='Withdraw Request' onClick={() => doModalOpen(WithdrawModal, { withdrawClick: handleWithdraw, data: data })} onKeyDown={handleTab} onKeyUp={e => handleKeyUp(e, 4)} ref={iconRef4} tabIndex={3}>
              <Icon focusable={false} className='cell-btn-icon delete' path={mdiNoteOffOutline} size={'28px'} />
            </div>}
        </div>}
      </>
    );
  });

export default PopupRenderer;
