import React from 'react';
import { Row } from '../../../_shared/helper';
import Card from '../../../../app-components/card/card';

import './FieldHeader.scss';

const FieldHeader = (props) => {
  const { text, subtext, children } = props;
  return (
    <>
      <Row className='mb-3'>
        <div className='w-100' id='fieldheader'>
          <h3>{text}</h3>
        </div>
      </Row>
      {(subtext || children) && (<Row className='mb-3'>
        <div className='w-100 pb-3' id='fieldheader'>
          <Card>
            <Card.Body>
              {subtext}
              {children && children}
            </Card.Body>
          </Card>
        </div>
      </Row>)}
    </>


  );
};

export default FieldHeader;
