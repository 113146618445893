import Icon from '@components/icon/Icon';
import { mdiFolderUploadOutline, mdiMagnify, mdiPlus, mdiMinus, mdiMapMarker, mdiTrashCanOutline, mdiLayersTriple } from '@mdi/js';
import { fullscreenSVG, polyLineSVG, polygonSVG } from '@src/utils/svg';

export const stateAbbreviations = {
  AL: 'ALABAMA',
  AK: 'ALASKA',
  AZ: 'ARIZONA',
  AR: 'ARKANSAS',
  CA: 'CALIFORNIA',
  CO: 'COLORADO',
  CT: 'CONNECTICUT',
  DE: 'DELAWARE',
  FL: 'FLORIDA',
  GA: 'GEORGIA',
  HI: 'HAWAII',
  ID: 'IDAHO',
  IL: 'ILLINOIS',
  IN: 'INDIANA',
  IA: 'IOWA',
  KS: 'KANSAS',
  KY: 'KENTUCKY',
  LA: 'LOUISIANA',
  ME: 'MAINE',
  MD: 'MARYLAND',
  MA: 'MASSACHUSETTS',
  MI: 'MICHIGAN',
  MN: 'MINNESOTA',
  MS: 'MISSISSIPPI',
  MO: 'MISSOURI',
  MT: 'MONTANA',
  NE: 'NEBRASKA',
  NV: 'NEVADA',
  NH: 'NEW HAMPSHIRE',
  NJ: 'NEW JERSEY',
  NM: 'NEW MEXICO',
  NY: 'NEW YORK',
  NC: 'NORTH CAROLINA',
  ND: 'NORTH DAKOTA',
  OH: 'OHIO',
  OK: 'OKLAHOMA',
  OR: 'OREGON',
  PA: 'PENNSYLVANIA',
  RI: 'RHODE ISLAND',
  SC: 'SOUTH CAROLINA',
  SD: 'SOUTH DAKOTA',
  TN: 'TENNESSEE',
  TX: 'TEXAS',
  UT: 'UTAH',
  VT: 'VERMONT',
  VA: 'VIRGINIA',
  WA: 'WASHINGTON',
  WV: 'WEST VIRGINIA',
  WI: 'WISCONSIN',
  WY: 'WYOMING',
  AS: 'AMERICAN SAMOA',
  DC: 'DISTRICT OF COLUMBIA',
  FM: 'FEDERATED STATES OF MICRONESIA',
  GU: 'GUAM',
  MH: 'MARSHALL ISLANDS',
  MP: 'NORTHERN MARIANA ISLANDS',
  PW: 'PALAU',
  PR: 'PUERTO RICO',
  VI: 'VIRGIN ISLANDS'
};

export const stateAbbrProperCase = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  AS: 'American Samoa',
  DC: 'District of Columbia',
  FM: 'Federated States of Micronesia',
  GU: 'Guam',
  MH: 'Marshall Islands',
  MP: 'Northern Mariana Islands',
  PW: 'Palau',
  PR: 'Puerto Rico',
  VI: 'Virgin Islands'
};

export const states = [
  { value: 'AL', text: 'Alabama' },
  { value: 'AK', text: 'Alaska' },
  { value: 'AZ', text: 'Arizona' },
  { value: 'AR', text: 'Arkansas' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'CA', text: 'California' },
  { value: 'CO', text: 'Colorado' },
  { value: 'CT', text: 'Connecticut' },
  { value: 'DE', text: 'Delaware' },
  { value: 'DC', text: 'District of Columbia' },
  { value: 'FL', text: 'Florida' },
  { value: 'GA', text: 'Georgia' },
  { value: 'GU', text: 'Guam' },
  { value: 'HI', text: 'Hawaii' },
  { value: 'ID', text: 'Idaho' },
  { value: 'IL', text: 'Illinois' },
  { value: 'IN', text: 'Indiana' },
  { value: 'IA', text: 'Iowa' },
  { value: 'KS', text: 'Kansas' },
  { value: 'KY', text: 'Kentucky' },
  { value: 'LA', text: 'Louisiana' },
  { value: 'ME', text: 'Maine' },
  { value: 'MD', text: 'Maryland' },
  { value: 'MA', text: 'Massachusetts' },
  { value: 'MI', text: 'Michigan' },
  { value: 'MN', text: 'Minnesota' },
  { value: 'MS', text: 'Mississippi' },
  { value: 'MO', text: 'Missouri' },
  { value: 'MT', text: 'Montana' },
  { value: 'NE', text: 'Nebraska' },
  { value: 'NV', text: 'Nevada' },
  { value: 'NH', text: 'New Hampshire' },
  { value: 'NJ', text: 'New Jersey' },
  { value: 'NM', text: 'New Mexico' },
  { value: 'NY', text: 'New York' },
  { value: 'NC', text: 'North Carolina' },
  { value: 'ND', text: 'North Dakota' },
  { value: 'MP', text: 'Commonwealth of the Northern Mariana Islands' },
  { value: 'OH', text: 'Ohio' },
  { value: 'OK', text: 'Oklahoma' },
  { value: 'OR', text: 'Oregon' },
  { value: 'PA', text: 'Pennsylvania' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'RI', text: 'Rhode Island' },
  { value: 'SC', text: 'South Carolina' },
  { value: 'SD', text: 'South Dakota' },
  { value: 'TN', text: 'Tennessee' },
  { value: 'TX', text: 'Texas' },
  { value: 'UT', text: 'Utah' },
  { value: 'VT', text: 'Vermont' },
  { value: 'VI', text: 'U.S. Virgin Islands' },
  { value: 'VA', text: 'Virginia' },
  { value: 'WA', text: 'Washington' },
  { value: 'WV', text: 'West Virginia' },
  { value: 'WI', text: 'Wisconsin' },
  { value: 'WY', text: 'Wyoming' },
  { value: 'UM', text: 'U.S. Minor Outlying Islands' },
  { value: 'FM', text: 'Federated States of Micronesia' },
  { value: 'PW', text: 'Palau' },
];

// create key value array for quickly getting full state name from alpha2 code (e.g. stateKeys['AL'] => 'Alabama')
export const stateKeys = states.reduce((r, {text, value}) => {
  r[value] = text;
  return r;
}, {});

export const salutations = [
  { value: 'Dr.', text: 'Dr.' },
  { value: 'Miss.', text: 'Miss.' },
  { value: 'Mr.', text: 'Mr.' },
  { value: 'Mrs.', text: 'Mrs.' },
  { value: 'Ms.', text: 'Ms.' },
  { value: 'Mx.', text: 'Mx.' },
];

export const phonetypes = [
  { value: 'Residence', text: 'Residence' },
  { value: 'Business', text: 'Business' },
  { value: 'Primary', text: 'Primary' },
  { value: 'Secondary', text: 'Secondary' },
  { value: 'Mobile', text: 'Mobile' },
];

// NOTE: make sure any special characters are removed from the values
export const jddoctypes = [
  { value: 'Aerial Photograph', text: 'Aerial Photograph(s)' },
  { value: 'Site Photograph', text: 'Site Photograph(s)' },
  { value: 'Case Law', text: 'Applicable supporting case law' },
  { value: 'Scientific Literature', text: 'Applicable supporting scientific literature' },
  { value: 'USACE navigable waters study', text: 'USACE navigable waters study' },
  { value: 'Wetlands Datasheet', text: 'Wetland Datasheets' },
  { value: 'OHWM Datasheet', text: 'OHWM Datasheets' },
  { value: 'FEMA FIRM Map', text: 'FEMA/FIRM Maps' },
  { value: 'Map plan plot or plat', text: 'Maps, plans, plots or plats' },
  { value: 'National Wetland Inventory Map', text: 'National Wetland Inventory Map' },
  { value: 'Other Information', text: 'Other Information' },
  { value: 'State Local Wetland Inventory Map', text: 'State/Local Wetland Inventory Map' },
  { value: 'US Geological Survey Map', text: 'U.S. Geological Survey Map' },
  { value: 'USDS NRCS Soil Survey Map', text: 'USDS NRCS Soil Survey Map' },
  { value: 'USGS Hydrologic Unit Code Map', text: 'USGS Hydrologic Unit Code Map' },
  { value: 'USGS National Hydrography Dataset Map', text: 'USGS National Hydrography Dataset Map' },
  { value: 'Automated Wetland Datasheet', text: 'Automated Wetland Datasheet (excel file)' },
];

// NOTE: make sure any special characters are removed from the values
export const permitdoctypes = [
  { value: 'Project Plans', text: 'Project Plans' },
  { value: 'State Water Quality Certification', text: 'State Water Quality Certification' },
  { value: 'Endangered Threatened Species Information', text: 'Endangered/Threatened Species Information' },
  { value: 'Historic Properties Cultural Resources Information', text: 'Historic Properties/Cultural Resources Information' },
  { value: 'Aerial Photographs', text: 'Aerial Photograph(s)' },
  { value: 'Site Photographs', text: 'Site Photograph(s)' },
  { value: 'Applicable supporting case law', text: 'Applicable supporting case law' },
  { value: 'Applicable supporting scientific literature', text: 'Applicable supporting scientific literature' },
  { value: 'USACE navigable waters study', text: 'USACE navigable waters study' },
  { value: 'Wetland Datasheets', text: 'Wetland Datasheets' },
  { value: 'Delineation Report', text: 'Delineation Report' },
  { value: 'OHWM Datasheets', text: 'OHWM Datasheets' },
  { value: 'FEMA FIRM Maps', text: 'FEMA/FIRM Maps' },
  { value: 'Maps plans plots or plats', text: 'Maps, plans, plots or plats' },
  { value: 'Mitigation Plan', text: 'Mitigation Plan' },
  { value: 'National Wetland Inventory Map', text: 'National Wetland Inventory Map' },
  { value: 'Other Information', text: 'Other Information' },
  { value: 'State Local Wetland Inventory Map', text: 'State/Local Wetland Inventory Map' },
  { value: 'US Geological Survey Map', text: 'U.S. Geological Survey Map' },
  { value: 'USDS NRCS Soil Survey Map', text: 'USDS NRCS Soil Survey Map' },
  { value: 'USGS Hydrologic Unit Code Map', text: 'USGS Hydrologic Unit Code Map' },
  { value: 'USGS National Hydrography Dataset Map', text: 'USGS National Hydrography Dataset Map' },
  { value: 'Automated Wetland Datasheet', text: 'Automated Wetland Datasheet (excel file)' },
  { value: 'Other', text: 'Other' },
];

// NOTE: make sure any special characters are removed from the values
export const preappdoctypes = [
  { value: 'Delineation of waters of the United States', text: 'Delineation of waters of the United States' },
  { value: 'Draft Alternative Analysis', text: 'Draft Alternative Analysis' },
  { value: 'Threatened Endangered Species Report', text: 'Threatened/Endangered Species Report' },
  { value: 'Draft avoidance and minimization statement and or conceptual compensatory mitigation plan', text: 'Draft avoidance and minimization statement and/or conceptual compensatory mitigation plan' },
  { value: 'Scoping Letter', text: 'Scoping Letter' },
  { value: 'Draft prospectus conceptual mitigation plan', text: 'Draft prospectus/conceptual mitigation plan' },
  { value: 'Other', text: 'Other' },
];

// NOTE: make sure any special characters are removed from the values
export const violationComplaintDocTypes = [
  { value: 'Photographs', text: 'Photographs' },
  { value: 'Maps', text: 'Maps' },
  { value: 'Plans', text: 'Plans' },
  { value: 'Other', text: 'Other' },
];

export const requestreasons = [
  { value: 'I intend to construct/develop a project or perform activities on this parcel which would be designed to avoid all aquatic resources.', label: 'I intend to construct/develop a project or perform activities on this parcel which would be designed to avoid all aquatic resources' },
  { value: 'I intend to construct/develop a project or perform activities on this parcel which would be designed to avoid all jurisdictional aquatic resources under USACE authority.', label: 'I intend to construct/develop a project or perform activities on this parcel which would be designed to avoid all jurisdictional aquatic resources under USACE authority' },
  { value: 'I intend to construct/develop a project or perform activities on this parcel which may require authorization from the USACE, and the JD would be used to avoid and minimize impacts to jurisdictional aquatic resources and as an initial step in a future permitting process.', label: 'I intend to construct/develop a project or perform activities on this parcel which may require authorization from the USACE; and the JD would be used to avoid and minimize impacts to jurisdictional aquatic resources and as an initial step in a future permitting process' },
  { value: 'I intend to construct/develop a project or perform activities in a navigable water of the U.S. which is included on the district Section 10 list and/or is subject to the ebb and flow of the tide.', label: 'I intend to construct/develop a project or perform activities in a navigable water of the U.S. which is included on the district Section 10 list and/ or is subject to the ebb and flow of the tide' },
  { value: 'A USACE JD is required in order to obtain my local/state authorization.', label: 'A USACE Jurisdictional Determination is required in order to obtain my local/state authorization.' },
  { value: 'I intend to contest jurisdiction over a particular aquatic resource and request the USACE confirm that jurisdiction does/does not exist over the aquatic resource on the parcel.', label: 'I intend to contest jurisdiction over a particular aquatic resource and request the USACE confirm that jurisdiction does/does not exist over the aquatic resource on the parcel' },
  { value: 'I believe that the site may be comprised entirely of dry land.', label: 'I believe that the site may be comprised entirely of dry land' },
  { value: 'Other', label: 'Other (e.g. verify delineation report)' }
];

export const impactActivities = [
  { value: 'Conversion of waters type (forested wetland to emergent wetland, stream to lake)', label: 'Conversion of waters type (forested wetland to emergent wetland, stream to lake)' },
  { value: 'Discharge of dredged material', label: 'Discharge of dredged material' },
  { value: 'Discharge of fill material', label: 'Discharge of fill material' },
  { value: 'Dredging (Section 10)', label: 'Dredging (Section 10)' },
  { value: 'Ecological restoration', label: 'Ecological restoration' },
  { value: 'Other (Aquaculture, Work, Aerial or Submarine cable crossings)', label: 'Other (Aquaculture, Work, Aerial or Submarine cable crossings)' },
  { value: 'Removal (Sec 10 structures)', label: 'Removal (Sec 10 structures)' },
  { value: 'Structure (Sec 10 only)', label: 'Structure (Sec 10 only)' },
  { value: 'Transport of dredged material (Sec 103)', label: 'Transport of dredged material (Sec 103)' },
];

export const impactResourceTypes = [
  { value: 'Harbor/Ocean', label: 'Harbor/Ocean' },
  { value: 'Lake', label: 'Lake' },
  { value: 'Non-Tidal Wetland', label: 'Non-Tidal Wetland' },
  { value: 'Pond', label: 'Pond' },
  { value: 'River/Stream', label: 'River/Stream' },
  { value: 'Tidal Wetland', label: 'Tidal Wetland' },
  { value: 'Other', label: 'Other' },
];

export const mitigationTypes = [
  { value: 'Mitigation Bank', label: 'Mitigation Bank' },
  { value: 'In-lieu Fee', label: 'In-lieu Fee' },
  { value: 'Permittee Responsible On-site', label: 'Permittee Responsible On-site' },
  { value: 'Permittee Responsible Off-site', label: 'Permittee Responsible Off-site' },
  { value: 'Advanced Permittee Responsible Mitigation', label: 'Advanced Permittee Responsible Mitigation' }
];

export const PRMMitigationTypes = [
  { value: 'Permittee Responsible On-site', label: 'On-site' },
  { value: 'Permittee Responsible Off-site', label: 'Off-site' },
  { value: 'Advanced Permittee Responsible Mitigation', label: 'Advanced' }
];

export const ILFMitigationTypes = [
  { value: 'Mitigation Bank', label: 'Mitigation Bank' },
  { value: 'In-lieu Fee', label: 'In-lieu Fee' },
];

export const mitigationSubTypes = [
  { value: 'Establishment', label: 'Establishment' },
  { value: 'Re-establishment', label: 'Re-establishment' },
  { value: 'Enhancement', label: 'Enhancement' },
  { value: 'Rehabilitation', label: 'Rehabilitation' },
  { value: 'Preservation', label: 'Preservation' }
];

export const mitigationBankTypes = [
  { value: 'Acre Based', label: 'Acre Based' },
  { value: 'Linear Feet Based', label: 'Linear Feet Based' },
  { value: 'Assessment Based', label: 'Assessment Based' },
];

export const InlieuFeeTypes = [
  { value: 'Acre Based', label: 'Acre Based' },
  { value: 'Linear Feet Based', label: 'Linear Feet Based' },
  { value: 'Assessment Based', label: 'Assessment Based' },
  { value: 'Advanced Credit', label: 'Advanced Credit' },
];

export const countries = [
  { value: 'US', text: 'United States of America' },
  { value: 'AF', text: 'Afghanistan' },
  { value: 'AX', text: 'Aland Islands' },
  { value: 'AL', text: 'Albania' },
  { value: 'DZ', text: 'Algeria' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'AD', text: 'Andorra' },
  { value: 'AO', text: 'Angola' },
  { value: 'AI', text: 'Anguilla' },
  { value: 'AQ', text: 'Antarctica' },
  { value: 'AG', text: 'Antigua and Barbuda' },
  { value: 'AR', text: 'Argentina' },
  { value: 'AM', text: 'Armenia' },
  { value: 'AW', text: 'Aruba' },
  { value: 'AU', text: 'Australia' },
  { value: 'AT', text: 'Austria' },
  { value: 'AZ', text: 'Azerbaijan' },
  { value: 'BS', text: 'Bahamas' },
  { value: 'BH', text: 'Bahrain' },
  { value: 'BD', text: 'Bangladesh' },
  { value: 'BB', text: 'Barbados' },
  { value: 'BY', text: 'Belarus' },
  { value: 'BE', text: 'Belgium' },
  { value: 'BZ', text: 'Belize' },
  { value: 'BJ', text: 'Benin' },
  { value: 'BM', text: 'Bermuda' },
  { value: 'BT', text: 'Bhutan' },
  { value: 'BO', text: 'Bolivia (Plurinational State of)' },
  { value: 'BQ', text: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', text: 'Bosnia and Herzegovina' },
  { value: 'BW', text: 'Botswana' },
  { value: 'BV', text: 'Bouvet Island' },
  { value: 'BR', text: 'Brazil' },
  { value: 'IO', text: 'British Indian Ocean Territory' },
  { value: 'BN', text: 'Brunei Darussalam' },
  { value: 'BG', text: 'Bulgaria' },
  { value: 'BF', text: 'Burkina Faso' },
  { value: 'BI', text: 'Burundi' },
  { value: 'CV', text: 'Cabo Verde' },
  { value: 'KH', text: 'Cambodia' },
  { value: 'CM', text: 'Cameroon' },
  { value: 'CA', text: 'Canada' },
  { value: 'KY', text: 'Cayman Islands' },
  { value: 'CF', text: 'Central African Republic' },
  { value: 'TD', text: 'Chad' },
  { value: 'CL', text: 'Chile' },
  { value: 'CN', text: 'China' },
  { value: 'CX', text: 'Christmas Island' },
  { value: 'CC', text: 'Cocos (Keeling) Islands' },
  { value: 'CO', text: 'Colombia' },
  { value: 'KM', text: 'Comoros' },
  { value: 'CG', text: 'Congo' },
  { value: 'CD', text: 'Congo (Democratic Republic of the)' },
  { value: 'CK', text: 'Cook Islands' },
  { value: 'CR', text: 'Costa Rica' },
  { value: 'CI', text: 'Cote d\'Ivoire' },
  { value: 'HR', text: 'Croatia' },
  { value: 'CU', text: 'Cuba' },
  { value: 'CW', text: 'Curacao' },
  { value: 'CY', text: 'Cyprus' },
  { value: 'CZ', text: 'Czech Republic' },
  { value: 'DK', text: 'Denmark' },
  { value: 'DJ', text: 'Djibouti' },
  { value: 'DM', text: 'Dominica' },
  { value: 'DO', text: 'Dominican Republic' },
  { value: 'EC', text: 'Ecuador' },
  { value: 'EG', text: 'Egypt' },
  { value: 'SV', text: 'El Salvador' },
  { value: 'GQ', text: 'Equatorial Guinea' },
  { value: 'ER', text: 'Eritrea' },
  { value: 'EE', text: 'Estonia' },
  { value: 'ET', text: 'Ethiopia' },
  { value: 'FK', text: 'Falkland Islands (Malvinas)' },
  { value: 'FO', text: 'Faroe Islands' },
  { value: 'FJ', text: 'Fiji' },
  { value: 'FI', text: 'Finland' },
  { value: 'FR', text: 'France' },
  { value: 'GF', text: 'French Guiana' },
  { value: 'PF', text: 'French Polynesia' },
  { value: 'TF', text: 'French Southern Territories' },
  { value: 'GA', text: 'Gabon' },
  { value: 'GM', text: 'Gambia' },
  { value: 'GE', text: 'Georgia' },
  { value: 'DE', text: 'Germany' },
  { value: 'GH', text: 'Ghana' },
  { value: 'GI', text: 'Gibraltar' },
  { value: 'GR', text: 'Greece' },
  { value: 'GL', text: 'Greenland' },
  { value: 'GD', text: 'Grenada' },
  { value: 'GP', text: 'Guadeloupe' },
  { value: 'GU', text: 'Guam' },
  { value: 'GT', text: 'Guatemala' },
  { value: 'GG', text: 'Guernsey' },
  { value: 'GN', text: 'Guinea' },
  { value: 'GW', text: 'Guinea-Bissau' },
  { value: 'GY', text: 'Guyana' },
  { value: 'HT', text: 'Haiti' },
  { value: 'HM', text: 'Heard Island and McDonald Islands' },
  { value: 'VA', text: 'Holy See' },
  { value: 'HN', text: 'Honduras' },
  { value: 'HK', text: 'Hong Kong' },
  { value: 'HU', text: 'Hungary' },
  { value: 'IS', text: 'Iceland' },
  { value: 'IN', text: 'India' },
  { value: 'ID', text: 'Indonesia' },
  { value: 'IR', text: 'Iran (Islamic Republic of)' },
  { value: 'IQ', text: 'Iraq' },
  { value: 'IE', text: 'Ireland' },
  { value: 'IM', text: 'Isle of Man' },
  { value: 'IL', text: 'Israel' },
  { value: 'IT', text: 'Italy' },
  { value: 'JM', text: 'Jamaica' },
  { value: 'JP', text: 'Japan' },
  { value: 'JE', text: 'Jersey' },
  { value: 'JO', text: 'Jordan' },
  { value: 'KZ', text: 'Kazakhstan' },
  { value: 'KE', text: 'Kenya' },
  { value: 'KI', text: 'Kiribati' },
  { value: 'KP', text: 'Korea (Democratic People\'s Republic of)' },
  { value: 'KR', text: 'Korea (Republic of)' },
  { value: 'KW', text: 'Kuwait' },
  { value: 'KG', text: 'Kyrgyzstan' },
  { value: 'LA', text: 'Lao People\'s Democratic Republic' },
  { value: 'LV', text: 'Latvia' },
  { value: 'LB', text: 'Lebanon' },
  { value: 'LS', text: 'Lesotho' },
  { value: 'LR', text: 'Liberia' },
  { value: 'LY', text: 'Libya' },
  { value: 'LI', text: 'Liechtenstein' },
  { value: 'LT', text: 'Lithuania' },
  { value: 'LU', text: 'Luxembourg' },
  { value: 'MO', text: 'Macao' },
  { value: 'MK', text: 'Macedonia (the former Yugoslav Republic of)' },
  { value: 'MG', text: 'Madagascar' },
  { value: 'MW', text: 'Malawi' },
  { value: 'MY', text: 'Malaysia' },
  { value: 'MV', text: 'Maldives' },
  { value: 'ML', text: 'Mali' },
  { value: 'MT', text: 'Malta' },
  { value: 'MH', text: 'Marshall Islands' },
  { value: 'MQ', text: 'Martinique' },
  { value: 'MR', text: 'Mauritania' },
  { value: 'MU', text: 'Mauritius' },
  { value: 'YT', text: 'Mayotte' },
  { value: 'MX', text: 'Mexico' },
  { value: 'FM', text: 'Micronesia (Federated States of)' },
  { value: 'MD', text: 'Moldova (Republic of)' },
  { value: 'MC', text: 'Monaco' },
  { value: 'MN', text: 'Mongolia' },
  { value: 'ME', text: 'Montenegro' },
  { value: 'MS', text: 'Montserrat' },
  { value: 'MA', text: 'Morocco' },
  { value: 'MZ', text: 'Mozambique' },
  { value: 'MM', text: 'Myanmar' },
  { value: 'NA', text: 'Namibia' },
  { value: 'NR', text: 'Nauru' },
  { value: 'NP', text: 'Nepal' },
  { value: 'NL', text: 'Netherlands' },
  { value: 'NC', text: 'New Caledonia' },
  { value: 'NZ', text: 'New Zealand' },
  { value: 'NI', text: 'Nicaragua' },
  { value: 'NE', text: 'Niger' },
  { value: 'NG', text: 'Nigeria' },
  { value: 'NU', text: 'Niue' },
  { value: 'NF', text: 'Norfolk Island' },
  { value: 'MP', text: 'Northern Mariana Islands' },
  { value: 'NO', text: 'Norway' },
  { value: 'OM', text: 'Oman' },
  { value: 'PK', text: 'Pakistan' },
  { value: 'PW', text: 'Palau' },
  { value: 'PS', text: 'Palestine, State of' },
  { value: 'PA', text: 'Panama' },
  { value: 'PG', text: 'Papua New Guinea' },
  { value: 'PY', text: 'Paraguay' },
  { value: 'PE', text: 'Peru' },
  { value: 'PH', text: 'Philippines' },
  { value: 'PN', text: 'Pitcairn' },
  { value: 'PL', text: 'Poland' },
  { value: 'PT', text: 'Portugal' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'QA', text: 'Qatar' },
  { value: 'RE', text: 'Reunion' },
  { value: 'RO', text: 'Romania' },
  { value: 'RU', text: 'Russian Federation' },
  { value: 'RW', text: 'Rwanda' },
  { value: 'BL', text: 'Saint Barthélemy' },
  { value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', text: 'Saint Kitts and Nevis' },
  { value: 'LC', text: 'Saint Lucia' },
  { value: 'MF', text: 'Saint Martin (French part)' },
  { value: 'PM', text: 'Saint Pierre and Miquelon' },
  { value: 'VC', text: 'Saint Vincent and the Grenadines' },
  { value: 'WS', text: 'Samoa' },
  { value: 'SM', text: 'San Marino' },
  { value: 'ST', text: 'Sao Tome and Principe' },
  { value: 'SA', text: 'Saudi Arabia' },
  { value: 'SN', text: 'Senegal' },
  { value: 'RS', text: 'Serbia' },
  { value: 'SC', text: 'Seychelles' },
  { value: 'SL', text: 'Sierra Leone' },
  { value: 'SG', text: 'Singapore' },
  { value: 'SX', text: 'Sint Maarten (Dutch part)' },
  { value: 'SK', text: 'Slovakia' },
  { value: 'SI', text: 'Slovenia' },
  { value: 'SB', text: 'Solomon Islands' },
  { value: 'SO', text: 'Somalia' },
  { value: 'ZA', text: 'South Africa' },
  { value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', text: 'South Sudan' },
  { value: 'ES', text: 'Spain' },
  { value: 'LK', text: 'Sri Lanka' },
  { value: 'SD', text: 'Sudan' },
  { value: 'SR', text: 'Suriname' },
  { value: 'SJ', text: 'Svalbard and Jan Mayen' },
  { value: 'SZ', text: 'Swaziland' },
  { value: 'SE', text: 'Sweden' },
  { value: 'CH', text: 'Switzerland' },
  { value: 'SY', text: 'Syrian Arab Republic' },
  { value: 'TW', text: 'Taiwan, Province of China' },
  { value: 'TJ', text: 'Tajikistan' },
  { value: 'TZ', text: 'Tanzania, United Republic of' },
  { value: 'TH', text: 'Thailand' },
  { value: 'TL', text: 'Timor-Leste' },
  { value: 'TG', text: 'Togo' },
  { value: 'TK', text: 'Tokelau' },
  { value: 'TO', text: 'Tonga' },
  { value: 'TT', text: 'Trinidad and Tobago' },
  { value: 'TN', text: 'Tunisia' },
  { value: 'TR', text: 'Turkey' },
  { value: 'TM', text: 'Turkmenistan' },
  { value: 'TC', text: 'Turks and Caicos Islands' },
  { value: 'TV', text: 'Tuvalu' },
  { value: 'UG', text: 'Uganda' },
  { value: 'UA', text: 'Ukraine' },
  { value: 'AE', text: 'United Arab Emirates' },
  { value: 'GB', text: 'United Kingdom of Great Britain and Northern Ireland' },
  { value: 'UY', text: 'Uruguay' },
  { value: 'UZ', text: 'Uzbekistan' },
  { value: 'VU', text: 'Vanuatu' },
  { value: 'VE', text: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VN', text: 'Viet Nam' },
  { value: 'WF', text: 'Wallis and Futuna' },
  { value: 'EH', text: 'Western Sahara' },
  { value: 'YE', text: 'Yemen' },
  { value: 'ZM', text: 'Zambia' },
  { value: 'ZW', text: 'Zimbabwe' }
];

// create key value array for quickly getting full country name from alpha2 code (e.g. countryKeys['US'] => 'United States of America')
export const countryKeys = countries.reduce((r, {text, value}) => {
  r[value] = text;
  return r;
}, {});

export const usAndTerritories = [
  { value: 'US', text: 'United States of America' },
  { value: 'AS', text: 'American Samoa' },
  { value: 'GU', text: 'Guam' },
  { value: 'MH', text: 'Marshall Islands' },
  { value: 'FM', text: 'Micronesia (Federated States of)' },
  { value: 'MP', text: 'Northern Mariana Islands' },
  { value: 'PW', text: 'Palau' },
  { value: 'PR', text: 'Puerto Rico' },
  { value: 'VI', text: 'U.S. Virgin Islands' },
];

export const countriesAlpha3Alpha2Lookup = {
  AFG: 'AF',
  ALB: 'AL',
  DZA: 'DZ',
  ASM: 'AS',
  AND: 'AD',
  AGO: 'AO',
  AIA: 'AI',
  ATA: 'AQ',
  ATG: 'AG',
  ARG: 'AR',
  ARM: 'AM',
  ABW: 'AW',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BHS: 'BS',
  BHR: 'BH',
  BGD: 'BD',
  BRB: 'BB',
  BLR: 'BY',
  BEL: 'BE',
  BLZ: 'BZ',
  BEN: 'BJ',
  BMU: 'BM',
  BTN: 'BT',
  BOL: 'BO',
  BIH: 'BA',
  BWA: 'BW',
  BVT: 'BV',
  BRA: 'BR',
  IOT: 'IO',
  BRN: 'BN',
  BGR: 'BG',
  BFA: 'BF',
  BDI: 'BI',
  CPV: 'CV',
  KHM: 'KH',
  CMR: 'CM',
  CAN: 'CA',
  CYM: 'KY',
  CAF: 'CF',
  TCD: 'TD',
  CHL: 'CL',
  CHN: 'CN',
  CXR: 'CX',
  CCK: 'CC',
  COL: 'CO',
  COM: 'KM',
  COG: 'CG',
  COD: 'CD',
  COK: 'CK',
  CRI: 'CR',
  CIV: 'CI',
  HRV: 'HR',
  CUB: 'CU',
  CYP: 'CY',
  CZE: 'CZ',
  DNK: 'DK',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  ECU: 'EC',
  EGY: 'EG',
  SLV: 'SV',
  GNQ: 'GQ',
  ERI: 'ER',
  EST: 'EE',
  SWZ: 'SZ',
  ETH: 'ET',
  FLK: 'FK',
  FRO: 'FO',
  FJI: 'FJ',
  FIN: 'FI',
  FRA: 'FR',
  GUF: 'GF',
  PYF: 'PF',
  ATF: 'TF',
  GAB: 'GA',
  GMB: 'GM',
  GEO: 'GE',
  DEU: 'DE',
  GHA: 'GH',
  GIB: 'GI',
  GRC: 'GR',
  GRL: 'GL',
  GRD: 'GD',
  GLP: 'GP',
  GUM: 'GU',
  GTM: 'GT',
  GIN: 'GN',
  GNB: 'GW',
  GUY: 'GY',
  HTI: 'HT',
  HMD: 'HM',
  VAT: 'VA',
  HND: 'HN',
  HKG: 'HK',
  HUN: 'HU',
  ISL: 'IS',
  IND: 'IN',
  IDN: 'ID',
  IRN: 'IR',
  IRQ: 'IQ',
  IRL: 'IE',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JPN: 'JP',
  JOR: 'JO',
  KAZ: 'KZ',
  KEN: 'KE',
  KIR: 'KI',
  PRK: 'KP',
  KOR: 'KR',
  KWT: 'KW',
  KGZ: 'KG',
  LAO: 'LA',
  LVA: 'LV',
  LBN: 'LB',
  LSO: 'LS',
  LBR: 'LR',
  LBY: 'LY',
  LIE: 'LI',
  LTU: 'LT',
  LUX: 'LU',
  MAC: 'MO',
  MDG: 'MG',
  MWI: 'MW',
  MYS: 'MY',
  MDV: 'MV',
  MLI: 'ML',
  MLT: 'MT',
  MHL: 'MH',
  MTQ: 'MQ',
  MRT: 'MR',
  MUS: 'MU',
  MYT: 'YT',
  MEX: 'MX',
  FSM: 'FM',
  MDA: 'MD',
  MCO: 'MC',
  MNG: 'MN',
  MNE: 'ME',
  MSR: 'MS',
  MAR: 'MA',
  MOZ: 'MZ',
  MMR: 'MM',
  NAM: 'NA',
  NRU: 'NR',
  NPL: 'NP',
  NLD: 'NL',
  NCL: 'NC',
  NZL: 'NZ',
  NIC: 'NI',
  NER: 'NE',
  NGA: 'NG',
  NIU: 'NU',
  NFK: 'NF',
  MKD: 'MK',
  MNP: 'MP',
  NOR: 'NO',
  OMN: 'OM',
  PAK: 'PK',
  PLW: 'PW',
  PSE: 'PS',
  PAN: 'PA',
  PNG: 'PG',
  PRY: 'PY',
  PER: 'PE',
  PHL: 'PH',
  PCN: 'PN',
  POL: 'PL',
  PRT: 'PT',
  PRI: 'PR',
  QAT: 'QA',
  REU: 'RE',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  SHN: 'SH',
  KNA: 'KN',
  LCA: 'LC',
  VCT: 'VC',
  WSM: 'WS',
  SMR: 'SM',
  STP: 'ST',
  SAU: 'SA',
  SEN: 'SN',
  SRB: 'RS',
  SYC: 'SC',
  SLE: 'SL',
  SGP: 'SG',
  SXM: 'SX',
  SVK: 'SK',
  SVN: 'SI',
  SLB: 'SB',
  SOM: 'SO',
  ZAF: 'ZA',
  SGS: 'GS',
  SSD: 'SS',
  ESP: 'ES',
  LKA: 'LK',
  SDN: 'SD',
  SUR: 'SR',
  SJM: 'SJ',
  SWE: 'SE',
  CHE: 'CH',
  SYR: 'SY',
  TWN: 'TW',
  TJK: 'TJ',
  TZA: 'TZ',
  THA: 'TH',
  TLS: 'TL',
  TGO: 'TG',
  TKL: 'TK',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TKM: 'TM',
  TCA: 'TC',
  TUV: 'TV',
  UGA: 'UG',
  UKR: 'UA',
  ARE: 'AE',
  GBR: 'GB',
  USA: 'US',
  URY: 'UY',
  UZB: 'UZ',
  VUT: 'VU',
  VEN: 'VE',
  VNM: 'VN',
  VGB: 'VG',
  VIR: 'VI',
  WLF: 'WF',
  ESH: 'EH',
  YEM: 'YE',
  ZMB: 'ZM',
  ZWE: 'ZW'
};

export const JDTypeHelperContent = (
  <>
    <p><span className='text-underline'>Approved Jurisdictional Determination (AJD)</span>{' '}
    - An AJD is defined in USACE regulations at 33 CFR 331.2. As explained in
    further detail in RGL 16-01, an AJD is used to indicate that the USACE has
    identified the presence or absence of wetlands and/or other aquatic
    resources on a site, including their accurate location(s) and boundaries, as
    well as their jurisdictional status. AJDs are valid for 5 years.</p>
    <p><span className='text-underline'>Preliminary Jurisdictional Determination (PJD)</span>{' '}
    - A PJD is defined in USACE regulations at 33 CFR 331.2. As explained in
    further detail in RGL 16-01, a PJD is used to indicate that the USACE has
    identified the approximate location(s) and boundaries of wetlands and/or
    other aquatic resources on a site that are presumed to be subject to
    regulatory jurisdiction of the U.S. Army Corps of Engineers. Unlike an AJD, a PJD does
    not represent a definitive, official determination that there are, or that
    there are not, jurisdictional aquatic resources on a site, and does not have
    an expiration date.</p>
  </>
);

//to make multi select look like other elements
export const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    border: '1px solid #e74c3c',
    // padding: '0.375rem 1.5rem 0.375rem 0.75rem',
    paddingRight: '0.80rem',
    backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e74c3c%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e74c3c%27 stroke=%27none%27/%3e%3c/svg%3e")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 0.375rem center',
    backgroundSize: '1rem 1rem',
    borderColor: state.isFocused ? '#ccc' : '#e74c3c',
    boxShadow: state.isFocused ? '0 0 0 2px #e74c3c' : 'none',
    '&:hover': {
      borderColor: state.isFocused ? '#ccc' : '#e74c3c',
    },
  }),
};

export const customSelectStylesNonRequired = {
  control: (base) => ({
    ...base,
    // padding: '0.375rem 1.5rem 0.375rem 0.75rem',
  }),
};

export const ormErrorMsg = (<><p>An Invalid ORM file number was detected. ORM file numbers should conform to the following:</p>
  <ol>
    <li>
      First 3 characters should be one of the following: MVM, MVN, MVS, MVK, MVR, MVP, NAB, NAN, NAO, NAP, NAE, NWP, NWW, NWK, NWO, NWS, LRH, LRL, LRN, LRP, LRB, LRC, LRE, POH, POA, SAJ, SAC, SAM, SAS, SAW, SPL, SPK, SPN, SPA, SWF, SWG, SWL, SWT
    </li>
    <br />
    <li>
      4th character is a dash "-"
    </li>
    <br />
    <li>
      Characters 5-8 should be a 4 digit number between 1900-the current year
    </li>
    <br />
    <li>
      9th character is a dash "-"
    </li>
    <br />
    <li>
      Remaining characters could be 5 or 6 digits and may only be numbers.
    </li>
    <li>
      An optional 5 character suffix is also supported.
    </li>
  </ol>
  <p>Example: MVM-2023-12345 or MVS-2020-123456-MT123 </p>
</>);

export const ormErrorMsgAlert = 'An Invalid ORM file number was detected. ORM file numbers should conform to the following: \n\n First 3 characters should be one of the following: \n\n MVM, MVN, MVS, MVK, MVR, MVP, NAB, NAN, NAO, NAP, NAE, NWP, NWW, NWK, NWO, NWS, LRH, LRL, LRN, LRP, LRB, LRC, LRE, POH, POA, SAJ, SAC, SAM, SAS, SAW, SPL, SPK, SPN, SPA, SWF, SWG, SWL, SWT \n\n 4th character is a dash "-" \n\n Characters 5-8 should be a 4 digit number between 1900-the current year \n\n 9th character is a dash "-" \n\n Remaining characters could be 5 or 6 digits and may only be numbers.\n\n Optional 5 character suffix is also supported. Example: MVM-2023-12345 or MVS-2020-123456-MT123';

export const compensatoryMitigationTooltip = <>Compensatory mitigation is required for wetland losses greater than 1/10 Ac or stream losses greater than 3/100 Ac at a minimum of a 1:1 ratio unless the USACE district determines that 1) another form of mitigation is appropriate, 2) the impacts are minimal, or 3) an activity specific waiver is warranted.  For losses that exceed these thresholds, describe the proposed compensatory mitigation proposed or why compensatory mitigation should not be required. Since streams are difficult-to-replace resources, the compensatory mitigation requirement may be satisfied through the rehabilitation, enhancement, or preservation of streams, or the restoration or enhancement of riparian areas next to streams. </>;

export const proposedMitigationTooltip = 'Provide a brief explanation describing how impacts to waters of the United States are being avoided and minimized on the project site. Also provide a brief description of how impacts to waters of the United States will be compensated for, or a brief statement explaining why compensatory mitigation should not be required for those impacts';

export const impactNameTooltip = <>
  <p className='text-bold margin-bottom-0'>Examples:</p>
  <p className='margin-bottom-0'>Impact 1 – Culvert,</p>
  <p className='margin-bottom-0'>Impact 2 – Construction Equipment Access,</p>
  <p className='margin-bottom-0'>Impact 3 –  Sewer Line</p>
</>;

export const materialsTypeTooltip = <>Describe the material to be discharged within USACE jurisdiction. Please be sure this description will agree with your illustrations. Discharge material includes: rock, sand, clay, concrete, etc.</>;

export const mitigationTypeTooltip = <>
  <p><span className='text-bold'>Mitigation Bank</span> means a site, or suite of sites, where resources (e.g., wetlands, streams, riparian areas) are restored, established, enhanced, and/or preserved for the purpose of providing compensatory mitigation for impacts authorized by Department of the Army permits. In general, a mitigation bank sells compensatory mitigation credits to permittees whose obligation to provide compensatory mitigation is then transferred to the mitigation bank sponsor. The operation and use of a mitigation bank are governed by a mitigation banking instrument.</p>
  <p><span className='text-bold'>In-lieu fee (ILF) Program</span> means a program involving the restoration, establishment, enhancement, and/or preservation of aquatic resources through funds paid to a governmental or non-profit natural resources management entity to satisfy compensatory mitigation requirements for Department of the Army permits. Similar to a mitigation bank, an in-lieu fee program sells compensatory mitigation credits to permittees whose obligation to provide compensatory mitigation is then transferred to the in-lieu program sponsor. However, the rules governing the operation and use of in-lieu fee programs are somewhat different from the rules governing operation and use of mitigation banks. The operation and use of an in-lieu fee program are governed by an in-lieu fee program instrument. </p>
  <p><span className='text-bold'>Permitee-responsible mitigation (PRM)</span> means an aquatic resource restoration, establishment, enhancement, and/or preservation activity undertaken by the permittee (or an authorized agent or contractor) to provide compensatory mitigation for which the permittee retains full responsibility. <span className='text-bold'>Applicant must follow 33 CFR 332.4 when developing conceptual and final mitigation proposals.</span> </p>
  <p><span className='text-bold'>Advanced Permittee Responsible Mitigation</span> - APRM is a form of permittee-responsible mitigation (PRM) constructed in advance of permitted impacts to aquatic resources. All APRM sites must comply with the standards and requirements of the 2008 Mitigation Rule.</p>
</>;

export const permitteeResponsibleMitigationTypeTooltip = <>
  <p><span className='text-bold'>Establishment</span> (creation) means the manipulation of the physical, chemical, or biological characteristics present to develop an aquatic resource that did not previously exist at an upland site. Establishment results in a gain in aquatic resource area and functions. </p>
  <p><span className='text-bold'>Re-establishment</span> means the manipulation of the physical, chemical, or biological characteristics of a site with the goal of returning natural/historic functions to a former aquatic resource. Re-establishment results in rebuilding a former aquatic resource and results in a gain in aquatic resource area and functions. </p>
  <p><span className='text-bold'>Enhancement</span> means the manipulation of the physical, chemical, or biological characteristics of an aquatic resource to heighten, intensify, or improve a specific aquatic resource function(s). Enhancement results in the gain of selected aquatic resource function(s) but may also lead to a decline in other aquatic resource function(s). Enhancement does not result in a gain in aquatic resource area. </p>
  <p><span className='text-bold'>Rehabilitation</span> means the manipulation of the physical, chemical, or biological characteristics of a site with the goal of repairing natural/historic functions to a degraded aquatic resource. Rehabilitation results in a gain in aquatic resource function but does not result in a gain in aquatic resource area. </p>
  <p><span className='text-bold'>Preservation</span> means the removal of a threat to, or preventing the decline of, aquatic resources by an action in or near those aquatic resources. This term includes activities commonly associated with the protection and maintenance of aquatic resources through the implementation of appropriate legal and physical mechanisms. Preservation does not result in a gain of aquatic resource area or functions.</p>
</>;

export const devTypeTooltipContent = 'Fees are required for permits under Section 404 of the Clean Water Act, Section 103 of the Marine Protection, Research and Sanctuaries Act of 1972, as amended, and Sections 9 and 10 of the Rivers and Harbors Act of 1899. A fee of $100.00 will be charged when the planned or ultimate purpose of the project is commercial or industrial in nature and is in support of operations that charge for the production, distribution or sale of goods or services. A $10.00 fee will be charged for permit applications when the proposed work is non-commercial in nature and would provide personal benefits that have no connection with a commercial enterprise. The final decision as to the basis for a fee (commercial vs. non-commercial) shall be solely the responsibility of the U.S. Army Corps of Engineers. No fee will be charged if the applicant withdraws the application at any time prior to issuance of the permit or if the permit is denied. Collection of the fee will be deferred until the proposed activity has been determined to not be contrary to the public interest. Multiple fees are not to be charged if more than one law is applicable. Any modification significant enough to require publication of a public notice will also require a fee. No fee will be assessed when a permit is transferred from one property owner to another. No fees will be charged for time extensions, general permits, or letters of permission. Agencies or instrumentalities of federal, state, or local governments will not be required to pay any fee in connection with permits. 33 CFR 325.1 Payment of permit fees is accomplished through pay.gov and must be paid before a permit can be issued. Districts will contact you on how to do this - https://www.pay.gov/public/form/start/996412796';

export const otherCertsContent = 'You may need the approval of other federal, state, or local agencies for your project. Identify any applications you have submitted and the status, if any (approved or denied), of each application. You need not have obtained all other permits before applying for a USACE permit.';

const svgList = ['Full Screen', 'Add a Line Feature', 'Add a Polygon Feature' ];
const mapUseTipsList = [
  { icon: mdiPlus, label: 'Zoom In', text: 'Click the plus sign to zoom in on the map. You can also click one in the map and use the scroll wheel on your mouse to zoom in and out. With the focus of your cursor on the map, you can pan the map with the hand icon' },
  { icon: mdiMinus, label: 'Zoom Out', text: 'Click the minus sign to zoom out on the map. See above for further map navigation tips' },
  { icon: fullscreenSVG, label: 'Full Screen', text: 'Click the full screen icon to zoom out to the original extent of the map' },
  { icon: mdiFolderUploadOutline, label: 'Upload GDB File', text: 'Click this icon to upload the project location information from a geodatabase (GDB) instead of digitizing the location on the map. The geodatabase must be compressed (.zip or .gdb)' },
  { icon: polyLineSVG, label: 'Add a Line Feature', text: 'Select the line tool to add a project location as a line. Click the starting point location and any interim shape points. Double-click the final shape point of the line or click to set the location of the final shape point and then select "Finish" from the popup menu in the map' },
  { icon: polygonSVG, label: 'Add a Polygon Feature', text: 'Select the polygon tool to add a project location as a polygon. Click the starting point location followed by the remaining shape points. When complete, either double-click the last shape point or click the set the location of the final shape point and then select "Finish" from the popup menu in the map' },
  { icon: mdiMapMarker, label: 'Add a Point Feature', text: 'Select the point tool to add a project location as a single point. Click the location of the point' },
  { icon: mdiTrashCanOutline, label: 'Delete Features', text: 'Select this option to delete the location information from the map. You will then need to re-create the project location. After clicking the trash can icon, select Finish, Cancel, or Remove All to delete the location entered on the map' },
  { icon: mdiMagnify, label: 'Search', text: 'Click the magnifier icon in the upper right of the map to expand a search box which will allow you to search for a location via text (address, city, etc.)' },
  { icon: mdiLayersTriple, label: 'Layers', text: 'Mouse over the layers icon to view the available base maps. The base map can either be viewed as a street map, aerial imagery, or topographic map' },
];
export const mapUseTipsAccordionList = [
  {
    title: 'Map Use Tips',
    content: <>
      {mapUseTipsList.map((item, index) => (
        <p key={index} className={`margin-bottom-0 ${index !== 0 && 'margin-top-105'}`}>
          <span>{svgList.includes(item.label) ? item.icon : <Icon path={item.icon} size={'24px'} />}</span> {item.label} - {item.text}.
        </p>
      ))}
    </>,
    id: '1',
    headingLevel: 'h3'
  }
];
