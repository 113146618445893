import React from 'react';

const SplitLayout = ({ children, leftWidth = '6', rightWidth = '6' }) => {
  const [left, right] = children;

  return (
    <div className='row mr-0 ml-0'>
      <div className={`col-${leftWidth} col-md-${leftWidth} col-sm-12 p-2`}>
        {left}
      </div>
      <div className={`col-${rightWidth} col-md-${leftWidth} col-sm-12 p-2`}>
        {right}
      </div>
    </div >
  );
};

export default SplitLayout;