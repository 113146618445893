import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import ViolationsFormInstructions from '@forms/static-forms/ViolationsFormInstructions';
import ViolationsFormContacts from '@forms/input-forms/ViolationsForms/ViolationsFormContacts';
import SupportingInformation from '@forms/input-forms/common/SupportingInformation';
import ViolationsFormGeneralSiteInformation from '@forms/input-forms/ViolationsForms/ViolationsFormGeneralSiteInformation';
import ViolationsFormCertifySignSubmit from '@forms/input-forms/ViolationsForms/ViolationsFormCertifySignSubmit';

//List of steps for vertical stepper, these can also be used for form header. index = step ID 
export const ViolationsForm5Metadata = [
  ViolationsFormInstructions.metadata,
  ViolationsFormContacts.metadata,
  ViolationsFormGeneralSiteInformation.metadata,
  SupportingInformation.metadata,
  ViolationsFormCertifySignSubmit.metadata,
];

const ViolationsForm5 = connect(
  'doUpdateViolationComplaintRequest',
  'selectActiveStep',
  'selectViolationComplaintID',
  'selectViolationComplaintData',
  ({
    doUpdateViolationComplaintRequest,
    activeStep,
    violationComplaintID,
    violationComplaintData,
  }) => {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const [showSupportingInformation, setShowSupportingInformation] = useState(false);
    const [showGeneralSiteInformation, setShowGeneralSiteInformation] = useState(false);
    const [showCertifySignSubmit, setShowCertifySignSubmit] = useState(false);

    //display only active step, hide all other steps
    useEffect(() => {
      if (activeStep) {
        switch (activeStep.id) {
          case 0:
            setShowInstructions(true);
            setShowContacts(false);

            setShowGeneralSiteInformation(false);
            setShowSupportingInformation(false);
            setShowCertifySignSubmit(false);
            break;
          case 1:
            setShowInstructions(false);
            setShowContacts(true);
            setShowGeneralSiteInformation(false);
            setShowSupportingInformation(false);
            setShowCertifySignSubmit(false);
            break;
          case 2:
            setShowInstructions(false);
            setShowContacts(false);
            setShowGeneralSiteInformation(true);
            setShowSupportingInformation(false);
            setShowCertifySignSubmit(false);
            break;
          case 3:
            setShowInstructions(false);
            setShowContacts(false);
            setShowGeneralSiteInformation(false);
            setShowSupportingInformation(true);
            setShowCertifySignSubmit(false);
            break;
          case 4:
            setShowInstructions(false);
            setShowContacts(false);
            setShowGeneralSiteInformation(false);
            setShowSupportingInformation(false);
            setShowCertifySignSubmit(true);
            break;
          default:
            setShowInstructions(true);
            setShowContacts(false);
            setShowGeneralSiteInformation(false);
            setShowSupportingInformation(false);
            setShowCertifySignSubmit(false);
            break;
        }
      }
    }, [activeStep]);

    // if user clicks on stepper, hide all other steps but one clicked on
    // if user clicks back, hide all other values but current - 1
    // user only able to go forward when section is valid

    const instructionStyles = { display: showInstructions === true ? 'inline' : 'none' };
    const contactsStyles = { display: showContacts ? 'inline' : 'none' };
    const supportingInformationStyles = { display: showSupportingInformation ? 'inline' : 'none' };
    const generalSiteInformationStyles = { display: showGeneralSiteInformation ? 'inline' : 'none' };
    const certifySignSubmitStyles = { display: showCertifySignSubmit ? 'inline' : 'none' };

    return (
      <>
        <section id='ViolationsFormInstructions' name='Instructions' style={instructionStyles}>
          <ViolationsFormInstructions stepNo={0} />
        </section>
        <section id='ViolationsFormContacts' name='Contact Information' style={contactsStyles}>
          <ViolationsFormContacts stepNo={1} />
        </section>
        <section id='ViolationsFormGeneralSiteInformation' name='General Site Information' style={generalSiteInformationStyles}>
          <ViolationsFormGeneralSiteInformation stepNo={2} />
        </section>
        <section id='ViolationsFormSupportingInformation' name='Supporting Information' style={supportingInformationStyles}>
          <SupportingInformation
            componentID={violationComplaintID}
            subtext={<p>Include copies of any supporting documents you have (if available).</p>}
            requestData={violationComplaintData}
            updateRequestData={doUpdateViolationComplaintRequest}
            stepNo={3}
          />
        </section>
        <section id='ViolationsFormCertifySignSubmit' name='Certify, Sign, Submit' style={certifySignSubmitStyles}>
          <ViolationsFormCertifySignSubmit stepNo={4} last={true} />
        </section>
      </>
    );
  });

export default ViolationsForm5;
