import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAP_PHILADELPHIA = () => (
  <>
    <section id='nap-philadelphia'>
      <DistrictInfoTable district='NAP'/>
      <p>
      The <ExternalLink content='Philadelphia District' title='Philadelphia District' href='https://www.nap.usace.army.mil/missions/regulatory.aspx' /> uses State Programmatic General Permits for certain activities in navigable waters in Delaware and southern New Jersey, and for most activities in most waters in eastern Pennsylvania.  At this time, the Regulatory Request System (RRS) should be used for the submission of individual permit applications and nationwide permit preconstruction notifications for activities in non-navigable waters in Delaware and for activities in the Chesapeake and Delaware Canal in Maryland.  All other applications should continue to be directed to the appropriate state agency as is the current practice.  
      </p>
    </section>
  </>
);

export default NAP_PHILADELPHIA;
