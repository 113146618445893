import React from 'react';

import NewSectionCard from '../components/_shared/NewSectionCard';

import '../resources.scss';

const PublicNotices = () => (
  <>
    <section id='public-notices'>
      <div style={{ paddingLeft: '1%' }}>
        <h6>Public Notices Specific to District</h6>
        <p>Be sure to select a district. Public notices will be displayed on the selected district's homepage</p>
      </div>
      <div className='row'>
        <div className='col-6'>
          <NewSectionCard
            title='Public Notices'
            content='View public notices from your district.'
            buttonText='Go To Public Notices Page'
            buttonTitle='Public Notices section button'
            url='https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/Regulatory-Contacts/'
            external
          />
        </div>
        <div className='col-6'>
          <NewSectionCard
            title='Special Public Notices'
            content='The following is a list of recent special public notices. This is an incomplete list of these type of notices. If you would like a copy of an older one, please contact us.'
            buttonText='Go To Special Public Notices Page'
            buttonTitle='Special Public Notices section button'
            url='/'
            isDisabled
          />
        </div>
      </div>
    </section>
  </>
);

export default PublicNotices;
