import Icon from '@components/icon/Icon';
import { Button } from '@trussworks/react-uswds';
import { mdiArrowDownCircleOutline, mdiArrowUpCircleOutline } from '@mdi/js';

import './SectionButton.scss';

const SectionButton = ({ location, section }) => {
  const text = location === 'bottom' ? 'Next' : 'Previous';
  const iconPath = location === 'bottom' ? mdiArrowDownCircleOutline : mdiArrowUpCircleOutline;
  const className = location === 'bottom' ? 'next' : 'previous';

  return (
    <Button base className={`section-button ${className}`} id='section'>
      <div className='section-button-text'>
        <Icon focusable={false} size={'20px'} path={iconPath} />
        <span className='section-label'>{text} Section:</span>
        <span className='section-text'>{section}</span>
      </div>
    </Button>
  );
};

export default SectionButton;
