import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const SAM_MOBILE = () => (
  <>
    <section id='sam-mobile'>
      <DistrictInfoTable district='SAM'/>
      <p>
      Please do not use RRS to apply for a permit in coastal zone of Mississippi. The Mobile District employs a <ExternalLink content='joint permit application' title='Joint Permit Application' href='https://dmr.ms.gov/permitting/' /> process with the State of Mississippi.  In the coastal zone of Mississippi, applicants should use the joint permit application form.  In addition, applicants in MS should also review the Mississippi General Permits MVK_SAM MSGP to see if their activity qualifies for a programmatic permit through Mississippi Department of Marine Resources (MDMR). The <ExternalLink content='Mobile District website' title='Mobile District Website' href='https://www.sam.usace.army.mil/Missions/Regulatory/' /> contains more information on how to obtain a permit and the application form.
      </p>
    </section>
  </>
);

export default SAM_MOBILE;
