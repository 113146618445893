import React from 'react';

import './ImageFigure.scss';

const ImageFigure = (props) => (
  <div className='justify-content-center'>
    <img style={props.style} src={props.src} alt={props.alt ? props.alt : ''} />
    <figcaption>{props.caption}</figcaption>
  </div>
);

export default ImageFigure;
