import { Accordion } from '@trussworks/react-uswds';
import LRB_BUFFALO from './DistrictContent/LRD/LRD.LRB_Buffalo';
import LRC_CHICAGO from './DistrictContent/LRD/LRD.LRC_Chicago';
import LRE_DETROIT from './DistrictContent/LRD/LRD.LRE_Detroit';
import LRL_LOUISVILLE from './DistrictContent/LRD/LRD.LRL_Louisville';
import LRN_NASHVILLE from './DistrictContent/LRD/LRD.LRN_Nashville';
import LRP_PITTSBURGH from './DistrictContent/LRD/LRD.LRP_Pittsburgh';

const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Buffalo District (LRB)',
    content: <LRB_BUFFALO />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'Chicago District (LRC)',
    content: <LRC_CHICAGO />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'Detroit District (LRE)',
    content: <LRE_DETROIT />,
  },
  {
    id: '4',
    headingLevel: 'h3',
    title: 'Louisville District (LRL)',
    content: <LRL_LOUISVILLE />,
  },
  {
    id: '5',
    headingLevel: 'h3',
    title: 'Nashville District (LRN)',
    content: <LRN_NASHVILLE />,
  },
  {
    id: '6',
    headingLevel: 'h3',
    title: 'Pittsburgh District (LRP)',
    content: <LRP_PITTSBURGH />,
  },
];


const LRD = () => (
  <>
    <section id='lrd'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default LRD;
