import { connect } from 'redux-bundler-react';
import { TableWrapper } from './FormAndAppendices';
import LinkButton from '@components/link/linkButton';
import { FileTypes, ResourceFiles } from '@src/utils/enums';
import Tooltip from '@components/tooltip/tooltip';

const scopeModalContent = (
  <>
    Scoping letters, which are typically submitted by applicants using Federal or State funding, provides comments
    regarding potential impacts to waters of the United States during very early phases of project development. These
    are typically requested to help meet requirements of the National Environmental Policy Act or similar state/local
    laws.
  </>
);

const FormsAndAppendicesDelineationRequest = connect('doDownloadFile', ({ doDownloadFile }) => (
  <>
    <p>
      This form provides information needed to request a pre-application meeting or{' '}
      <span className='text-bold'>scoping letters</span> <Tooltip content={scopeModalContent} /> from USACE. The form
      includes contact information for applicants, property owners, and agents, project location information, and a
      general description of the project that will serve as a basis for a Project Manager and interested agencies to
      provide meaningful feedback on the proposal.
    </p>
    <TableWrapper>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.Eng6286, FileTypes.Resource)}>
            Request pre-application meeting or project scoping [PDF, 2 pages]
          </LinkButton>
        </td>
        <td>
          This form provides important information regarding a proposed project that will serve as a basis for a Project
          Manager and interested agencies to provide meaningful feedback on the proposal.
        </td>
      </tr>
      <tr>
        <td>
          <LinkButton onClick={() => doDownloadFile(ResourceFiles.AppendixH, FileTypes.Resource)}>
            App H. Supporting Information [PDF, 1 page]
          </LinkButton>
        </td>
        <td>
          Information that is uploaded by the requestor to support and/or clarify the request that is being made. This
          information can include, but is not limited to, photos, maps, drawings, other authorizations, mitigation
          plans, etc.
        </td>
      </tr>
    </TableWrapper>
  </>
));

export default FormsAndAppendicesDelineationRequest;
