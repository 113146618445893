import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NWP_PORTLAND = () => (
  <>
    <section id='nwp-portland'>
      <DistrictInfoTable district='NWP' orgEmail='PortlandPermits@usace.army.mil'/>
      <p>
      The Portland District partners with State of Oregon agencies to employ a <ExternalLink content='Joint Permit Application (JPA)' title='Joint Permit Application (JPA)' href='https://www.nwp.usace.army.mil/Missions/Regulatory/Apply/' /> for permit application submittals. Please contact the Portland District at <InternalLink content='PortlandPermits@usace.army.mil' title='PortlandPermits@usace.army.mil' href='mailto:PortlandPermits@usace.army.mil' /> for application information regarding Columbia River Ports in southwest Washington and restoration projects in the Columbia River estuary funded by the Bonneville Power Administration (BPA), as these projects are evaluated by Portland District.
      </p>
    </section>
  </>
);

export default NWP_PORTLAND;
