import { ProfileRoles } from '@src/utils/enums';
import { districtsArr, divisions } from '../_shared/helper';

export const rolesHelperContent = (
  <>
    Role Descriptions:
    <ol>
      <li><p><span className='text-bold'>Applicant</span> – Entity with desire to undertake the proposed activity that possesses or will possess the requisite property interest.</p></li>
      <li><p><span className='text-bold'>Agent</span> - Entity acting on behalf of the applicant.</p></li>
      <li><p><span className='text-bold'>External Agency</span> – Future Capability</p></li>
      <li><p><span className='text-bold'>USACE Regulatory User</span> – Internal USACE Project Managers and RRS Administrators.</p></li>
    </ol>
  </>
);

export const cleanProfileData = (contact) => {
  let updatedContact = { ...contact };

  // Check phoneNumberOne and remove country code if empty
  if (!contact?.phoneOne || contact?.phoneOne?.trim() === '') {
    updatedContact.phoneOneCountryCode = null;
  }
  
  // Check phoneNumberTwo and remove country code if empty
  if (!contact?.phoneTwo || contact?.phoneTwo?.trim() === '') {
    updatedContact.phoneTwoCountryCode = null;
  }

  // Check phoneNumberTwo and remove country code if empty
  if (!contact?.faxPhone || contact?.faxPhone?.trim() === '') {
    updatedContact.faxCountryCode = null;
  }

  // Check user role and remove country when appropriate
  if (contact?.role === ProfileRoles.USACERegulator) {
    updatedContact.country = null;
  }
  
  return updatedContact;
};

export const getAgencyType = (agencyType) => {
  if (agencyType) {
    const agencyTypes = agencyType.map((val) => ({ value: val, label: val }));
    return agencyTypes;
  }
};

export const getDivisions = (areaOfInterest) => {
  if (areaOfInterest) {
    const divisionList = areaOfInterest.map((val) => {
      if (val <= 10) {
        const matchedDivision = divisions.filter(division => division.value === val)[0];
        return { value: val, label: matchedDivision ? matchedDivision.label : null };
      }
      return null;
    }).filter(Boolean);
    return divisionList;
  }
  return [];
};

export const getDistricts = (areaOfInterest) => {
  if (areaOfInterest) {
    const districtList = areaOfInterest.map((val) => {
      if (val > 10) {
        const matchedDistrict = districtsArr.filter(district => district.value === val)[0];
        return { value: val, label: matchedDistrict ? matchedDistrict.label : null };
      }
      return null;
    }).filter(Boolean);
    return districtList;
  }
  return [];
};