const IPACAPIBundle = {
  name: 'ipacapi',

  getReducer: () => {
    const initialData = {
      endspecieslist: [],
      isLoading: false,
      isError: false,
    };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATING_IPAC_RESOURCES':
          if (payload) {
            const populations = Object.values(payload.resources.populationsBySid);
            const filteredPopulations = populations.filter(pop =>
              ['Endangered', 'Threatened', 'Proposed Endangered', 'Proposed Threatened'].includes(
                pop.population.listingStatusName
              )
            );
            const filteredPopulationObjects = filteredPopulations.map(population => population.population);
            return {
              ...state,
              endspecieslist: filteredPopulationObjects,
              isLoading: false,
            };
          }
          return state;
        case 'LOADING_IPAC_RESOURCES':
          return {
            ...state,
            isLoading: true,
            isError: false,
          };
        case 'RESET_IPAC_API_RESULTS':
          return initialData;
        case 'ERROR_IPAC_API_REQUEST':
          return {
            ...state,
            isLoading: false,
            isError: true,
          };
        default:
          return state;
      }
    };
  },

  doGetIPACSpeciesList: (updatedGeometry, isFootprint) => ({ dispatch }) => {
    dispatch({ type: 'RESET_IPAC_API_RESULTS' });
    dispatch({ type: 'LOADING_IPAC_RESOURCES' });

    const uri = 'https://ipac.ecosphere.fws.gov/location/api/resources';

    let body = {
      'location.selectionMode': 'freedraw',
      'location.bufferDistance': 5,
      'includeOtherFwsResources': true,
      'includeCrithabGeometry': false
    };

    if (isFootprint)
      body['location.footprint'] = JSON.stringify(updatedGeometry);
    else
      body.projectLocationWKT = updatedGeometry;

    fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        if (!response.ok) {
          dispatch({ type: 'ERROR_IPAC_API_REQUEST' });
          throw new Error('API is not available at this time. Please try again later.');
        }
        return response.json();
      })
      .then(data => {
        dispatch({ type: 'UPDATING_IPAC_RESOURCES', payload: data });
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: 'ERROR_IPAC_API_REQUEST' });
      });
  },

  selectEndSpeciesList: (state) => state.ipacapi.endspecieslist,
  selectIsLoading: (state) => state.ipacapi.isLoading,
  selectIsError: (state) => state.ipacapi.isError,
};

export default IPACAPIBundle;
