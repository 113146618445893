import { useCallback, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button, Grid, Label } from '@trussworks/react-uswds';
import { toast } from 'react-toastify';

import Antispam from '@components/antispam/Antispam';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import FileInput from '@components/file-input/FileInput';
import PhoneInput from '@components/phone-input/PhoneInput';
import TextInput from '@components/text-input/TextInput';
import TextArea from '@components/textarea/TextArea';
import Tooltip from '@components/tooltip/tooltip';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { ErrorMessages } from '@src/utils/enums';
import { countryCodeRegex, phoneExtRegex, phoneRegex, usPhoneRegex } from '@src/utils/regex';
import { contactInputValidation } from '@src/utils/validation/inputValidation.contact';

import './publicNotices.scss';

const phoneOptionalDependencies = [
  //     1                   2                3
  ['phoneType', 'phoneCountryCode', 'phone'], // 1 2 3
  ['phoneType', 'phone', 'phoneCountryCode'], // 1 3 2
  ['phoneCountryCode', 'phoneType', 'phone'], // 2 1 3
  ['phoneCountryCode', 'phone', 'phoneType'], // 2 3 1
  ['phone', 'phoneType', 'phoneCountryCode'], // 3 1 2
  ['phone', 'phoneCountryCode', 'phoneType'], // 3 2 1
];

const tooltipContent = <>
  <p>To select multiple files, while pressing down the control key (Ctrl) click on files that you want to select. Continue to press down the control key until you select all the files you want.</p>
  <p><span className='text-bold'>{ErrorMessages.UploadNotice}</span></p>
</>;

const SecurityCheckTooltipContent = <p>This simple math equation is used to prove you are a human and not a robot.</p>;

const cleanPhoneInputData = (contact) => {
  let updatedContact = { ...contact };

  // Check phone and remove country code if empty
  if (!contact?.phone || contact?.phone?.trim() === '') {
    updatedContact.phoneCountryCode = '';
    updatedContact.phoneType = '';
  }

  return updatedContact;
};

const SubmitCommentsModal = connect(
  'doModalClose',
  'doSavePublicNoticesAttachments',
  'doSubmitPublicNoticeComments',
  'selectAntispamResults',
  ({
    doModalClose,
    doSavePublicNoticesAttachments,
    doSubmitPublicNoticeComments,
    antispamResults,
    data,
    onClose,
  }) => {
    const [stagedFiles, setStagedFiles] = useState([]);
    const [fileErrors, setFileErrors] = useState(false);
    const isSelectedFiles = stagedFiles?.length > 0;

    const defaultValues = {
      daNumber: data?.daNumber,
      firstName: '',
      lastName: '',
      emailAddress: '',
      phone: '',
      phoneCountryCode: '1',
      phoneType: '',
      phoneExtension: '',
      company: '',
      comments: '',
      antispam: '',
    };

    const schema = yup.object().shape({
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      ...contactInputValidation.emailOptional,
      phoneType: yup.string().when('phone', {
        is: (phone) => !!phone?.length,
        then: (schema) => schema.required(ErrorMessages.Required),
        otherwise: (schema) => schema.nullable(),
      }),
      phoneCountryCode: yup.string().when(['phoneType', 'phone'], {
        is: (type, phone) => !!type?.length || !!phone?.length,
        then: (schema) => schema.required(ErrorMessages.Required).matches(countryCodeRegex, ErrorMessages.Invalid),
        otherwise: (schema) => schema.nullable(),
      }),
      phone: yup.string().when(['phoneType', 'phoneCountryCode'], {
        is: (type, code) => !!type?.length || code === '1',
        then: (schema) => schema.matches(usPhoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}).nullable(),
        otherwise: (schema) => schema.when(['phoneType', 'phoneCountryCode'], {
          is: (type, code) => !!type?.length || !!code?.length,
          then: (schema) => schema.matches(phoneRegex, {message: ErrorMessages.Invalid, excludeEmptyString: true}).nullable(),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
      phoneExtension: yup.string().nullable().matches(phoneExtRegex, { message: ErrorMessages.Invalid, excludeEmptyString: true }),
      company: yup.string().nullable(),
      comments: yup.string().required(ErrorMessages.Required),
      attachments: yup.string().test('file-invalid-test',
        'File selected is invalid',
        function (value) { return value !== 'error'; }
      ),
      antispam: yup.string().required('Please solve the equation').test('antispam', 'Invalid response for the math equation', (value) => 
        value && antispamResults
      ),
    }, [...phoneOptionalDependencies]);

    const methods = useForm({ defaultValues: defaultValues, resolver: yupResolver(schema), mode: 'onBlur', stateOptions: [] });
    const { formState: { errors, isValid }, setValue, setFocus, watch, getValues, trigger } = methods;
    const phone = watch('phone');
    const attachments = watch('attachments');

    const handleAntispamBlur = () => {
      trigger('antispam');
    };

    const handleDeleteFile = () => {
      setStagedFiles([]);
      setValue('attachments', '', { shouldValidate: true });
    };

    const handleFileInput = e => {
      const filesArr = Array.from(e?.target?.files) ?? [];
      setStagedFiles(filesArr);
      trigger('attachments');
    };

    const handleSave = () => {
      if (isValid && fileErrors === false && antispamResults) {
        const formData = getValues();
        const parsedPhone = phone?.replace(/\D/g, '') ?? undefined;
        const cleanPhoneValues = cleanPhoneInputData(formData);

        const payload = {...cleanPhoneValues, publicNoticeID: data?.publicNoticeID, phone: parsedPhone};
        doSubmitPublicNoticeComments(payload);
        toast.success('Comment successfully submitted!');
        doModalClose();
      } 
      else {
        trigger();
      }
    };

    const setFileValue = useCallback(() => {
      if (attachments?.length === 0) {
        return null;
      }
      return fileErrors === true ? 'error' : 'true';
    }, [attachments?.length, fileErrors]);

    // Update public notices file list (if any)
    useEffect(() => {
      doSavePublicNoticesAttachments(stagedFiles);
    }, [stagedFiles, doSavePublicNoticesAttachments]);

    // Set attachments value
    useEffect(() => {
      setValue('attachments', setFileValue(), { shouldValidate: true });
    }, [fileErrors, setValue, setFileValue]);

    useEffect(() => {
      setFocus(errors?.[Object.keys(errors)[0]]?.['ref']?.['id']);
    }, [errors, setFocus]);

    return (
      <>
        <FormProvider {...methods}>
          {errors && <ErrorSummary errors={errors} modalID='submitCommentsModal' type='modal' />}
          <section className='modal-body' id='submitCommentsModal'>
            <div className='container-fluid'>
              <section className='pn-section flex-item'>
                <p className='margin-bottom-0'>DA Number: <span className='text-bold'>{data?.daNumber}</span></p>
                <p className='margin-bottom-0'>Project: <span className='text-bold'>{data?.projectName}</span></p>
              </section>
              <Grid row gap='md'>
                <Grid tablet={{col: 6}}>
                  <TextInput name='firstName' label='First Name' />
                </Grid>
                <Grid tablet={{col: 6}}>
                  <TextInput name='lastName' label='Last Name' />
                </Grid>
              </Grid>
              <Grid row gap='md'>
                <Grid col>
                  <TextInput name='emailAddress' label='Email' />
                </Grid>
              </Grid>
              <Grid row gap='md'>
                <Grid col>
                  <PhoneInput
                    phoneName='phone'
                    phoneCountryCodeName='phoneCountryCode'
                    phoneTypeName='phoneType'
                    extensionName='phoneExtension'
                    label='Phone Number'
                    showTypeSelect
                  />
                </Grid>
              </Grid>
              <Grid row gap='md'>
                <Grid col>
                  <TextInput name='company' label='Company Name' />
                </Grid>
              </Grid>
              <Grid row gap='md'>
                <Grid col>
                  <TextArea name='comments' label='Summary of Comments' tooltip='Comments over 4000 characters should be uploaded as a file.' required />
                </Grid>
              </Grid>
              <Label className='border-bottom w-100 padding-bottom-0 mt-3' htmlFor='attachments'>
                <span id='attachments_label'>Attachments</span>
                <Tooltip name='publicNoticesAttachments' header='Attachments' content={tooltipContent} iconStyle={{ marginLeft: '5px' }} />
              </Label>
              <div className='d-flex flex-column align-items-center margin-top-1 margin-bottom-1'>
                <FileInput
                  doDeleteFile={() => handleDeleteFile()}
                  id='attachments'
                  multiple={true}
                  name='attachments'
                  onChange={handleFileInput}
                  onDrop={handleFileInput}
                  setFileErrors={setFileErrors}
                  showDeleteButton={isSelectedFiles}
                  required
                  value={stagedFiles}
                />
              </div>
            </div >
            <div className='border-bottom w-100 padding-bottom-0 margin-top-2'><span id='security_check_label'>Security Check</span><Tooltip name='securitychecktooltip' header='Security Check' content={SecurityCheckTooltipContent} iconStyle={{ marginLeft: '5px' }} /></div>
            <Antispam onBlur={handleAntispamBlur} />
          </section >
        </FormProvider >
        <div className='modal-footer'>
          <Button onClick={onClose} base>Cancel</Button>
          <Button onClick={handleSave}>Submit</Button>
        </div>
      </>
    );
  }
);

export default SubmitCommentsModal;
