export const helpContent = {
  '/public-notices': (
    <>
      <p>The following screens will provide you with several options to search for, and provide comments on, projects that are currently being evaluated by the USACE Regulatory Program pursuant to Section 404 of the Clean Water Act, Section 10 of the Rivers and Harbors Act, and/or Section 103 of the Marine Protection, Research, and Sanctuaries Act. Once you have found the specific project you are interested in, you may enter your comments directly into the Comments field that is provided (note that this option is limited to 4,000 characters) or you may upload your comments and any attachments directly to the RRS. Your comments will be provided directly to the USACE Project Manager that is responsible for the evaluation of the project.</p>
      <p>You may search for a specific Public Notice by providing any of the following information:</p>
      <ul>
        <li>The ORM ID number - this will take you to a specific project.</li>
        <li>The USACE District in which the project is located - you will need to search the list provided within the Public Notice page for the project you are interested in.</li>
        <li>Search by a specific keyword from the following list (for example - entering "Pipeline" will return all projects where the word “Pipeline” is contained in the Public Notice text).</li>
        <li>Search by project or applicant name (using the Search icon).</li>
        <li>Search by location from the map view. Search option.</li>
      </ul>
    </>
  )
};

export const helpHeaders = {
  '/public-notices': 'Public Notices'
};