import { connect } from 'redux-bundler-react';
import { formatCoordStr, getCountryNameFromCode, getStateNameFromCode } from '@src/utils/helpers';
import classnames from 'classnames';
import LinkButton from '@components/link/linkButton';
import genericSecondaryModal from '@pages/Forms/components/modals/genericSecondaryModal';

const LabelValue = ({ label, value }) => (
  <div className='col-auto'>
    <span className='text-bold'>{label}:</span> {value ? value : '---'}
  </div>
);

const ProjectLocationSummary = connect(
  'doSecondaryModalOpen',
  ({
    areaLabel = 'Project Area',
    className,
    summaryData,
    directionsLabel = 'Directions to Project Location',
    doSecondaryModalOpen,
  }) => (
    <div className={classnames('border p-2', className)}>
      <div className='row mt-1'>
        <LabelValue label='Latitude' value={formatCoordStr(summaryData.latitude)} />
        <LabelValue label='Latitude' value={formatCoordStr(summaryData.longitude)} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='Address' value={summaryData.address} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='Address Line 2' value={summaryData.addressTwo} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='City' value={summaryData.city} />
      </div>
      <div className='row mt-1'>
        <LabelValue label={summaryData.country === 'US' ? 'State' : 'Region'} value={getStateNameFromCode(summaryData.state)} />
      </div>
      <div className='row mt-1'>
        <LabelValue label={summaryData.country === 'US' ? 'Zip Code' : 'Postal Code'} value={summaryData.zipcode} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='County' value={summaryData.county} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='District' value={summaryData.district} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='Country' value={getCountryNameFromCode(summaryData.country) || summaryData.country} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='Field Office Code' value={summaryData.fieldOfficeCode} />
      </div>
      <div className='row mt-1'>
        <LabelValue label={areaLabel} value={`${summaryData.projectArea} ${summaryData.unitOfMeasurement}`} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='Parcel Number' value={summaryData.parcelNumber} />
      </div>
      <div className='row mt-1'>
        <LabelValue label='PLSS Section' value={summaryData.plsssection} />
        <LabelValue label='PLSS Township' value={summaryData.plsstownship} />
        <LabelValue label='PLSS Range' value={summaryData.plssrange} />
      </div>
      <div className='row mt-1'>
        <LabelValue
          label='Nearest Waterbody'
          value={
            summaryData?.nearestWaterbody?.length > 60 ? (
              <LinkButton
                onClick={() =>
                  doSecondaryModalOpen(genericSecondaryModal, {
                    msg: summaryData.nearestWaterbody,
                    title: 'Nearest Waterbody',
                    hasCloseButton: false,
                  })
                }
                content='Click to view nearest waterbody'
              />
            ) : (
              summaryData.nearestWaterbody
            )
          }
        />
      </div>
      <div className='row mt-1'>
        <LabelValue
          label={directionsLabel}
          value={
            summaryData?.siteDirections?.length > 60 ? (
              <LinkButton
                onClick={() =>
                  doSecondaryModalOpen(genericSecondaryModal, {
                    msg: summaryData.siteDirections,
                    title: 'Project/Site Directions',
                    hasCloseButton: false,
                  })
                }
                content='Click to view project/site directions'
              />
            ) : (
              summaryData.siteDirections
            )
          }
        />
      </div>
    </div>
  )
);

export default ProjectLocationSummary;
