import { ContactTypes } from './enums';
import { countriesAlpha3Alpha2Lookup, stateAbbrProperCase, countryKeys, stateKeys } from '@forms/input-forms/_helper';
import * as turf from '@turf/turf';
import { ormRegex } from './regex';

export const classnames = (opts) => Object.keys(opts)
  .map((key) => !!opts[key] ? key : '')
  .join(' ');

export const classArray = (arr) => arr.filter(e => e).join(' ');

export const formatBytes = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const isNumeric = str => {
  if (typeof str != 'string') return false; // only process strings
  return !isNaN(str) &&                     // use type coercion to parse the entirety of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str));           // ...and ensure strings of whitespace fail
};

export const isNullUndefinedOrEmpty = (str) => str === undefined || str === null || str === '';

/**
 * Returns a provided string based on parameters provided
 * @param {string} single - The string to be returned if value is 1
 * @param {string} plural - The string to be returned if value is not 1
 * @param {number} value - Checked to determine which string to return;
 * @returns The single or plural string provided based on the value.
 */
export const pluralize = (single, plural, value) => {
  if (value === 1) return single;
  return plural;
};

export const keyAsText = key => {
  const words = key.substring(1).split(/(?=[A-Z])/).join(' ');
  return key.substring(0, 1).toUpperCase() + words;
};

export const hrefAsString = href => {
  const str = href.replace('/', '');
  const words = str.split('-');
  const upperWords = words.map(word => word.substring(0, 1).toUpperCase() + word.substring(1));

  return upperWords.join(' ');
};

// @TODO: Delete once all references are removed from bundles
export const queryFromObject = (obj = {}) => {
  const keys = Object.keys(obj);

  if (!keys.length) return '';

  const finalKeys = keys.filter(key => !!obj[key] || isNumeric(obj[key]));

  return `?${finalKeys.map(key => `${key}=${obj[key]}`).join('&')}`;
};

export const camalize = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const isObjectNotDirty = (obj) => Object.keys(obj)
  .filter(key => key !== 'contactType')
  .some(key => obj[key] !== undefined && obj[key] !== null && obj[key] !== '');

export const stripUncompletedContacts = (contacts) => {
  let result = contacts.filter(contact => isObjectNotDirty(contact));
  return result;
};

export const updateHasAgentVal = (request) => {
  if (!request.hasOwnProperty('contacts') || !request.contacts.some(contact => contact.contactType === ContactTypes.Agent))
    return false;
  return true;
};

export const filterNullEmptyObjects = (data) => {
  if (Array.isArray(data)) {
    // Handle array elements recursively
    const filteredArray = data.map(item => filterNullEmptyObjects(item)).filter(item => !(typeof item === 'object' && item !== null && Object.keys(item).length === 0) && item !== '' && item !== undefined);
    // Return null if array is empty after filtering
    return filteredArray.length > 0 && filteredArray.some(obj => obj !== undefined && obj !== null) ? filteredArray : null;
  } else if (typeof data === 'object' && data !== null) {
    // Create a new object for filtered properties if input is an object
    const newObj = {};
    Object.keys(data).forEach(key => {
      const value = data[key];
      const filteredValue = filterNullEmptyObjects(value);
      if (typeof filteredValue === 'object') {
        if (filteredValue !== null && Object.keys(filteredValue).length !== 0) {
          newObj[key] = filteredValue;
        }
      } else if (filteredValue !== '' && filteredValue !== null && filteredValue !== undefined) {
        newObj[key] = filteredValue;
      }
    });
    // Return null if the object is empty after filtering
    return Object.keys(newObj).length > 0 ? newObj : undefined;
  } else {
    // Return the data directly if it is not an object or array
    return data !== '' && data !== undefined && JSON.stringify(data) !== 'null' ? data : null;
  }
};

export const formatCoordFlt = (coord) => coord && parseFloat(Number(coord).toFixed(7));

export const formatCoordStr = (coord) => coord && Number(coord).toFixed(7);

export const isValidASCII = (str) => [...str].every(char => {
  const code = char.charCodeAt(0);
  return code >= 32 && code <= 126;
});

export const sanitizeASCII = (str) => [...str].filter(char => {
  const code = char.charCodeAt(0);
  return code >= 32 && code <= 126;
}).join('');

export const dateBeforeA = '1896-03-03';
export const dateBeforeB = '1986-11-13';

export const isDateValid = (dateStr, startDate, endDate) => {
  const date = new Date(dateStr);
  const datetime = new Date(dateStr).getTime();
  const startDatetime = new Date(startDate).getTime();
  const endDatetime = new Date(endDate).getTime();
  const year = date?.getFullYear();

  // CASE: Year is NaN
  if (isNaN(year)) {
    return false;
  }

  // CASE: Minimum/maximum year requirement
  if (datetime < startDatetime || datetime > endDatetime) {
    return false;
  }

  // CASE: Year more than 4 numbers
  if (String(year)?.length > 4) {
    return false;
  }

  return true;
};

export const isDateRangeValid = (dateStart, dateEnd) => {
  const date1 = new Date(dateStart).getTime();
  const date2 = new Date(dateEnd).getTime();

  if (date1 < date2) {
    return true;
  } else if (date1 > date2) {
    return false;
  } else if (date1 === date2) {
    return true;
  } else {
    return false;
  }
};

export const formatDateToMMDDYYYY = (dateValue) => {
  const [year, month, day] = dateValue.split('T')[0].split('-').map(Number);
  const date = new Date(year, month - 1, day);
  const formattedValue = date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
  return formattedValue;
};

export const formatUSPhoneNumber = (value) => {
  const numericValue = value.replace(/\D/g, '');
  if (numericValue.length <= 3) {
    return numericValue;
  } else if (numericValue.length <= 6) {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
  } else {
    return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
  }
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const mapCountryAlpha3toAlpha2 = (alpha3) => countriesAlpha3Alpha2Lookup[alpha3];

export const mapStateAbbrToFullName = (stateAbbr) => stateAbbrProperCase[stateAbbr];

export const isValidORMNumber = (num) => {
  const valIsValidORMNumber = ormRegex.test(num);
  return valIsValidORMNumber;
};

export const calculateGeometryExtent = (geometry) => {
  const type = geometry?.type;

  switch (type) {
    case 'Point':
      return calculateBufferedExtent(geometry, 5);

    case 'MultiPoint':
      return calculateMultiPointExtent(geometry, 5);

    case 'Polygon':
    case 'MultiPolygon':
      return calculatePolygonExtent(geometry);

    case 'LineString':
    case 'MultiLineString':
      return calculateLineStringExtent(geometry);

    default:
      return null;
  }
};

export const calculateBufferedExtent = (geometry, bufferMiles) => {
  const buffered = turf.buffer(geometry, bufferMiles, { units: 'miles' });
  const bbox = turf.bbox(buffered);
  return convertBBoxToLeafletBounds(bbox);
};

export const calculateMultiPointExtent = (geometry, bufferMiles) => {
  const buffered = turf.buffer(geometry, bufferMiles, { units: 'miles' });
  const bbox = turf.bbox(buffered);
  return convertBBoxToLeafletBounds(bbox);
};

export const calculatePolygonExtent = (geometry) => {
  const bbox = turf.bbox(geometry);
  return convertBBoxToLeafletBounds(bbox);
};

export const calculateLineStringExtent = (geometry) => {
  const bbox = turf.bbox(geometry);
  return convertBBoxToLeafletBounds(bbox);
};

export const convertBBoxToLeafletBounds = (bbox) => {
  const [minX, minY, maxX, maxY] = bbox;
  return [
    [minY, minX],
    [maxY, maxX]
  ];
};

export const calculateAcres = (length, width) => {
  const squareFeet = length * width;
  const acres = (squareFeet / 43560).toFixed(6);
  return parseFloat(acres);
};

export const getProposedAmount = (isDisabled, rowAmountUnit, rowLength, rowWidth) => {
  if (!isDisabled) {
    return '';
  }
  let calculatedValue;
  switch (rowAmountUnit) {
    case 'Square Feet':
      calculatedValue = rowLength * rowWidth;
      break;
    case 'Acres':
      calculatedValue = calculateAcres(rowLength, rowWidth);
      break;
    default:
      return 'N/A';
  }
  if (isNaN(calculatedValue) || calculatedValue === 0) {
    return 'N/A';
  } else {
    return calculatedValue.toString();
  }
};

export const scrollToElement = (elementId) => {
  setTimeout(() => {
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
  }, 100);
};

export const LatLngOutsideUS = (lat, lng) => {

  // Check if latitude is outside the Northern Hemisphere (0 to 90 degrees)
  if (Number(lat) >= -90 && Number(lat) <= 0) {
    return true;
  }
  // Check if longitude is outside the Western Hemisphere (-180 to 0 degrees)
  if (Number(lng) >= 0 && Number(lng) <= 180) {
    return true;
  }
  return false;
};

export const getLabelTextById = (id) => {
  const label = document.querySelector(`label.usa-label span[id="${id}_label"]`);

  // Set custom label for State/Region and Zip/Postal Code fields
  let text = '';
  if (label?.textContent === 'State' || label?.textContent === 'Region') {
    text = 'State/Region';
  } else if (label?.textContent?.toLowerCase() === 'zip code' || label?.textContent?.toLowerCase() === 'postal code') {
    text = 'Zip Code/Postal Code';
  } else {
    text = label?.textContent;
  }

  return label ? text : undefined;
};

export const compareArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const getStateNameFromCode = (code) => stateKeys?.[code] || code || '';

export const getCountryNameFromCode = (code) => countryKeys?.[code] || code || '';

export const formatReadOnlyValue = (value) => {
  if (value === true || value === 'true') return 'Yes';
  if (value === false || value === 'false') return 'No';

  const stateName = getStateNameFromCode(value);
  if (stateName) return stateName;

  const countryName = getCountryNameFromCode(value);
  if (countryName) return countryName;

  return value || '';
};

export const convertGeoJsonToEsri = (geoJson) => {
  const spatialReference = { wkid: 4326 };

  const convertPoint = (geoJson) => ({
    x: geoJson.coordinates[0],
    y: geoJson.coordinates[1],
    spatialReference
  });

  const convertLineString = (geoJson) => ({
    paths: [geoJson?.coordinates],
    spatialReference
  });

  const convertPolygon = (geoJson) => ({
    rings: geoJson?.coordinates,
    spatialReference
  });

  const convertMultiPoint = (geoJson) => ({
    points: geoJson?.coordinates,
    spatialReference
  });

  const convertMultiLineString = (geoJson) => ({
    paths: geoJson?.coordinates,
    spatialReference
  });

  const convertMultiPolygon = (geoJson) => ({
    rings: geoJson?.coordinates?.flat(),
    spatialReference
  });

  const convertEnvelope = (geoJson) => {
    const [xmin, ymin] = geoJson?.coordinates[0][0];
    const [xmax, ymax] = geoJson?.coordinates[0][2];
    return {
      xmin,
      ymin,
      xmax,
      ymax,
      spatialReference
    };
  };

  switch (geoJson?.type) {
    case 'Point':
      return convertPoint(geoJson);
    case 'LineString':
      return convertLineString(geoJson);
    case 'Polygon':
      return convertPolygon(geoJson);
    case 'MultiPoint':
      return convertMultiPoint(geoJson);
    case 'MultiLineString':
      return convertMultiLineString(geoJson);
    case 'MultiPolygon':
      return convertMultiPolygon(geoJson);
    case 'Polygon' && geoJson.bbox: // Envelope-like polygons with bbox
      return convertEnvelope(geoJson);
    default:
      throw new Error('Unsupported GeoJSON type');
  }

};

export const doClearIncompletePhoneInfo = (payload) => {
  const contacts = payload?.request?.contacts;

  const filteredContacts = contacts?.map(contact => {
    let updatedContact = { ...contact };

    // Check phoneNumberOne and remove country code if empty
    if (!contact?.phoneOne || contact?.phoneOne?.trim() === '') {
      updatedContact.phoneOneCountryCode = '';
    }

    // Check phoneNumberTwo and remove country code if empty
    if (!contact?.phoneTwo || contact?.phoneTwo?.trim() === '') {
      updatedContact.phoneTwoCountryCode = '';
    }

    // Check faxPhone and remove country code if empty
    if (!contact?.faxPhone || contact?.faxPhone?.trim() === '') {
      updatedContact.faxCountryCode = '';
    }

    return updatedContact;
  });

  return {
    ...payload,
    request: {
      ...payload.request,
      contacts: filteredContacts
    }
  };
};
