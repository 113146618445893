import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Label, Select, Pagination} from '@trussworks/react-uswds';

const pageBreakdown = [10, 20, 30, 50];
const getPageCount = (totalCount, itemsPerPage) => totalCount > itemsPerPage ? Math.ceil(totalCount/itemsPerPage) : 1;

const PublicNoticesTableFooter = connect(
  'doSetPublicNoticesItemsPerPage',
  'doSetPublicNoticesPageNumber',
  'selectPublicNoticesItemsPerPage',
  'selectPublicNoticesTotalCount',
  'selectPublicNoticesPageNumber',
  ({
    doSetPublicNoticesItemsPerPage,
    doSetPublicNoticesPageNumber,
    publicNoticesItemsPerPage,
    publicNoticesTotalCount,
    publicNoticesPageNumber,
    table
  }) => {
    const [totalPageCount, setTotalPageCount] = useState(getPageCount(publicNoticesTotalCount, publicNoticesItemsPerPage));

    const handlePreviousButton = () => {
      doSetPublicNoticesPageNumber(Math.max(0, Number(publicNoticesPageNumber) - 1));
    };

    const handleNextButton = () => {
      doSetPublicNoticesPageNumber(Number(publicNoticesPageNumber) + 1);
    };

    const handlePageNumberClick = (e) =>{
      doSetPublicNoticesPageNumber(e?.target?.innerText);
    };

    const handlePageSizeSelect = e => {

      const pageSize = parseInt(e.target.value);
      doSetPublicNoticesItemsPerPage(pageSize);
      doSetPublicNoticesPageNumber(1);
      table.setPageSize(pageSize);
    };

    // Calculates total page count
    useEffect(() => {
      const newPageCount = getPageCount(publicNoticesTotalCount, publicNoticesItemsPerPage);
      const resetPage = newPageCount !== totalPageCount;

      setTotalPageCount(newPageCount);
      resetPage && doSetPublicNoticesPageNumber(1);
    }, [publicNoticesItemsPerPage, publicNoticesTotalCount, doSetPublicNoticesPageNumber, totalPageCount]);

    return (
      <div className='d-flex justify-content-center usa-prose'>
        <div className='d-flex flex-align-center'>
          <Pagination
            className='margin-right-3 pagination-styles'
            currentPage={Number(publicNoticesPageNumber)}
            maxSlots={7}
            totalPages={totalPageCount}
            onClickNext={handleNextButton}
            onClickPageNumber={handlePageNumberClick}
            onClickPrevious={handlePreviousButton}
            pathname=''
          />

          <span className='d-flex flex-align-center margin-right-3 rows-per-page' style={{ whiteSpace: 'nowrap' }}>
            <Label htmlFor='rows_per_page' className='margin-right-1'>
              rows per page:
            </Label>
            <div className='footer-select-container'>
              <Select
                name='rows_per_page'
                id='rows_per_page'
                defaultValue={publicNoticesItemsPerPage}
                onChange={handlePageSizeSelect}
              >
                {pageBreakdown.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Select>
            </div>
          </span>

          <span className='d-flex flex-align-center'>
            {`${publicNoticesPageNumber} of ${totalPageCount} page${totalPageCount !== 1 ? 's' : ''}`}
          </span>
        </div>
      </div>
    );
  });

export default PublicNoticesTableFooter;

//Keep this just in case the client wants it
/* <span className='margin-right-3'>
            jump to page:{' '}
        <input style={{width:'50px'}} type='number' min={0} max={totalPageCount} value={jumpPageNumber} onKeyDown={e => handleJumpTo(e)} onChange={e => handleJumpPageNumberChange(e)} />
        <Button className='margin-left-1' unstyled onClick={handleGoClick} >
            Go
        </Button>
      </span> */

// const handleFirstButton = () => {
//   doSetPublicNoticesPageNumber(1);
//   setJumpPageNumber(1);
// };

// const handleLastButton = () => {
//   doSetPublicNoticesPageNumber(totalPageCount);
//   setJumpPageNumber(1);
// };

// const handleJumpPageNumberChange = (e) => {
//   setJumpPageNumber(e.target.value);
// };

// const handleJumpTo = (e) => {
//   const value = e?.target?.value;
//   if (e?.key === 'Enter' && value > 0 && value <= totalPageCount)
//   {
//     doSetPublicNoticesPageNumber(value);
//   }
// };

// const handleGoClick = () => {
//   if (jumpPageNumber > 0 && jumpPageNumber <= totalPageCount)
//   {
//     doSetPublicNoticesPageNumber(jumpPageNumber);
//   }
// };
