import { MitigationTypes } from '@src/utils/enums';
import { compareArrays } from '@src/utils/helpers';
import { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'redux-bundler-react';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const WatersNameTableCell = connect(
  'selectRequestFormData',
  ({
    requestFormData,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const initialOptions = columnMeta?.options;
    const [value, setValue] = useState(initialValue);
    const [waterNameOptions, setWaterNameOptions] = useState(initialOptions);
    const waterNames = requestFormData?.request?.aquaticResources?.map(item => item.waterName);
    const debouncedUpdateRef = useRef();

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    const handleChange = e => {
      setValue(e?.target?.value);
      updateValue(e?.target?.value);
    };

    const getRequired = () => {
      if (columnMeta?.dataType === 'mitigations') {
        const mitigationType = row.getValue('mitigationType');
        return mitigationType !== MitigationTypes.AdvancedPermitteeResponsible ? true : false;
      } else {
        return columnMeta?.required;
      }
    };

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta?.updateData(row.index, column.id, newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, tableMeta]);

    // Update WaterName cell dropdown options
    useEffect(() => {
      setWaterNameOptions(requestFormData?.request?.aquaticResources?.map(resource =>
        resource?.waterName ? { value: resource?.waterName, label: resource?.waterName } : { value: null, label: 'NO NAME ENTERED!' }
      ));
    }, [requestFormData?.request?.aquaticResources]);

    // Clear cell value if aquatic resources water names change
    useEffect(() => {
      if (waterNames?.includes(initialValue) === false && compareArrays(waterNameOptions, initialOptions) === false) {
        setValue(null);
        tableMeta?.updateData(row.index, column.id, null);
      }
    }, [column.id, initialOptions, initialValue, row.index, tableMeta, waterNameOptions, waterNames]);
    
    return (
      <select
        aria-label='Select water name'
        disabled={columnMeta?.readOnly}
        id={cell.id}
        onChange={handleChange}
        required={getRequired()}
        style={{ width: '100%' }}
        value={value ?? ''}>
        <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
        {waterNameOptions?.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    );
  });

export default WatersNameTableCell;
