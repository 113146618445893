import statusModal from '../app-pages/Forms/components/modals/statusModal';
import XMLParser from 'react-xml-parser';

// Environment URLs configuration
const urls = {
  local: {
    mitigationBank: import.meta.env.VITE_MITIGATION_BANK_RIBITS_BASE_URL,
    inLieuFee: import.meta.env.VITE_INLIEUFEE_RIBITS_BASE_URL,
  },
  development: {
    mitigationBank: import.meta.env.VITE_MITIGATION_BANK_RIBITS_BASE_URL,
    inLieuFee: import.meta.env.VITE_INLIEUFEE_RIBITS_BASE_URL,
  },
  test: {
    mitigationBank: import.meta.env.VITE_MITIGATION_BANK_RIBITS_BASE_URL_TEST,
    inLieuFee: import.meta.env.VITE_INLIEUFEE_RIBITS_BASE_URL_TEST,
  },
  prod: {
    mitigationBank: import.meta.env.VITE_MITIGATION_BANK_RIBITS_BASE_URL_PROD,
    inLieuFee: import.meta.env.VITE_INLIEUFEE_RIBITS_BASE_URL_PROD,
  },
};
const currentEnv = import.meta.env.VITE_ENVIRONMENT || 'development';
const baseUrls = urls[currentEnv] || urls.development;
const TIMEOUT = 10000;

const RIBITSAPIBundle = {
  name: 'ribitsapi',

  getReducer: () => {
    const initialData = currentEnv === 'local' || currentEnv === 'development'
      ? { banksitelist: [], banksitedata: [], ilfprogramlist: [], ilfprogramdata: [], error: null }
      : { data: [], error: null };

    return (state = initialData, { type, payload }) => {
      switch (type) {
        case 'UPDATING_BANK_SITE_LIST':
          return { ...state, banksitelist: payload };
        case 'UPDATING_BANK_SITE_DATA':
          return { ...state, banksitedata: payload };
        case 'UPDATING_ILF_PROGRAM_LIST':
          return { ...state, ilfprogramlist: payload };
        case 'UPDATING_ILF_PROGRAM_DATA':
          return { ...state, ilfprogramdata: payload };
        case 'RESET_RIBITS_API_RESULTS':
          return initialData;
        case 'UPDATING_BANK_SITE_LIST_ERROR':
        case 'UPDATING_ILF_PROGRAM_LIST_ERROR':
          return { ...state, error: payload };
        default:
          return state;
      }
    };
  },
  selectBankSiteList: (state) => state.ribitsapi.banksitelist || state.ribitsapi.data,
  selectBankSiteData: (state) => state.ribitsapi.banksitedata,
  selectILFProgramList: (state) => state.ribitsapi.ilfprogramlist,
  selectILFProgramData: (state) => state.ribitsapi.ilfprogramdata,
  selectRIBITSAPIError: (state) => state.ribitsapi.error,

  doGetBankSiteList: (key, value) => ({ dispatch, apiGet, store }) => {
    let query;
    switch (key) {
      case 'bank':
        query = `{ "kind_of_bank": "${value}" }`;
        break;
      case 'district':
        query = `{ "district": "${value}" }`;
        break;
      case 'fieldoffice':
        query = `{ "fieldoffice": "${value}" }`;
        break;
      case 'state':
        query = `{ "state": "${value}" }`;
        break;
      case 'noaaregion':
        query = `{ "noaaregion": "${value}" }`;
        break;
      default:
        query = `{ "kind_of_bank": "${value}" }`;
    }

    const uri = '/api/RIBITS/bankSite?' + new URLSearchParams({
      query: query,
      endPointSuffix: 'list',
    });

    apiGet(uri, (err, body) => {
      if (!err && body.status === 'Success') {
        const jsonObject = JSON.parse(body.data);
        const newArray = [...jsonObject.ITEMS];
        dispatch({ type: 'UPDATING_BANK_SITE_LIST', payload: newArray });
      } else {
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An unknown error has occurred', status: body?.status ?? err?.status ?? 'Unknown Error' });
        dispatch({ type: 'UPDATING_BANK_SITE_LIST_ERROR', payload: err });
      }
    });
  },

  doGetILFProgramList: (key, value) => ({ dispatch, apiGet, store }) => {
    let query;
    switch (key) {
      case 'district':
        query = `{ "district": "${value}" }`;
        break;
      case 'fieldoffice':
        query = `{ "fieldoffice": "${value}" }`;
        break;
      case 'state':
        query = `{ "state": "${value}" }`;
        break;
      case 'noaaregion':
        query = `{ "noaaregion": "${value}" }`;
        break;
      default:
        query = `{ "district": "${value}" }`;
        break;
    }

    const uri = '/api/RIBITS/ilfProgram?' + new URLSearchParams({
      query: query,
      endPointSuffix: 'list',
    });

    apiGet(uri, (err, body) => {
      if (!err && body.status === 'Success') {
        const jsonObject = JSON.parse(body.data);
        const newArray = [...jsonObject.ITEMS];
        dispatch({ type: 'UPDATING_ILF_PROGRAM_LIST', payload: newArray });
      } else {
        dispatch({ type: 'UPDATING_ILF_PROGRAM_LIST_ERROR', payload: err });
        store.doSecondaryModalOpen(statusModal, { msg: body?.msg ?? err?.msg ?? 'An unknown error has occurred', status: body?.status ?? err?.status ?? 'Unknown Error' });
      }
    });
  },

  doGetMitigationBanksOrILF: (mitigationType) => async ({ dispatch, store }) => {
    const { latitude, longitude } = store.selectRequestFormDataLocation() || {};
    const baseUrl = mitigationType === 'Mitigation Bank'
      ? baseUrls.mitigationBank
      : baseUrls.inLieuFee;
    const uri = currentEnv === 'local'
      ? `/ribits${baseUrl}?longitude=${longitude}&latitude=${latitude}`
      : `${baseUrl}?longitude=${longitude}&latitude=${latitude}`;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);
    try {
      const response = await fetch(uri, {
        method: 'GET',
        headers: {
          'Accept': 'application/xml',
          'Content-Type': 'application/xml'
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        signal: controller.signal
      });
      clearTimeout(timeoutId);
      if (!response.ok) {
        throw new Error(`API Error: ${response.statusText}`);
      }     
      const data = await response.text();// Assuming the response is XML text
      // Parse XML response to JSON object
      const xml = new XMLParser().parseFromString(data);
      let jsonData = [];   
      xml?.children?.forEach(bank => {
        if (bank.name === 'bank' || bank.name === 'program') {
          // Format JSON object
          let jsonObj = {};
          bank.children.forEach(el => {
            let value = el.value;
            if (el.name === 'name') {
              // Convert &apos; to apostrophe in the bank name
              value = el.value.replace(/&apos;/g, '\'');
            }
            jsonObj[el.name] = value;
          });
          jsonData.push(jsonObj);
        }
      });    
      dispatch({ type: 'UPDATING_BANK_SITE_LIST', payload: jsonData });
    } catch (error) {
      console.error('Fetch error:', error);
      dispatch({ type: 'UPDATING_BANK_SITE_LIST_ERROR', payload: error.message });
      throw error;
    }
  },
};
export default RIBITSAPIBundle;