import { composeBundles, createCacheBundle, createUrlBundle } from 'redux-bundler';

import addressValidationBundle from './address-validation-bundle';
import antiSpamBundle from './anti-spam-bundle';
import aorApiBundle from './aor-api-bundle';
import arcgisServicesBundle from './arcgis-services-bundle';
import cache from '../cache';
import complexStateBundle from './complex-state-bundle';
import contactInfoBundle from './contact-info-bundle';
import createAuthBundle from './auth-bundle';
import createJwtApiBundle from './create-jwt-api-bundle';
import dashboardBundle from './dashboard-bundle';
import developerBundle from './developer-bundle';
import districtAPIBundle from './district-api-bundle';
import fieldLookupTableBundle from './field-lookup-table-bundle';
import fileStorageBundle from './file-storage-bundle';
import fileUploadBundle from './file-upload-bundle';
import formValidationBundle from './form-validation';
import formViewBundle from './form-view-bundle';
import geocodingAPIBundle from './geocoding-api-bundle';
import geoStateValidationApiBundle from './geo-state-validation-api-bundle';
import IPACAPIBundle from './ipac-api-bundle';
import JDFormDataBundle from './jdform-data-bundle';
import loadingBundle from './loading-bundle';
import mapBundle from './map-bundle';
import modalBundle from './modal-bundle';
import PermitsDataBundle from './permits-data-bundle';
import PLSSAPIBundle from './PLSS-api-bundle';
import preAppFormDataBundle from './preappform-data-bundle';
import profileBundle from './profile-bundle';
import projectAddressBundle from './project-address-bundle';
import projectBundle from './project-bundle';
import publicNoticesBundle from './public-notices-bundle';
import requestDataBundle from './request-data-bundle';
import RIBITSAPIBundle from './RIBITS-api-bundle';
import routesBundle from './routes-bundle';
import secondaryModalBundle from './secondary-modal-bundle';
import sectionStateBundle from './section-state-bundle';
import selectedRequestBundle from './selected-request-bundle';
import stepperBundle from './stepper-bundle';
import tableBundle from './table-bundle';
import urlBasePath from './create-url-base-path-bundle';
import violationsFormDataBundle from './violationsform-data-bundle';

// @TODO: what is our mock token?
const mockTokenApplicationAdmin =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyIiwibmFtZSI6IlVzZXIuQXBwbGljYXRpb25BZG1pbiIsImlhdCI6MTUxNjIzOTAyMiwiZXhwIjoyMDAwMDAwMDAwLCJyb2xlcyI6W119.aKaDNBnuhQyXI6zvzn-dAg8SxJSP3mQEx5FTSmJbYog';

export default composeBundles(
  /** -- START APPLICATION BUNDLES -- **/
  addressValidationBundle,
  antiSpamBundle,
  complexStateBundle,
  contactInfoBundle,
  createAuthBundle({
    appId: '07f1223f-f208-4b71-aa43-5d5f27cd8ed9',
    redirectOnLogout: '/',
    mock: import.meta.env.VITE_ENVIRONMENT === 'local' ? true : false,
    token: import.meta.env.VITE_ENVIRONMENT === 'local' ? mockTokenApplicationAdmin : null,
  }),
  createCacheBundle(cache.set),
  createJwtApiBundle({
    root: import.meta.env.VITE_API_URL,
    tokenSelector: 'selectAuthToken',
  }),
  createUrlBundle(),
  fileUploadBundle,
  formValidationBundle,
  formViewBundle,
  loadingBundle,
  mapBundle,
  modalBundle,
  PermitsDataBundle,
  projectAddressBundle,
  routesBundle,
  secondaryModalBundle,
  sectionStateBundle,
  selectedRequestBundle,
  stepperBundle,
  tableBundle,
  urlBasePath({ base: import.meta.env.VITE_URL_BASE_PATH ?? '' }),
  /** -- END APPLICATION BUNDLES -- **/

  /** -- START API BUNDLES -- **/
  aorApiBundle,
  arcgisServicesBundle,
  dashboardBundle,
  developerBundle,
  districtAPIBundle,
  fieldLookupTableBundle,
  fileStorageBundle,
  geocodingAPIBundle,
  geoStateValidationApiBundle,
  IPACAPIBundle,
  JDFormDataBundle,
  PLSSAPIBundle,
  preAppFormDataBundle,
  profileBundle,
  projectBundle,
  requestDataBundle,
  RIBITSAPIBundle,
  violationsFormDataBundle,
  publicNoticesBundle,
  /** -- END API BUNDLES -- **/
);
