import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAB_BALTIMORE = () => (
  <>
    <section id='nab-baltimore'>
      <DistrictInfoTable district='NAB'/>
      <p>
      Please do not use the RRS to apply for a permit in Pennsylvania, Maryland, the District of Columbia, and certain military installations within northern Virginia (i.e., Fort Belvoir, Fort Myer, and the Pentagon).  The <ExternalLink content='Baltimore District website' title='Baltimore District Website' href='https://www.nab.usace.army.mil/Missions/Regulatory/Permit-Types-and-Process/' /> contains information on how to obtain a Department of the Army authorization and copies of the permit application forms.
      </p>
    </section>
  </>
);

export default NAB_BALTIMORE;
