import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRB_BUFFALO = () => (
  <>
    <section id='lrb-buffalo'>
      <DistrictInfoTable district='LRB'/>
      <p>Please do not use RRS to apply for a permit in New York. The Buffalo and New York Districts employ a <ExternalLink content='joint permit application process with New York State' title='New York State JPA' href='https://www.lrd.usace.army.mil/Wetlands-Permits/Article/3646979/new-york-state-regulatory-program/'></ExternalLink>.   </p>
    </section>
  </>
);

export default LRB_BUFFALO;
