import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect } from 'redux-bundler-react';

import { isDateValid, isValidASCII, sanitizeASCII } from '@src/utils/helpers';
import genericSecondaryModal from '@forms/components/modals/genericSecondaryModal';
import { decimalNumberRegex } from '@src/utils/regex';
import { ErrorMessages } from '@src/utils/enums';
import statusModal from '@forms/components/modals/statusModal';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const TableCell = connect(
  'doSecondaryModalOpen',
  'doValidateGeometryInValidDistrict',
  'selectRequestFormData',
  ({
    doSecondaryModalOpen,
    doValidateGeometryInValidDistrict,
    requestFormData,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const type = columnMeta?.type ?? 'text';
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    // Setting the End Date
    var endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 100);
    endDate = new Date(endDate).toISOString().slice(0, 10);

    const debouncedUpdateRef = useRef();

    useEffect(() => {
      debouncedUpdateRef.current = debounce((newValue) => {
        if (tableMeta?.updateData) {
          tableMeta?.updateData(row.index, column.id, type === 'number' ? Number(newValue) : newValue ?? newValue);
        }
      }, 500);
    }, [row.index, column.id, tableMeta?.updateData, type, tableMeta]);

    const updateValue = useCallback((newValue) => {
      debouncedUpdateRef.current(newValue);
    }, []);

    const handleBlur = async (e) => {
      // Clear field if value is 0 or is a negative number
      if (String(e?.target?.value) === '0' || String(e?.target?.value)[0] === '-') {
        setValue('');
        updateValue('');
      }
      if (columnMeta?.formatCoordStr) {
        if (!isNaN(e?.target?.value)) {
          const formattedCoord = columnMeta.formatCoordStr(e?.target?.value);
          setValue(formattedCoord);
          updateValue(formattedCoord);
        }
      }
      if(columnMeta?.validateDistrict === true)
      {
        const latitude = row.getValue('latitude');
        const longitude = row.getValue('longitude');
        if (latitude && longitude) {
          const geometry = {type: 'Point',   coordinates: [longitude, latitude]};
          const isValid = await doValidateGeometryInValidDistrict(geometry);
          if(!isValid){
            doSecondaryModalOpen(statusModal,{msg: 'A USACE regulatory district could not be determined for these coordinates, Please verify your latitude and longitude and try again.', status: 'Invalid Resource Location'});
            setValue('');
            updateValue('');
          }
        }
      }
      if (type === 'date') {
        if (isDateValid(value, columnMeta?.min, endDate) === false) {
          // @TODO: Workaround to rerender the cell - generates console warning for invalid formatting
          setValue('0000-0-0');
          updateValue('');
        }
      }
    };

    const handleChange = async (e) => {
      const inputValue = e?.target?.value ?? '';
      if (inputValue === '') {
        setValue(null);
        updateValue(null);
      } else if (columnMeta?.isNumber === true) {
        if (decimalNumberRegex.test(inputValue)) {
          setValue(inputValue);
          updateValue(inputValue);
        }
      } else {
        setValue(inputValue);
        updateValue(inputValue);
      }
    };

    // Handling special characters
    useEffect(() => {
      if (value && typeof value === 'string') {
        if (isValidASCII(value)) {
          setValue(value);
          updateValue(value);
        } else {
          // Optionally handle invalid input here, such as warning the user
          const msg = ErrorMessages.InvalidCharacters;
          doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
          setValue(sanitizeASCII(value));
          updateValue(sanitizeASCII(value));
        }
      }
    }, [value, setValue, updateValue, doSecondaryModalOpen]);

    const getMaxLength = () => {
      if (type === 'text') {
        return columnMeta?.maxLength || 256;
      }
      return null;
    };

    return type === 'select' ? (
      <select
        aria-label={columnMeta?.label || 'Select an option'}
        disabled={columnMeta?.readOnly}
        id={cell.id}
        onBlur={handleBlur}
        onChange={handleChange}
        required={columnMeta?.required}
        style={{ width: '100%' }}
        value={value ?? ''}>
        <option key={0} value='' className='none' style={{ display: 'none' }}>-- Select a value --</option>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    ) : (
      
      <input
        aria-label={columnMeta?.label || 'Enter value'}
        id={cell.id}
        max={type === 'date' ? endDate : null}
        maxLength={getMaxLength()}
        min={type === 'date' ? columnMeta?.min : null}
        onBlur={handleBlur}
        onChange={handleChange}
        readOnly={columnMeta?.readOnly}
        required={columnMeta?.required}
        style={{ width: '100%' }}
        type={type}
        value={value ?? ''}
      />
    );
  });
