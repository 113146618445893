import React, { useEffect} from 'react';
import Tooltip from '@components/tooltip/tooltip';
import { useFormContext } from 'react-hook-form';
import { Label, Select, TextInput } from '@trussworks/react-uswds';
import { formatUSPhoneNumber , formatReadOnlyValue } from '@src/utils/helpers';
import { phonetypes } from '@src/app-pages/Forms/input-forms/_helper';
import classnames from 'classnames';

import './PhoneInput.scss';

const PhoneInput = ({
  className,
  extensionName,
  hint,
  label,
  onBlur = () => {},
  onChange = () => {},
  phoneName,
  phoneTypeName,
  phoneCountryCodeName,
  readOnly,
  required,
  showTypeSelect = false,
  tooltip,
  tooltipClickable,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext();
  const phoneOptions = phonetypes?.map((item, i) => (
    <option key={i + 2} value={item?.value}>
      {item?.text}
    </option>
  ));
  const defaultOption = '-Select Option-';
  const phoneCountryCodeError = errors[phoneCountryCodeName];
  const phoneError = errors[phoneName];
  const phoneTypeError = errors[phoneTypeName];
  const extensionError = errors[extensionName];

  const selectCss = classnames('type-container', className, {
    'is-invalid': phoneTypeError,
  });

  const countryCodeVal = watch(phoneCountryCodeName);
  const phoneVal = watch(phoneName);
  const phoneTypeVal = watch(phoneTypeName);

  // limit phone number length
  useEffect(() => {
    if (phoneVal?.replace(/\D/g, '')?.length > 15) {
      setValue(phoneName, phoneVal.substring(0, 15, { shouldValidate: true }));
    }
  }, [phoneName, phoneVal, setValue]);

  const isValidPhoneKey = (key) => /[\d]|Backspace/.test(key);

  const handlePhoneChange = (e) => {
    const key = e?.key;
    const value = e?.target?.value;

    if (!isValidPhoneKey(key)) {
      e?.preventDefault();
    }
    if (countryCodeVal === '1') {
      const formattedNumber = formatUSPhoneNumber(value);
      setValue(phoneName, formattedNumber);
      onChange(e);
    } else {
      const digitsOnly = value?.replace(/\D/g, '');
      setValue(phoneName, digitsOnly);
      onChange(e);
    }
  };

  const handlePhoneBlur = (e) => {
    onBlur(e);
    trigger(phoneTypeName);
    trigger(phoneCountryCodeName);
    trigger(phoneName);
  };

  const handleCountryCodeBlur = (e) => {
    if (countryCodeVal === '1' && phoneVal.replace(/\D/g, '').length <= 10) {
      setValue(phoneName, formatUSPhoneNumber(phoneVal), { shouldValidate: true });
    }
    else if (countryCodeVal === '1' && phoneVal.replace(/\D/g, '').length > 10) {
      setValue(phoneName, formatUSPhoneNumber(phoneVal.substring(0, 10)), { shouldValidate: true });
    }
    else if (countryCodeVal !== '1')
    {
      setValue(phoneName, phoneVal?.replace(/\D/g, ''), { shouldValidate: true });
    }
    onBlur(e);
    trigger(phoneCountryCodeName);
    trigger(phoneName);
  };

  const { ref: typeRef, ...typeRest } = register(phoneTypeName, { onChange, onBlur: handlePhoneBlur });
  const { ref: countryCodeRef, ...countryCodeRest } = register(phoneCountryCodeName, { onChange, onBlur: handleCountryCodeBlur });
  const { ref: phoneRef, ...phoneRest } = register(phoneName, { onChange: handlePhoneChange, onBlur: handlePhoneBlur });
  const { ref: extensionRef, ...extensionRest } = register(extensionName, { onChange, onBlur });

  return (
    <div className='phone-input-container'>
      {label && (
        <Label htmlFor={label}>
          <span id={`${label}_label`}>{label}</span>
          {required ? <span className='asterisk-color'>*</span> : <span className='text-italic'> (optional)</span>}
          {tooltip && (
            <Tooltip
              clickable={tooltipClickable}
              content={tooltip}
              header={label}
              iconStyle={{ marginLeft: '5px' }}
              name={phoneName}
            />
          )}
        </Label>
      )}
      {hint && (
        <div className='usa-hint' id={`${phoneName}_hint`}>
          {hint}
        </div>
      )}
      <div className='inputs-container'>
        {showTypeSelect && (
          <div className={selectCss}>
            {readOnly ? (
              <TextInput
                id={phoneTypeName}
                name={phoneTypeName}
                readOnly={readOnly}
                style={{ marginTop: '1px' }}
                value={formatReadOnlyValue(getValues(phoneTypeName))}
              />
            ) : (
              <Select
                aria-invalid={phoneTypeError ? 'true' : 'false'}
                aria-required={required ? 'true' : 'false'}
                disabled={readOnly}
                id={phoneTypeName}
                inputRef={typeRef}
                name={phoneTypeName}
                required={required}
                {...typeRest}
              >
                <option value='' style={{ display: required ? 'none' : 'inline' }}>{`${defaultOption}`}</option>
                {phoneOptions}
              </Select>
            )}
            <Label htmlFor={phoneTypeName} className='small-label'>
              <span id={`${phoneTypeName}_label`}>Phone Type</span>
            </Label>
          </div>
        )}
        <div className='country-code-container'>
          <TextInput
            defaultValue={getValues(phoneCountryCodeName)}
            id={phoneCountryCodeName}
            maxLength={3}
            inputRef={countryCodeRef}
            label='Country Code'
            name={phoneCountryCodeName}
            readOnly={readOnly}
            required={required}
            type='text'
            validationStatus={phoneCountryCodeError && 'error'}
            {...countryCodeRest}
          />
          <Label htmlFor={phoneCountryCodeName} className='small-label'>
            <span id={`${phoneCountryCodeName}_label`}>Country Code</span>
          </Label>
        </div>
        <div className='number-container'>
          <TextInput
            defaultValue={getValues(phoneName)}
            id={phoneName}
            inputRef={phoneRef}
            name={phoneName}
            readOnly={readOnly}
            required={required}
            type='text'
            validationStatus={phoneError && 'error'}
            {...phoneRest}
          />
          <Label htmlFor={phoneName} className='small-label'>
            <span id={`${phoneName}_label`}>Area Code and Phone Number</span>
          </Label>
        </div>
        {showTypeSelect && phoneTypeVal === 'Business' && (
          <div className='extension-container'>
            <TextInput
              defaultValue={getValues(extensionName)}
              id={extensionName}
              inputRef={extensionRef}
              label='Extension'
              maxLength='5'
              name={extensionName}
              readOnly={readOnly}
              required={required}
              type='text'
              validationStatus={extensionError && 'error'}
              {...extensionRest}
            />
            <Label htmlFor={extensionName} className='small-label'>
              <span id={`${extensionName}_label`}>Extension</span>
            </Label>
          </div>
        )}
      </div>
    </div>
  );
};
export default PhoneInput;
