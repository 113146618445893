import { Accordion } from '@trussworks/react-uswds';
import NAB_BALTIMORE from './DistrictContent/NAD/NAD.NAB_Baltimore';
import NAE_NEW_ENGLAND from './DistrictContent/NAD/NAD.NAE_New_England';
import NAN_NEW_YORK from './DistrictContent/NAD/NAD.NAN_New_York';
import NAO_NORFOLK from './DistrictContent/NAD/NAD.NAO_Norfolk';
import NAP_PHILADELPHIA from './DistrictContent/NAD/NAD.NAP_Philadelphia';

const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Baltimore District (NAB)',
    content: <NAB_BALTIMORE />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'New England District (NAE)',
    content: <NAE_NEW_ENGLAND />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'New York District (NAN)',
    content: <NAN_NEW_YORK />,
  },
  {
    id: '4',
    headingLevel: 'h3',
    title: 'Norfolk District (NAO)',
    content: <NAO_NORFOLK/>,
  },
  {
    id: '5',
    headingLevel: 'h3',
    title: 'Philadelphia District (NAP)',
    content: <NAP_PHILADELPHIA/>,
  },
];


const NAD = () => (
  <>
    <section id='nad'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default NAD;
