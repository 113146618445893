// PreApp Instructions - Static Helpers

import InternalLink from '@components/internal-link/InternalLink';
import SiteSelectionCriteriaTable from './SiteSelectionCriteriaTable/SiteSelectionCriteriaTable';
import Tooltip from '@components/tooltip/tooltip';
import { Accordion } from '@trussworks/react-uswds';

// Tooltip Content

const preappInstructionsTooltipContent = {
  WOTUSDelinReport: <><p>The term “delineation” of WOTUS is differentiated here from a "complete delineation report" of WOTUS. The applicant, to submit a complete Standard Permit application, is only required to provide a boundary delineation for the purpose of depicting the boundaries on project plans which help to generate meaningfully comments from agencies, Tribes, and the public during the public notice period.</p>
    <p> A "complete delineation report" includes the boundary information in addition to the scientific methods and documentation used to arrive at those boundaries. A "complete delineation report" is typically in the form of report that consists of a document or series of documents, stored in the USACE administrative record for the project, that describes the methodology used to conduct the delineation and typically includes a collection of one or more maps, datasheets, photographs, and/or figures that depict and support a legally defensible delineation of WOTUS on a property</p></>,
  practicalAlt: 'Practicable is defined as meaning the alternative is available, and capable of being done after taking into consideration cost, existing technology, and/or logistics in light of the overall project purpose(s) (40 CFR 230.3(q). Reasonable is based on consideration of the project purpose as well as technology, economics and common sense (Council on Environmental Quality Guidance 40 Most Asked Questions #2A). The Guidelines may require more substantive effort to demonstrate compliance compared to NEPA (40 CFR 230.10(a)(4)), as well as involve limitations relative to how they can be applied to determine practicability. This is further underscored by the rebuttable presumptions requiring it be clearly demonstrated by the applicant that the alternatives are not practicable compared to the applicant\'s proposed project.'
};

// Static Accordions

const guidelinesAccordion = [
  {
    id: 1,
    headingLevel: 'h3',
    title: 'Site Selection Criteria',
    content: <>
      <p className='margin-bottom-2'>An alternatives site selection criteria matrix is an effective way to present and compare the main site selection criteria that were considered during the evaluation. To allow for an objective evaluation, the comparison of the plan(s) for the proposed and alternative sites should be framed for “yes” or “no” determinations. A narrative needs to accompany the matrix defining the practicability factors chosen, the data used to support the limitations of the factor or criteria, and explanation of any “no” determinations. Practicability of the No Action alternative also must be addressed in this narrative and, if applicable, also included in the matrix. The information should explain the consequences on the applicant and the public if the project is denied, if an alternative can be implemented that does not involve discharges into <InternalLink href='/home/jurisdiction' title='WOTUS' content='WOTUS' openInNewTab />, or is an option that is outside the capability of the applicant. Any remaining alternatives that are found to be practicable will move on to the next and final step.
        The following table is provided as an example of what could be submitted with a pre-application meeting request.</p>
      <SiteSelectionCriteriaTable />
    </>
  },{
    id: 2,
    headingLevel: 'h3',
    title: 'Analyzing of each Alternative',
    content: <>
      <p><span className='text-bold'>Practicability:</span> Alternatives that are practicable are those that are available and capable of being done by the applicant after considering the following (in light of the project purpose). <span className='text-bold'>An alternative needs to fail only one practicability factor to be eliminated during the screening process:</span></p>
      <p className='margin-top-2 padding-left-2'>
        <span className='text-underline'>Costs</span>- Cost is analyzed in the context of the overall scope/cost of the project and whether it is unreasonably expensive. This determination is typically made in relation to comparable costs for similar actions in the region or analogous markets (National Policy Guidance Old Cutler Bay Associates 404(q) Permit Elevation, 13 Sep 1990.). If costs of an alternative are clearly exorbitant compared to those similar actions, and possibly the applicant's proposed action, they can be eliminated without the need to establish a cost threshold for practicability determinations. Cost is to be based on an objective, industry-neutral inquiry that does not consider an individual applicant's financial standing. The data used for any cost must be current with respect to the time of the alternatives analysis. For example, the costs associated with various infrastructure components such as roadways or utilities, including upgrades to existing infrastructure components or the need to establish new infrastructure components, may affect the viability of a particular alternative.
        A location far from all existing infrastructure (roads, water, sewer, and/or electricity) might not be practicable based on the costs associated with upgrading/establishing the infrastructure necessary to use that site. However, just because one alternative costs more than another does not mean that the more expensive alternative is impracticable. It is important to note that in the context of this definition, cost does not include economics. Economic considerations, such as job loss or creation, effects to the local tax base, or other effects a project is anticipated to have on the local economy are not part of the cost analysis;
      </p>
      <p className='padding-left-2'>
        <span className='text-underline'>Existing Technology</span>- The alternatives examined should consider the limitations of existing technology yet incorporate the most efficient/least-impacting construction methods currently available. For example, alternatives to a proposed highway that occur in unstable or dynamic soils may not be practicable due to a lack of technology to ensure the road will not crumble or collapse. Implementation of state of the art technologies might be available and should be considered if applicable. Engineered retaining walls and cantilevered road ways can also be incorporated into an alternative that substantially minimizes wetland or water impacts by eliminating fill slopes. However, it is recognized that such actions may result in the alternative being determined as impracticable due to costs; and,
      </p>
      <p className='padding-left-2'>
        <span className='text-underline'>Logistics</span>- The alternatives evaluated may incorporate an examination of various logistics associated with the project, i.e., placement of facilities within a specified distance to major thoroughfares, utilization of existing storage or staging areas, and/or safety concerns that cannot be overcome. Examples of alternatives that may not be practicable considering logistics are: no access to a major interstate or rail for manufactured goods; a piece of property is land-locked and cannot be accessed by public roads or utilities and applicant does not have condemnation authority; water supply is needed within a certain time frame and option cannot be implemented within it.
      </p>
      <p><span className='text-bold'>Availability:</span> The Guidelines state that if it is otherwise a practicable alternative, an area not presently owned by the applicant that could reasonably be obtained, utilized, expanded, or managed in order to fulfill the overall purpose of the proposed activity can still be considered a practicable alternative. In other words, the fact that an applicant does not own an alternative parcel, does not preclude that parcel from being considered as a practicable alternative. This factor is normally a consideration as a logistics and possibly cost limitation. <span className='text-bold'>The applicant should consider and anticipate alternatives available during the timeframe that the USACE conducts its alternatives analysis. In some circumstances, consideration of the timeframe when property was obtained by the applicant may influence the analysis.</span></p>
      <p className='margin-top-2 text-bold'>While not required to schedule a pre-application meeting or for the USACE to issue a Public Notice, it can be helpful to present a draft alternatives analysis during the pre-application meeting to avoid unnecessary comments during a Public Notice.</p>
    </>
  }
];

const projectPurposeNeedAccordion = [
  {
    id: 1,
    headingLevel: 'h3',
    title: 'Example 1',
    content: <>
      <p><span className='text-bold'>Too Restrictive:</span> To build a profitable 225-lot single-family residential development with 2 Olympic-sized swimming pools, 3 recreational centers and 5 sports fields at the southwest intersection of Interstate 35W and Keller-Hicks Road.</p>
      <p className='margin-top-2'>This example is too restrictive because there are no alternative sites to consider. It also unnecessarily details the exact number of lots and pools and other facilities, which unduly reduces the number of practicable and reasonable alternatives. Additionally, the profitability of the project is an inherent aspect of the project but not necessarily germane to the analysis USACE has to undertake.</p>
      <p><span className='text-bold'>Too Broad:</span> To provide residential development in Northeast Texas.</p>
      <p className='margin-top-2'>For the type of action being proposed, this example is too broad in scope if the applicant is focusing on a certain city or county to locate the project. This would also create such a large number of alternatives that evaluating them would be unwieldy.</p>
      <p><span className='text-bold'>Appropriate:</span> To provide a medium-sized single-family residential development with associated support facilities near Interstate 35W in Fort Worth, Texas, to meet local demand.</p>
      <p className='margin-top-2'>This is an appropriate overall project purpose. It clearly defines what the project involves, single-family residences, rather than “housing” which could include multi-family features such as townhouses or apartments, reflects the need to be located near a targeted major transportation corridor (which would need to be explained and supported in the needs analysis), and it defines the geographic scope to a reasonable and justified size addressing the applicant's target area of Fort Worth, TX while reflecting the public demand.</p>
    </>
  },{
    id: 2,
    headingLevel: 'h3',
    title: 'Example 2',
    content: <>
      <p><span className='text-bold'>Too Restrictive:</span> To build an economically viable 1.75-million square foot furniture warehouse facility with a 150-car parking lot and 2-acre aesthetic reflecting pond, at the Southeast corner side of I-20 in Duncanville.</p>
      <p className='margin-top-2'>As with the first example, this example is too restrictive because there are no alternative sites to consider. It also unnecessarily details the exact square footage of the building, the number of parking spaces, and includes a water feature. It is unclear why the proposed water feature would be an essential component of this project. An applicant would have to attempt to justify in the need analysis why such a feature is relevant and needed for the commercial project. Additionally, as with the first example, 4 Version 1.0, prepared by Fort Worth District Regulatory Division, November 2014 the economic viability of the project is an inherent aspect of the project but not necessarily germane to the analysis USACE has to undertake.</p>
      <p><span className='text-bold'>Too Broad:</span> To provide light industrial/commercial development in the North Central Texas.</p>
      <p className='margin-top-2'>Although the applicant may have a legitimate need to locate the project in a certain region, this example is likely too broad in scope and would also create such a large number of alternatives that evaluating them would be unwieldy.</p>
      <p><span className='text-bold'>Appropriate:</span> To provide large commercial warehouse space with access to Interstate Highway and rail line in the South Dallas area to meet regional demands.</p>
      <p className='margin-top-2'>This is an appropriate overall project purpose. It clearly defines what the project involves, commercial warehouse space, rather than the broader scope of light industrial/commercial development. The statement also specifies a legitimate need for access to both Interstate Highway and rail for transportation of goods and targets a reasonable and justifiable geographic target area of South Dallas county. The needs analysis that supports this statement will provide further details on the building size, the need for warehouse space in this growing area and will describe the specific transportation needs that drive project constraints relative to siting near both Interstate Highway and rail line to serve regional demands. The applicant's proposed overall project purpose will be carefully considered, but if the USACE cannot concur with it as submitted, the USACE is required to modify it. If the applicant has submitted an alternative analysis using a project purpose the USACE cannot concur with, (e.g., it is too restrictive, contains multiple purposes but treated as one, etc.), the analysis most likely will need to be revised to appropriately include the proper range of practicable and reasonable alternatives and/or revised alternatives screening. The applicant would be notified of the change to the definition.</p>
    </>
  }
];

export const recommendedInfoAccordion = [
  {
    id: 1, 
    headingLevel: 'h3',
    title: 'Draft Waters of the United States Delineation',
    content: <p><span className='text-bold'>While a complete delineation report</span><Tooltip name='WOTUSDelin' content={preappInstructionsTooltipContent.WOTUSDelinReport} /><span className='text-bold'> or <InternalLink href='/home/jurisdiction' title='jurisdictional determination' content='jurisdictional determination' openInNewTab /> is not required to schedule a pre-application meeting, </span>a draft delineation of <InternalLink href='/home/jurisdiction' title='WOTUS' content='WOTUS' openInNewTab /> on the project site can be very helpful during a pre-application meeting. A delineation ensures accurate quantification of impacts to WOTUS and helps the applicant clearly convey the extent and nature of the activity on their project plans to stakeholders during the pre-application meeting. It also aids the applicant in demonstrating the steps they've taken to avoid and minimize impacts to WOTUS.</p>
  },{
    id: 2, 
    headingLevel: 'h3',
    title: 'Draft Project Plans',
    content: <>
      <p className='margin-bottom-2'><span className='text-bold'>While final plans are not required to schedule a pre-application meeting, </span>draft or initial plans should be provided to help generate meaningful conversation during a pre-application meeting with stakeholders.</p>
      <p>For the USACE to issue a Public Notice, the application must include plan and elevation drawing showing the general and specific site location and character of all proposed activities, including the size relationship of the proposed structures to the size of the impacted waterway and depth of water in the area (33 CFR 325.3(a)(6)). While plans do not need to be professionally produced, they should be clear, accurate, and contain all necessary information. </p>
    </>
  },{
    id: 3, 
    headingLevel: 'h3',
    title: 'Draft Alternative Analysis',
    content: <>
      <p className='margin-bottom-2'>The USACE must evaluate alternatives that are <span className='text-bold'>practicable and reasonable.</span> In accordance with the Guidelines at 40 CFR 230.10(a), a permit cannot be issued if a <span className='text-bold'>practicable alternative</span><Tooltip name='praticalalt' content={preappInstructionsTooltipContent.practicalAlt} /> exists that would have less adverse impact on the aquatic ecosystem (known as the Least Environmentally Damaging Practicable Alternative (LEDPA), provided that the LEDPA does not have other significant adverse environmental consequences to other natural ecosystem components.</p>
      <p>The Guidelines include two rebuttable <span className='text-bold'>presumptions for projects with discharges into <InternalLink title='WOTUS' href='/home/jurisdiction' content='WOTUS' openInNewTab /></span> which involve special aquatic sites (defined at 40 CFR 240.40-45 and include wetlands, riffle pool complexes, and other specific aquatic resources), that do not require access to or siting within the special aquatic site(s) to achieve their basic essence (basic project purpose). The first presumption states that alternatives that do not affect special aquatic sites are presumed to be available. The second presumption states that practicable alternatives located in non-special aquatic sites (e.g., other waters, uplands, etc.) have less adverse impact on the aquatic ecosystem. <span className='text-bold'>It is the applicant's responsibility to clearly demonstrate to the USACE that both of these presumptions have been rebutted in order to pass the alternatives portion of the Guidelines.</span></p>
      <div className='container margin-bottom-2'>
        <Accordion bordered items={guidelinesAccordion} multiselectable={true} />
      </div>
    </>
  },{
    id: 4, 
    headingLevel: 'h3',
    title: 'Draft Project Purpose and Need',
    content: <>
      <p>Need and purpose are inter-dependent terms which are critical to the alternative analysis. They should be articulated individually since the project's purpose is framed in relation to addressing a need. <span className='text-bold'>Need is typically the problem or opportunity that the applicant is proposing to meet with their project.</span> It can normally be quantified or measured. Information collected or developed relative to project need is important in the framing of the project purpose. The evaluation of need will vary based on the type of project and will be commensurate with the magnitude of impacts and scope of the proposal. Examples can include: </p>
      <ul>
        <li>Road/highway project - safety issues/needs such as accident rates, congestion levels, regional traffic flow, level of service, etc. </li>
        <li>Commercial/Housing Development - market demands</li>
        <li>Energy project - projected increases in power use </li>
      </ul>
      <p>Based upon the need, the applicant should develop their <span className='text-bold'>project purpose</span> and clearly state it. The project purpose statement should be carefully considered and developed, as it <span className='text-bold'>will define and drive the complexity of the alternatives analysis</span>, including constraints and practicability considerations. The purpose should not be defined in such a restrictive manner to unduly restrict or preclude other alternatives, nor should it be so broad that a reasonable search of options cannot be accomplished. The applicant is to define the project purpose from their perspective. Inclusion of a geographic limit within the purpose statement is normally justified but subject to the same limits relative to unduly restricting the range of alternatives. This does not mean that site-specific projects do not occur (e.g. airport and mind expansions). Furthermore, if an analysis for alternative offsite locations is required, <span className='text-bold'>we recommend that you furnish an offsite alternative map delineating the area within which the search for alternative sites would be conducted.</span></p>
      <p className='margin-bottom-2 text-bold'>Example Project Purpose and Need (click to expand):</p>
      <div className='container margin-bottom-2'>
        <Accordion bordered items={projectPurposeNeedAccordion} multiselectable={true} />
      </div>
    </>
  },{
    id: 5, 
    headingLevel: 'h3',
    title: 'Draft Avoidance/Minimization/Compensation Statement',
    content: <p><span className='text-bold'>While not required to schedule pre-application meeting,</span> we recommend that you prepare a draft statement describing how impacts to <InternalLink href='/home/jurisdiction' title='WOTUS' content='WOTUS' openInNewTab /> are to be avoided and minimized. The application must also include a statement describing how impacts to WOTUS are to be compensated or a statement explaining why compensatory mitigation should not be required for the proposed impacts (33 CFR 325.1(d)(7)). This statement should address, the proposed avoidance and minimization and the amount, type, and location of any proposed compensatory mitigation, including any out-of-kind compensation, or indicate an intention to use an approved mitigation bank or in-lieu fee program.</p>
  },{
    id: 6, 
    headingLevel: 'h3',
    title: 'Other Laws and Policies',
    content: <>
      <p><span className='text-bold'>While not required to schedule a pre-application meeting,</span> we suggest that you furnish any reports or data you possess regarding the impact of the proposed activity on:</p>
      <ul>
        <li>Essential fish habitat</li>
        <li>Federally listed or proposed endangered species</li>
        <li>Cultural/historic properties</li>
        <li>USACE Civil Works Projects (Section 408 projects)</li>
      </ul>
    </>
  },
];