import { connect } from 'redux-bundler-react';
import { SideNav } from '@trussworks/react-uswds';
import InternalLink from '@components/internal-link/InternalLink';

import './SideNavigation.scss';
import { scrollToElement } from '@src/utils/helpers';

const SideNavigation = connect('selectRelativePathname', ({relativePathname, items, }) => {

  const createLinks = (items) => items?.map((item) => (
    <>
      <InternalLink
        className={`sidenav_link ${relativePathname === item.href ? 'usa-current' : ''}`}
        title={item?.content}
        content={item?.content}
        href={item?.href}
      />
      {item?.subSections && <SideNav items={createSubLinks(item?.subSections)} isSubnav />}
    </>
  ));

  const createSubLinks = (items) => items?.map((item) => ( 
    <InternalLink href={item?.href} content={item?.content} title={item?.content} onClick={() => scrollToElement(item?.id) } />
  ));

  return (
    <div className='sidebar'>
      <div className='margin-top-105'>
        <h6>Jump To</h6>
        <hr />
      </div>
      <SideNav items={createLinks(items)}/>
    </div>
  );

}
);

export default SideNavigation;
