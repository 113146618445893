import { Button, Icon as uswdsIcon } from '@trussworks/react-uswds';
import { mdiClose } from '@mdi/js';

import Icon from '@components/icon/Icon';

import './drawer.scss';

const DrawerHeader = ({ title, setIsOpen = () => {} }) => (
  <header aria-describedby='drawer-header' className='drawer-header margin-bottom-2' >
    <h2 className='drawer-title'><uswdsIcon.Help className='help-icon' />{title}</h2>
    <Button unstyled className='close-button' type='button' onClick={() => setIsOpen(false)}>
      <Icon focusable={false} path={mdiClose} size={'26px'}/>
    </Button>
  </header>
);

const Drawer = ({ title, content, setIsOpen = () => {}, isOpen }) => (
  <div className={`drawer-container ${isOpen ? 'open' : ''}`}>
    <div className='drawer-content' >
      <DrawerHeader title={title} setIsOpen={setIsOpen} />
      <>{content}</>
    </div>
  </div>
);

export default Drawer;
