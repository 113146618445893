import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAO_NORFOLK = () => (
  <>
    <section id='nao-norfolk'>
      <DistrictInfoTable district='NAO'/>
      <p>
      Please utilize the <ExternalLink content='Joint Permit Application (JPA)' title='Joint Permit Application (JPA)' href='https://www.nao.usace.army.mil/Missions/Regulatory/JPA.aspx' /> for permitting.
      </p>
    </section>
  </>
);

export default NAO_NORFOLK;
