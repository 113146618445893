import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';

import Breadcrumb from '@components/breadcrumb';
import PublicNoticesTable from './public-notices-table/PublicNoticesTable';
import PublicNoticesMap from './public-notices-map/PublicNoticesMap';
import PublicNoticesFilters from './PublicNoticesFilters';
import NotFound from '@pages/NotFound';
import classnames from 'classnames';

import './publicNotices.scss';
import { Button, ButtonGroup } from '@trussworks/react-uswds';

const PublicNotices = connect(
  'doClearPublicNotices',
  'doFetchPublicNotices',
  'doSetPublicNoticesView',
  'selectAuthToken', // hack for issue where no records are returned on hard-refresh/first-load (lack of auth token fails post)
  'selectPublicNotices',
  'selectPublicNoticesDistricts',
  'selectPublicNoticesItemsPerPage',
  'selectPublicNoticesPageNumber',
  'selectPublicNoticesSearchText',
  'selectPublicNoticesStates',
  'selectPublicNoticesTotalCount',
  'selectPublicNoticesView',
  'selectUserIsDeveloper',
  ({
    authToken,
    doClearPublicNotices,
    doFetchPublicNotices,
    doSetPublicNoticesView,
    publicNotices,
    publicNoticesDistricts,
    publicNoticesItemsPerPage,
    publicNoticesPageNumber,
    publicNoticesSearchText,
    publicNoticesStates,
    publicNoticesTotalCount,
    publicNoticesView,
    userIsDeveloper,
  }) => {
    const [sorting, setSorting] = useState({ column: null, order: 'asc' });
    const filterString = JSON.stringify(
      `${publicNoticesItemsPerPage}${publicNoticesPageNumber}${publicNoticesStates}${publicNoticesDistricts}${publicNoticesSearchText}`
    );
    const tableStyles = classnames({
      'display-none': publicNoticesView !== 'table'
    });
    const mapStyles = classnames({
      'display-none': publicNoticesView !== 'map'
    });

    const handleViewChange = (view) => {
      view === 'table' && doClearPublicNotices();
      doSetPublicNoticesView(view);
    };

    useEffect(() => {
      const filters = {
        itemsPerPage: publicNoticesView === 'table' ? publicNoticesItemsPerPage : undefined,
        pageNumber: publicNoticesPageNumber,
        states: publicNoticesStates,
        districtCodes: publicNoticesDistricts,
        searchText: publicNoticesSearchText,
        sortColumn: sorting?.column,
        sortOrder: sorting?.order,
      };
      authToken && doFetchPublicNotices(filters);
    }, [doFetchPublicNotices, authToken, filterString, publicNoticesView]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!userIsDeveloper) {
      return <NotFound />;
    }

    return (
      <>
        {userIsDeveloper === true ? (
          <div className='content-container'>
            <Breadcrumb pathname={'/public-notices'} href='/' />
            <h1>Public Notices</h1>
            <div className='pn-content'>
              <PublicNoticesFilters />
              <div className='pn-records'>
                <div className='pn-results-count'>Results Count: {publicNoticesTotalCount}</div>
                <ButtonGroup className='pn-view-toggle' type='segmented'>
                  <Button
                    aria-selected={publicNoticesView === 'table'}
                    className='page-control'
                    onClick={() => handleViewChange('table')}
                    outline={publicNoticesView !== 'table'}
                  >
                    Table view
                  </Button>
                  <Button
                    aria-selected={publicNoticesView === 'map'}
                    className='page-control'
                    onClick={() => handleViewChange('map')}
                    outline={publicNoticesView !== 'map'}
                  >
                    Map view
                  </Button>
                </ButtonGroup>
                <div className={tableStyles}>
                  <PublicNoticesTable publicNotices={publicNotices} setSorting={setSorting} />
                </div>
                <div className={mapStyles}>
                  <PublicNoticesMap publicNotices={publicNotices} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NotFound />
        )}
      </>
    );
  }
);

export default PublicNotices;
