import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Button, Checkbox, TextInput } from '@trussworks/react-uswds';

const FilterCheckbox = ({ item, onChange, selected, type }) => (
  <Checkbox
    checked={selected.includes(item.code)}
    className='small-checkbox pn-filter-checkbox'
    id={item.code}
    label={
      <>
        <span className='pn-filter-text'>{item.name}</span>
        <span className='pn-filter-count'>{item.count}</span>
      </>
    }
    name={item.code}
    onChange={(e) => onChange(e, type)}
    value={item.code}
  />
);

const PublicNoticesFilter = connect(
  'doGetPublicNoticesFilterCounts',
  'doSetPublicNoticesDistricts',
  'doSetPublicNoticesPageNumber',
  'doSetPublicNoticesSearchText',
  'doSetPublicNoticesStates',
  'selectPublicNoticesFilterCounts',
  ({
    doGetPublicNoticesFilterCounts,
    doSetPublicNoticesDistricts,
    doSetPublicNoticesPageNumber,
    doSetPublicNoticesSearchText,
    doSetPublicNoticesStates,
    publicNoticesFilterCounts,
  }) => {
    const [appliedDistricts, setAppliedDistricts] = useState([]);
    const [appliedSearchText, setAppliedSearchText] = useState('');
    const [appliedStates, setAppliedStates] = useState([]);
    const [enteredSearchText, setEnteredSearchText] = useState('');
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);

    const applyPending = useMemo(
      () =>
        JSON.stringify(selectedStates.sort()) !== JSON.stringify(appliedStates.sort()) ||
        JSON.stringify(selectedDistricts.sort()) !== JSON.stringify(appliedDistricts.sort()) ||
        enteredSearchText !== appliedSearchText,
      [appliedDistricts, appliedSearchText, appliedStates, enteredSearchText, selectedDistricts, selectedStates]
    );

    const hasFilters = useMemo(
      () => !!selectedStates.length || !!selectedDistricts.length || !!enteredSearchText.length,
      [enteredSearchText, selectedDistricts, selectedStates]
    );

    const handleApply = (clear = false) => {
      // 'clear' boolean is used because the selected values are not cleared until the next render - without this, the selected states contain the old values
      const newDistricts = clear ? [] : selectedDistricts;
      const newStates = clear ? [] : selectedStates;
      const newSearchText = clear ? '' : enteredSearchText;

      setAppliedDistricts(newDistricts);
      setAppliedStates(newStates);
      setAppliedSearchText(newSearchText);

      doSetPublicNoticesDistricts(newDistricts);
      doSetPublicNoticesStates(newStates);
      doSetPublicNoticesSearchText(newSearchText);
      doSetPublicNoticesPageNumber(1);

      doGetPublicNoticesFilterCounts();
    };

    const handleCheckboxChange = (e, type) => {
      const selected = type === 'state' ? selectedStates : selectedDistricts;
      const setter = type === 'state' ? setSelectedStates : setSelectedDistricts;
      const value = e.target.value;
      const newValue = selected.includes(value) ? selected.filter((item) => item !== value) : [...selected, value];

      setter(newValue);
    };

    const handleClear = () => {
      setSelectedDistricts([]);
      setSelectedStates([]);
      setEnteredSearchText('');

      !applyPending && handleApply(true);
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Enter') {
        handleApply();
      }
    };

    const handleTextChange = (e) => {
      setEnteredSearchText(e.target.value);
    };

    useEffect(() => {
      doGetPublicNoticesFilterCounts();
    }, [doGetPublicNoticesFilterCounts]);

    return (
      <div onKeyUp={handleKeyUp} className='pn-filters-container'>
        <div className='pn-filter-title'>Filter Notices</div>
        <div className={`pn-filter-buttons ${(hasFilters || applyPending) && 'open'}`}>
          <Button type='button' outline onClick={handleClear} disabled={!hasFilters}>
            Clear
          </Button>
          <Button type='button' onClick={() => handleApply()} disabled={!applyPending}>
            Apply
          </Button>
        </div>
        <div className='filters-content'>
          <div className='pn-filter-section'>
            <TextInput className='pn-filter-text' placeholder='search text' onChange={handleTextChange} value={enteredSearchText} />
          </div>
          <div className='pn-filter-section'>
            <div className='pn-filter-title'>
              States
              {!!selectedStates.length && <span className='pn-filter-section-count'> : {selectedStates.length}</span>}
            </div>
            <div className='pn-filter-section-content'>
              {publicNoticesFilterCounts?.states?.map((item) => (
                <FilterCheckbox
                  item={item}
                  key={item.code}
                  onChange={handleCheckboxChange}
                  selected={selectedStates}
                  type='state'
                />
              ))}
            </div>
          </div>
          <div className='pn-filter-section'>
            <div className='pn-filter-title'>
              Districts
              {!!selectedDistricts.length && (
                <span className='pn-filter-section-count'> : {selectedDistricts.length}</span>
              )}
            </div>
            <div className='pn-filter-section-content'>
              {publicNoticesFilterCounts?.districts?.map((item) => (
                <FilterCheckbox
                  item={item}
                  key={item.code}
                  onChange={handleCheckboxChange}
                  selected={selectedDistricts}
                  type='district'
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PublicNoticesFilter;
