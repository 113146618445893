import { ModalContent, ModalFooter } from '@components/modal';
import ReadOnlyMap from '@components/map/readOnlyMap';
import { calculateGeometryExtent } from '@src/utils/helpers';

import { connect } from 'redux-bundler-react';
import ProjectLocationSummary from '@components/project-location/ProjectLocationSummary';

const ProjectLocationModal = connect(
  'selectRequestFormData',
  'selectSelectedRequest',
  ({ requestFormData, selectedRequest, saveText = 'Close' }) => {
    const location = requestFormData?.location;

    return (
      <ModalContent hasCloseButton title={`${selectedRequest === '6' ? 'Site Location' : 'Project Location'}`}>
        <section className='modal-body'>
          <ReadOnlyMap mapOnly geometry={location?.geometry} extent={calculateGeometryExtent(location?.geometry)} />
          <ProjectLocationSummary className='mt-2' summaryData={location} />
        </section>
        <ModalFooter saveText={saveText} showCancelButton={false} />
      </ModalContent>
    );
  }
);
export default ProjectLocationModal;
