import { useEffect, useState, useMemo } from 'react';
import { connect } from 'redux-bundler-react';
import { AgGridReact } from 'ag-grid-react';
import Card from '@components/card';
import { Button } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import FieldHeader from '@forms/components/Form/FieldHeader';
import AddSupportingDocumentModal from '@forms/components/modals/AddSupportDocumentModal';
import EditSupportingDocsCellRenderer from '@forms/components/gridCellRenderers/editSupportingDocsCellRender';
import SelectInput from '@components/select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { mdiCloseOctagon, mdiDownload, mdiPlusBox } from '@mdi/js';
import ErrorSummary from '@components/error-summary/ErrorSummary';
import useErrorFocus from '@hooks/useErrorFocus';
import { ErrorMessages } from '@src/utils/enums';

export const SupportingInformationMetadata = {
  sectionName: 'Supporting Information',
  isSection: true,
  lastSection: false,
  firstSection: false,
};

const schema = yup.object().shape({
  hasSupportingDocuments: yup.string().required('Please select an option'),
});

const SupportingInformation = connect(
  'doDeleteFile',
  'doDownloadFile',
  'doDownloadZip',
  'doModalOpen',
  'doResetFileSection',
  'doUpdateSectionValidity',
  'selectSupportingDocFiles',
  'selectSteps',
  'selectActiveStep',
  'selectIsReadOnly',
  ({
    doDeleteFile,
    doDownloadFile,
    doDownloadZip,
    doModalOpen,
    doResetFileSection,
    doUpdateSectionValidity,
    supportingDocFiles,
    steps,
    activeStep,
    isReadOnly,
    componentID,
    subtext,
    stepNo,
    requestData,
    updateRequestData,
  }) => {
    const defaultValues = {
      hasSupportingDocuments: requestData?.hasSupportingDocuments !== null ? requestData?.hasSupportingDocuments : null,
    };

    const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur', defaultValues: defaultValues });
    const { formState: { errors, isValid }, watch, setError, clearErrors, trigger } = methods;
    const [tableError, setTableError] = useState(false);
    const supportingDocFileCount = supportingDocFiles?.length;

    const hasSupportingDocuments = watch('hasSupportingDocuments');

    const thisSectionStepStatus = useMemo(() => steps.find(step => step.id === stepNo)?.touched, [steps, stepNo]);

    const columnDefs = [
      {
        field: 'actions',
        headerName: 'Actions',
        width: 70,
        cellRenderer: 'editSupportingDocsCellRenderer',
        cellRendererParams: {
          doDownloadFile: doDownloadFile,
          doDeleteFile: doDeleteFile,
          isReadOnly: isReadOnly
        },
      },
      { field: 'fileName', headerName: 'File Name', flex: 1, resizable: true },
      { field: 'doclabel', headerName: 'Document Label', flex: 1, resizable: true },
      { field: 'doctype', headerName: 'Document Type', flex: 1, resizable: true },
      { field: 'doccreateddate', headerName: 'Document Created Date', flex: 1, resizable: true },
      { field: 'doccitation', headerName: 'Information Source/Citation', flex: 1, resizable: true },
    ];

    useEffect(() => {
      const valid = isValid && !tableError;
      doUpdateSectionValidity(SupportingInformationMetadata.sectionName, valid ? true : false, stepNo, isReadOnly);
    }, [isValid, tableError, doUpdateSectionValidity, stepNo, isReadOnly]);

    useEffect(() => {
      updateRequestData({ hasSupportingDocuments: hasSupportingDocuments });
      if (thisSectionStepStatus === 'true' && ((hasSupportingDocuments === 'true' || hasSupportingDocuments === true) && supportingDocFileCount < 1)) {
        setTableError(true);
        setError('supportingDocuments', { type: 'custom', message: 'Supporting Documents: At least one supporting document is required' });
      }
      else if (thisSectionStepStatus === 'true' && ((hasSupportingDocuments !== 'true' && hasSupportingDocuments !== true && hasSupportingDocuments !== 'false' && hasSupportingDocuments !== false))) {
        setTableError(true);
        trigger();
      }
      else {
        setTableError(false);
        trigger();
        clearErrors('supportingDocuments');
      }
    }, [thisSectionStepStatus, hasSupportingDocuments, supportingDocFileCount, updateRequestData, setError, clearErrors, trigger]);

    useEffect(() => {
      if (supportingDocFileCount > 0) {
        (hasSupportingDocuments === 'false' || hasSupportingDocuments === false) && doResetFileSection('Supporting Documents', true);
      }
    }, [hasSupportingDocuments, doResetFileSection, supportingDocFileCount]);

    useErrorFocus({ steps, stepNo, activeStep, trigger, noTrigger: true, isReadOnly });

    return (
      <FormProvider {...methods}>
        {errors && thisSectionStepStatus === 'true' && !isReadOnly &&
          <ErrorSummary errors={errors} sectionNo={stepNo} />
        }
        <FieldHeader
          text='Supporting Information'
          subtext={<>
            {subtext}
            <p>Please ensure the values for uploaded files only include letters, digits, white-space, and the characters, '_', '.', '/', '=', '+', '-', ':', '@'. If any characters outside of these mentioned are included in the File name or Document Label fields you will receive an error message.  To resolve the error, remove the uploaded documents with the "Clear Staged Documents" button, and then reupload the files again ensuring the file name and document labels only include the aforementioned characters.</p>
            <p className='text-bold'>{ErrorMessages.UploadNotice}</p>
          </>}
        />
        <div className='ml-2'>
          <SelectInput name='hasSupportingDocuments' label='Are there any supporting documents you will be uploading?' required className='w-50 mb-3' readOnly={isReadOnly}>
            <option key='1' value='true'>Yes</option>
            <option key='2' value='false'>No</option>
          </SelectInput>
        </div>
        {(hasSupportingDocuments === 'true' || hasSupportingDocuments === true) &&
          <>
            <Card className='mb-3' id='supportingDocuments'>
              <div className='row d-flex w-100 mt-3 pb-3 justify-content-center'>
                <div className='d-flex justify-content-center col-4'>
                  <Button
                    className='add-document-button'
                    title='Add a Document'
                    size='small'
                    onClick={() => doModalOpen(AddSupportingDocumentModal, { componentID: componentID })}
                    disabled={isReadOnly}
                  >
                    <Icon focusable={false} className='mr-1' path={mdiPlusBox} size={'16px'} />
                    Add a Document
                  </Button>
                </div>
                <div className='d-flex justify-content-center col-4'>
                  {supportingDocFiles.filter(item => item.key).length !== 0 &&
                  <Button
                    className='all-documents-button'
                    title='Download All Documents'
                    size='small'
                    onClick={() => doDownloadZip(supportingDocFiles?.map(item => {
                      const parsedArr = item?.key?.split('/');
                      return parsedArr[parsedArr.length - 1];
                    }))}
                    disabled={isReadOnly}
                  >
                    <Icon focusable={false} className='mr-1' path={mdiDownload} size={'16px'} />
                    Download All Documents
                  </Button> }
                </div>
                <div className='d-flex justify-content-center col-4'>
                  {(supportingDocFiles.filter(item => !item.key).length !== 0 && !isReadOnly) &&
                  <Button
                    className={`clear-table-button ${(supportingDocFiles.filter(item => !item.key).length === 0 || isReadOnly) ? 'disabled' : 'hover'}`}
                    title='Clear Staged Documents'
                    size='small'
                    onClick={() => doResetFileSection('Supporting Documents')}
                    disabled={supportingDocFiles.filter(item => !item.key).length === 0 || isReadOnly}
                  >
                    <Icon focusable={false} path={mdiCloseOctagon} size={'16px'} />
                    Clear Staged Documents
                  </Button> }
                </div>
              </div>
              <div className='ag-theme-balham' style={{ height: 300 }}>
                <AgGridReact
                  rowData={supportingDocFiles}
                  columnDefs={columnDefs}
                  rowHeight={35}
                  pagination={true}
                  paginationAutoPageSize={true}
                  gridOptions={{
                    alwaysShowHorizontalScroll: true,
                    alwaysShowVerticalScroll: true,
                  }}
                  components={{
                    'editSupportingDocsCellRenderer': EditSupportingDocsCellRenderer,
                  }}
                />
              </div>
            </Card>
          </>}
      </FormProvider>);
  });
SupportingInformation.metadata = SupportingInformationMetadata;

export default SupportingInformation;
