import { useFormContext } from 'react-hook-form';
import { TextInput as UswdsTextInput, Label } from '@trussworks/react-uswds';
import classNames from 'classnames';
import { isDateValid } from '@src/utils/helpers';
import './DatePicker.scss';

const DatePicker = ({
  className = 'width-full',
  hint,
  label,
  max,
  min,
  name,
  onBlur = () => { },
  onChange = () => { },
  readOnly,
  required,
  uppercase,
  ...customProps
}) => {
  const classes = classNames(className, 'date-picker-input', { 'text-uppercase': uppercase });
  // Setting the End Date
  let endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() + 100);
  endDate = new Date(endDate).toISOString().slice(0, 10);

  const { register, setValue, getValues, formState: { errors } } = useFormContext();
  const inputError = errors[name];
  const setMax = () => max ? max : endDate;

  const handleBlur = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    onBlur(e);
    // date validation
    if (!isDateValid(value, min, endDate)) {
      setValue(name, '');
    }
  };

  const handleChange = (e) => {
    onChange(e);
  };

  const {ref: datePickerRef , ...rest } = register(name, { onBlur:handleBlur, onChange:handleChange });

  const formattedDate = () => {
    const value = getValues(name);
    if (!value) return '';
    const date = new Date(value);
    return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
  };

  return (
    <div className='date-picker-container'>
      <Label htmlFor={name}>
        <span id={`${name}_label`}>{label}</span>
        {required ? <span className='asterisk-color'>*</span> : <span className='text-italic'> (optional)</span>}
      </Label>
      {hint && <div className='usa-hint' id={`${name}_hint`}>{hint}</div>}
      {readOnly ? (
        <UswdsTextInput
          className={classes}
          id={name}
          name={name}
          readOnly={readOnly}
          type={'text'}
          value={readOnly ? formattedDate() : getValues(name)}
          aria-describedby={hint ? `${name}_hint` : undefined}
          aria-required={required ? 'true' : 'false'}
        />
      ) : (
        <UswdsTextInput
          className={classes}
          defaultValue={getValues(name)}
          readOnly={readOnly}
          id={name}
          inputRef={datePickerRef}
          max={setMax()}
          min={min}
          name={name}
          required={required}
          type='date'
          validationStatus={inputError && 'error'}
          aria-invalid={inputError ? 'true' : 'false'}
          aria-describedby={hint ? `${name}_hint` : undefined}
          aria-required={required ? 'true' : 'false'}
          {...rest}
          {...customProps}
        />
      )}
    </div>
  );
};

export default DatePicker;
