import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import './filePreview.scss';

const FilePreview = ({ file, imageId, readOnly, resetFileErrors, showError }) => {
  const name = file?.name ?? file.fileName;
  const isExcel = name?.indexOf('.xls') > 0 || name?.indexOf('.numbers') > 0;
  const isPDF = name?.indexOf('.pdf') > 0;
  const isWord = name?.indexOf('.doc') > 0 || name?.indexOf('.pages') > 0;
  const isVideo = name?.indexOf('.mov') > 0 || name?.indexOf('.mp4') > 0;
  const isGeneric = !isPDF && !isWord && !isVideo && !isExcel;

  const { formState: { errors } } = useFormContext();
  const inputError = errors[name];

  const imageClasses = classNames('usa-file-input__preview-image', {
    'usa-file-input__preview-image--pdf': isPDF,
    'usa-file-input__preview-image--word': isWord,
    'usa-file-input__preview-image--video': isVideo,
    'usa-file-input__preview-image--excel': isExcel,
    'usa-file-input__preview-image--generic': isGeneric,
  });

  const previewClasses = classNames('usa-file-input__preview', {
    'file-input-disabled': readOnly 
  },{
    'file-input-invalid': (showError || inputError) && resetFileErrors === false
  });
  
  return (
    <div id={imageId} className={previewClasses} aria-hidden='true'>
      <div className={imageClasses} />
      {name}
    </div>
  );
};

export default FilePreview;
