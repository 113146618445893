import PNMap from '@components/map/PNMap';

const PublicNoticesMap = ({publicNotices}) => {

  const points = publicNotices?.map(notice => ({
    type: 'Point',
    coordinates: [notice.longitude, notice.latitude],
    properties: {
      ...notice
    }
  }));

  return (
    <div className='pn-map-container'>
      <PNMap geometry={points}  />
    </div>
  );

};

export default PublicNoticesMap;
