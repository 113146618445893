import React, { useState } from 'react';
import { connect } from 'redux-bundler-react';
import { Icon } from '@trussworks/react-uswds';

import './MaintenanceBanner.scss';

const MaintenanceBanner = connect(
  'selectBannerData',
  ({
    bannerData,
    isDismissible = false,
  }) => {
    const [showAlert, setShowAlert] = useState(true);

    const handleCloseAlert = () => {
      setShowAlert(false);
    };

    return (
      <>
        <div className='App maintenance'>
          {(showAlert && bannerData?.isBannerOn === true) && (
            <div className='accordion-wrapper'>
              <div className='mx-banner-body pt-1 pb-1'>
                <div className='row'>
                  <div className='col-1 left center'></div>
                  <div className='col-10 center'>
                    <div className='usa-alert__text'>
                      <Icon.Error className='mx-banner-icon warning-icon mb-1' />
                      {bannerData?.banner}
                    </div>
                  </div>
                  {isDismissible && <div className='col-1 center'>
                    <Icon.Cancel className='mx-banner-icon dismiss-icon ml-auto ml-r' onClick={handleCloseAlert} />
                  </div>}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  });

export default MaintenanceBanner;