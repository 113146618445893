import { useEffect } from 'react';
import { ModalContent } from '@components/modal';
import PublicNoticeComment from './PublicNoticeComment';
import { connect } from 'redux-bundler-react';

const PublicNoticeInfoModal = connect(
  'doModalClose',
  'doFetchPublicNoticeById',
  'selectPublicNoticeById',
  ({ doModalClose, doFetchPublicNoticeById, publicNoticeById, publicNoticeId, }) => {
    useEffect(() => {
      doFetchPublicNoticeById(publicNoticeId);
    }, [doFetchPublicNoticeById, publicNoticeId]);

    return (
      <ModalContent title='Submit Public Notice Comments' hasCloseButton={false}>
        <div className='modal-body'>
          {!publicNoticeById.publicNoticeID && <div>Loading...</div>}
          <PublicNoticeComment data={publicNoticeById} onClose={(doModalClose)} />
        </div>
      </ModalContent>
    );
  }
);

export default PublicNoticeInfoModal;
