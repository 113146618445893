import { Accordion } from '@trussworks/react-uswds';
import MVK_VICKSBURG from './DistrictContent/MVD/MVD.MVK_Vicksburg';
import MVN_NEW_ORLEANS from './DistrictContent/MVD/MVD.MVN_New_Orleans';
import MVP_ST_PAUL from './DistrictContent/MVD/MVD.MVP_St_Paul';

const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Vicksburg District (MVK)',
    content: <MVK_VICKSBURG />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'New Orleans District (MVN)',
    content: <MVN_NEW_ORLEANS />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'St. Paul District (MVP)',
    content: <MVP_ST_PAUL/>,
  },
];


const MVD = () => (
  <>
    <section id='mvd'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default MVD;
