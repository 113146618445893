import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { isDateRangeValid, isDateValid } from '../../../utils/helpers';

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const PermitDateTableCell = ({
  getValue,
  row,
  column,
  table,
  cell,
}) => {
  const columnMeta = column.columnDef.meta;
  const columnAccessorKey = column.columnDef.accessorKey;
  const type = columnMeta?.type ?? 'date';
  const tableMeta = table.options.meta;
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const rowApprovedDate = useMemo(() => row.getValue('approvedDate'), [row]);
  const rowDeniedDate = useMemo(() => row.getValue('deniedDate'), [row]);
  const rowAppliedDate = useMemo(() => row.getValue('appliedDate'), [row]);

  // @TODO: Workaround to rerender the cell - generates console warning for invalid formatting
  const noVal = '0000-00-00';

  // Setting the Max Date
  const endDate = new Date().toISOString().slice(0, 10);

  const debouncedUpdateRef = useRef();

  useEffect(() => {
    debouncedUpdateRef.current = debounce((newValue) => {
      if (tableMeta?.updateData) {
        tableMeta?.updateData(row.index, column.id, type === 'number' ? Number(newValue) : newValue ?? newValue);
      }
    }, 500);
  }, [row.index, column.id, tableMeta?.updateData, type, tableMeta]);

  const updateValue = useCallback((newValue) => {
    debouncedUpdateRef.current(newValue);
  }, []);

  const handleBlur = (e) => {
    // Check if date value is valid
    if (isDateValid(value, columnMeta?.min, endDate) === false) {
      setValue(noVal);
      updateValue('');
    }
    // Before date cannot be after the After date
    if (columnAccessorKey === 'appliedDate' && (rowApprovedDate || rowDeniedDate)) {
      if (isDateRangeValid(new Date(e?.target?.value), rowApprovedDate ?? rowDeniedDate) === false) {
        setValue(noVal);
        updateValue('');
      }
    }
    if ((columnAccessorKey === 'approvedDate' || columnAccessorKey === 'deniedDate') && rowAppliedDate) {
      if (isDateRangeValid(rowAppliedDate, new Date(e?.target?.value)) === false) {
        setValue(noVal);
        updateValue('');
      }
    }
  };

  const handleChange = (e) => {
    setValue(e?.target?.value);
    updateValue(e?.target?.value);
  };

  const isDisabled = (column.id === 'approvedDate' && (rowDeniedDate && rowDeniedDate !== '0000-00-00')) || (column.id === 'deniedDate' && (rowApprovedDate && rowApprovedDate !== '0000-00-00'));

  const cursorStyle = columnMeta?.readOnly || isDisabled ? { cursor: 'not-allowed' } : {};

  return (
    <input
      aria-label={'Date Applied'}
      disabled={isDisabled}
      id={cell.id}
      max={endDate}
      maxLength={columnMeta?.maxLength}
      onBlur={handleBlur}
      onChange={handleChange}
      readOnly={columnMeta?.readOnly}
      required={columnMeta?.required}
      style={{ width: '100%', ...cursorStyle }}
      type={type}
      value={value ?? ''}
    />
  );
};