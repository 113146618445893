import React from 'react';
import { connect } from 'redux-bundler-react';

import { HeaderCard, FormCard } from './components/dashboard-cards';
import Resources from '../Resources';

import './home.scss';

const Home = connect(
  'selectIsUserRoleValid',
  ({
    isUserRoleValid
  }) => (
    <>
      {isUserRoleValid === true ? (
        <>
          <div className='home-container'>
            <div>
              <HeaderCard />
            </div>
            <FormCard />
          </div>
        </>
      ) : (
        <Resources />
      )}
    </>
  ));

export default Home;
