const antiSpamBundle = {
  name: 'antispam',

  getReducer: () => {
    const initialState = {
      isValid: false
    };

    return (state = initialState, { type, payload }) => {
      switch (type) {
        case 'UPDATE_ANTISPAM_RESULTS':
          return { isValid: payload };
        default:
          return state;
      }
    };
  },

  selectAntispamResults: (state) => state.antispam.isValid,

  doSetAntispamResults: (payload) => ({ dispatch }) => {
    dispatch({ type: 'UPDATE_ANTISPAM_RESULTS', payload });
  },

};
export default antiSpamBundle;
