import React from 'react';
import { connect } from 'redux-bundler-react';

import Breadcrumb from '../../app-components/breadcrumb/index.js';
import GeneratePDF from './components/GeneratePDF.jsx';
import NotFound from '../NotFound/index.jsx';
import BannerSettings from './components/BannerSettings.jsx';
import ManageVersion from './components/ManageVersion.jsx';

const DeveloperDashboard = connect(
  'selectUserIsDeveloper',
  ({
    userIsDeveloper
  }) => (
    <>
      {userIsDeveloper === true ? (
        <>
          <div className='container'>
            <Breadcrumb pathname='RRS Developer Dashboard' href='/home' hrefText='Home' />
            <h1>RRS Developer Dashboard</h1>
            <div className='row'>
              <div className='col mt-2'>
                <div className='card general-support-card'>
                  <div className='card-body'>
                    <h4 className='card-title'>Manual PDF Generation</h4>
                    <p className='card-text'>Regenerate submitted requests PDFs by providing the Project ID, Request ID, and Version in the field below.</p>
                    <GeneratePDF />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mt-4'>
                <div className='card office-support-card'>
                  <div className='card-body'>
                    <h4 className='card-title'>Maintenance Banner Control Center</h4>
                    <p className='card-text'>Post and/or enable/disable maintenance banner</p>
                    <p className='card-subtitle mb-2 ml-2'>Example:</p>
                    <p className='card-subtitle mb-2 ml-2'>The Regulatory Request System (RRS) will be down for scheduled maintenance on 7/3/2024 starting at 6:00 P.M. Eastern Standard Time. Estimated downtime will be approximately 15-30 minutes.</p>
                    <BannerSettings />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mt-4'>
                <div className='card feedback-card'>
                  <div className='card-body'>
                    <h4 className='card-title'>Manual Version Number Update</h4>
                    <p className='card-text'>Manually update RRS release version number</p>
                    <ManageVersion />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mt-4'>
                <div className='card logingov-card'>
                  <div className='card-body'>
                    <h4 className='card-title'>External API Statuses</h4>
                    <p className='card-subtitle mb-2 ml-2'>** Under Construction **</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>) : <NotFound />}
    </>
  ));

export default DeveloperDashboard;
