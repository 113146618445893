const addressValidationBundle = {
  name: 'addressvalidation',
  getReducer: () => {
    const initialData = {
      validAddress: null,
      enteredAddress: null,
      error: null,
      isValid: null,
    };

    function areEquivalentAddresses(validAddress, enteredAddress, keyMappings) {
      if (!validAddress || !enteredAddress) {
        return false;
      }
      for (const validKey in keyMappings) {
        const enteredKey = keyMappings[validKey];
        if (
          validAddress[validKey] !== undefined &&
          enteredAddress[enteredKey] !== undefined &&
          validAddress[validKey].toLowerCase() !== enteredAddress[enteredKey].toLowerCase()
        ) {
          return false;
        }
      }
      return true;
    }

    const keyMappings = {
      Address2: 'address',
      Address1: 'addressTwo',
      City: 'city',
      State: 'state',
      Zip5: 'zipcode'
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'VALIDATING_ADDRESS_FROM_ADDRESS_NAME') {
        let valid;

        const validAddress = payload?.response?.AddressValidateResponse?.Address ?? null;
        const enteredAddress = payload?.data ?? null;

        const isValid = areEquivalentAddresses(validAddress, enteredAddress, keyMappings);
        if (isValid) {
          valid = true;
        }
        else {
          valid = false;
        }

        return {
          ...state,
          validAddress: payload?.response?.AddressValidateResponse?.Address ?? null,
          enteredAddress: payload.data,
          error: payload?.response?.data?.Error?.Description ?? null,
          isValid: valid
        };

      }
      else {
        return state;
      }
    };
  },
  doValidateAddress:
    (data) =>
      ({ dispatch, apiPost }) => {
        const uri = '/api/USPSAddress/getAddress';
        const skipAuth = true;

        apiPost(uri, data, (err, body) => {
          if (body) {
            dispatch({
              type: 'VALIDATING_ADDRESS_FROM_ADDRESS_NAME',
              payload: { response: body.data, data: data }
            });
          }
          if (err) {
            dispatch({ type: 'REVERSE_GEOCODE_FETCH_ERROR', payload: err });
          }

        }, skipAuth);

        // fetch(
        //   'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?' +
        //   new URLSearchParams({
        //     f: 'json',
        //     address: ADDRESS,
        //     token: ACCESS_TOKEN,
        //     outFields: '*',
        //   })
        // )
        //   .then((data) => data.text())
        //   .then((text) => {
        //     dispatch({
        //       type: 'VALIDATING_ADDRESS',
        //       payload: text,
        //     });
        //   })
        //   .catch((err) => console.log(err));
      },
  selectAddressValidationResults: (state) => state.addressvalidation,
};

export default addressValidationBundle;
