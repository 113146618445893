import { states } from '../input-forms/_helper.jsx';

import { PhoneTypes } from '@src/utils/enums';
import { emailRegex, zipCodeRegex, cityRegex, phoneExtRegex, phoneRegex } from '@src/utils/regex.jsx';

export const validatePermitteeRespMitigations = (data) => {
  let resourcesValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <ol>
      {errors.map((error, i) => <><li key={i}>{error}</li><br /></>)}
    </ol>
  );

  const isBlank = (val) => !val;

  const requiredFields = [
    'Waters_Name',
    'Name',
    'Mitigation_Type',
    'Permittee_Responsible_Type',
    'Resource_Type',
    'Proposed_Length',
    'Proposed_Width',
    'Proposed_Amount',
    'Amount_Units',
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`Mit-PermitteeResp: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        resourcesValid = false;
      }
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

};

export const validateBankILFMitigations = (data) => {
  let resourcesValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <ol>
      {errors.map((error, i) => <><li key={i}>{error}</li><br /></>)}
    </ol>
  );

  const isValidUnit = (val, unit) =>
    (val === 'In-Lieu Fee' && (unit === 'Acre Based' || unit === 'Linear Feet Based' || unit === 'Advanced Credit' || unit === 'Assessment Based')) ||
    (val === 'Mitigation Bank' && (unit === 'Acre Based' || unit === 'Linear Feet Based' || unit === 'Advanced Credit'));


  const isBlank = (val) => !val;

  const requiredFields = [
    'Waters_Name',
    'Name',
    'Mitigation_Type',
    'Bank_OR_Program_Name',
    'Credit_Unit',
    'Proposed_Credits',
    'Required_Credits',
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`MitBank_ILF: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        resourcesValid = false;
      }
    }

    if (!isValidUnit(row.Mitigation_Type, row.Credit_Unit)) {
      errorList.push(`MitBank_ILF: row ${i + 2} - Credit_Unit is invalid for Mitigation_Type!`);
      resourcesValid = false;
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: resourcesValid, msg: resourcesValid === false ? JSXlistToReturn : null };

};

export const validatePropertyOwners = (data) => {
  let propOwnersValid = true;
  let errorList = [];

  const formatErrorList = (errors) => (
    <>
      <h6>{errors.length} errors found!</h6>
      <ul>
        {errors.map((error, i) => <><li key={i}>{error}</li><br key={`br${i}`} /></>)}
      </ul>
    </>
  );

  const isBlank = (val) => !val;
  const isValidPhoneType = (type) => isBlank(type) || Object.values(PhoneTypes).includes(type);
  const isValidPhoneNumber = (phone) => isBlank(phone) || phoneRegex.test(phone);
  const isValidPhoneNumberExt = (phone) => isBlank(phone) || phoneExtRegex.test(phone);
  const isValidZipcode = (val) => zipCodeRegex.test(val);
  const isValidState = (val) => states.some(state => state.value === val);
  const isValidCity = (val) => cityRegex.test(val);
  const isValidEmail = (val) => emailRegex.test(val);
  const isValidExt = (ext) => (isBlank(ext) || phoneExtRegex.test(ext));



  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'city',
    'state',
    'zipcode',
    'phoneOneType',
    'phoneOne',
    'emailAddress'
  ];

  data.forEach((row, i) => {

    for (const field of requiredFields) {
      if (isBlank(row[field])) {
        errorList.push(`Property Owner: row ${i + 2} - ${field} is a required field and cannot be blank!`);
        propOwnersValid = false;
      }
    }
    if (!isValidPhoneType(row.phoneOneType)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneType is not a valid phone type!`);
      propOwnersValid = false;
    }
    if (!isValidPhoneNumber(row.phoneOne)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOne is not a valid phone number!`);
      propOwnersValid = false;
    }
    if (!isValidPhoneNumberExt(row.phoneOneExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneExtension is not a valid extension!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumber(row.phoneTwo)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwo is not a valid phone number!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumberExt(row.phoneTwoExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwoExtension is not a valid extension!`);
      propOwnersValid = false;
    }

    if (!isValidPhoneNumber(row.faxPhone)) {
      errorList.push(`Property Owner: row ${i + 2} - faxPhone is not a valid phone number!`);
      propOwnersValid = false;
    }

    if (!isValidZipcode(row.zipcode)) {
      errorList.push(`Property Owner: row ${i + 2} - zipcode is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidCity(row.city)) {
      errorList.push(`Property Owner: row ${i + 2} - city is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidState(row.state)) {
      errorList.push(`Property Owner: row ${i + 2} - state is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidEmail(row.emailAddress)) {
      errorList.push(`Property Owner: row ${i + 2} - emailAddress is not valid!`);
      propOwnersValid = false;
    }

    if (!isValidExt(row.phoneOneExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneOneExtension is not valid!`);
      propOwnersValid = false;
    }
    if (!isValidExt(row.phoneTwoExtension)) {
      errorList.push(`Property Owner: row ${i + 2} - phoneTwoExtension is not valid!`);
      propOwnersValid = false;
    }

  });

  const JSXlistToReturn = formatErrorList(errorList);

  return { valid: propOwnersValid, msg: propOwnersValid === false ? JSXlistToReturn : null, status: propOwnersValid ? 'Success' : 'VALIDATION FAILED!' };
};
