const formViewBundle = {
  name: 'formview',
  getReducer: () => {
    const initialData = {
      title: '',
      firstname: '',
      middlename: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FORM_UPDATED') {
        return Object.assign({}, state);
      }

      if (type === 'VIEW_ADDRESS_RESET') {
        return {
          ...state,
          address1: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
        };
      }

      if (type === 'VIEW_NAME_RESET') {
        return {
          ...state,
          title: '',
          firstname: '',
          middlename: '',
          lastname: '',
        };
      }

      if (type === 'VIEW_ADDRESS_UPDATED') {
        switch (payload.name) {
          case 'address1':
            return { ...state, address1: payload.value };
          case 'address2':
            return { ...state, address2: payload.value };
          case 'city':
            return { ...state, city: payload.value };
          case 'state':
            return { ...state, state: payload.value };
          case 'zipcode':
            return { ...state, zipcode: payload.value };

          default:
            return state;
        }
      }

      return state;
    };
  },
  doUpdateViewAddressField:
    (props) =>
      ({ dispatch }) => {
        dispatch({
          type: 'VIEW_ADDRESS_UPDATED',
          payload: {
            name: props.name,
            value: props.value,
          },
        });
      },
  doResetViewAddress:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'VIEW_ADDRESS_RESET',
        });
      },
  doResetViewName:
    () =>
      ({ dispatch }) => {
        dispatch({
          type: 'VIEW_NAME_RESET',
        });
      },
  selectFieldViewAddress1: (state) => state.formview.address1,
  selectFieldViewAddress2: (state) => state.formview.address2,
  selectFieldViewCity: (state) => state.formview.city,
  selectFieldViewState: (state) => state.formview.state,
  selectFieldViewZipcode: (state) => state.formview.zipcode,
};

export default formViewBundle;