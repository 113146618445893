import React, { useState, useEffect } from 'react';

import { connect } from 'redux-bundler-react';

const JDTypeTableCell = connect(
  'selectJDData',
  ({
    jDData,
    getValue,
    row,
    column,
    table,
    cell,
  }) => {
    const columnMeta = column.columnDef.meta;
    const tableMeta = table.options.meta;
    const initialValue = getValue();
    const initialJDType = columnMeta?.initialJDType ?? null;
    const [value, setValue] = useState(initialValue ?? null);

    useEffect(() => {
      initialJDType && setValue(initialJDType);
    }, [initialJDType]);

    useEffect(() => {
      const currentValue = getValue();
      const currentJDType = jDData?.jurisdictionalDetermination;
      if (currentJDType === 'Preliminary+Approved') {
        currentValue === 'No JD Requested' && setValue(null);
        currentValue === 'No JD Requested' && tableMeta?.updateData(row.index, column.id, null);
      }
      else {
        currentValue !== currentJDType && setValue(currentJDType);
        currentValue !== currentJDType && tableMeta?.updateData(row.index, column.id, currentJDType);
      }
    }, [jDData?.jurisdictionalDetermination, column.id, getValue, row.index, tableMeta]);

    const handleBlur = (e) => {
    };

    const handleChange = (e) => {
      setValue(e.target.value);
      tableMeta?.updateData(row.index, column.id, e.target.value);
    };

    return (
      <select
        aria-label={'JD Type'}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={columnMeta?.readOnly}
        id={cell.id}
        required={columnMeta?.required}
        style={{ width: '100%' }}
        value={value ?? ''}>
        <option key={0} value='' style={{ display: 'none' }}>-- Select a value --</option>
        {columnMeta?.options?.map((option) => (
          <option key={option.value} value={option.value} title={option.label}>{option.label}</option>
        ))}
      </select>
    );
  }
);

export default JDTypeTableCell;