import React, { useEffect } from 'react';
import { connect } from 'redux-bundler-react';
import { useFormContext } from 'react-hook-form';

import { TextInput as UswdsTextInput, Label } from '@trussworks/react-uswds';

import classNames from 'classnames';
import Tooltip from '@components/tooltip/tooltip';
import genericSecondaryModal from '@forms/components/modals/genericSecondaryModal';

import { ErrorMessages } from '@src/utils/enums';
import { sanitizeASCII, isValidASCII, isDateValid } from '@src/utils/helpers';
import { decimalNumberRegex } from '@src/utils/regex';

import './TextInput.scss';

const TextInput = connect(
  'doSecondaryModalOpen',
  ({
    doSecondaryModalOpen,
    className = 'width-full',
    hint,
    showOptionalText = true,
    label,
    max,
    type = 'text',
    maxLength = type === 'text' ? 256 : null,
    min,
    name,
    onBlur = () => { },
    onChange = () => { },
    pattern,
    readOnly,
    required,
    signature,
    tooltip,
    tooltipClickable,
    uppercase,
    validations,
    valueAsDate = false,
    valueAsNumber = false,
    ...customProps
  }) => {
    const classes = classNames(className, { 'text-uppercase': uppercase, });
    // Setting the End Date
    var endDate = new Date();
    endDate.setFullYear(endDate.getFullYear() + 100);
    endDate = new Date(endDate).toISOString().slice(0, 10);

    const {register, watch, setValue, getValues, formState: { errors } } = useFormContext();
    const inputError = errors[name];
    const input = watch(name);

    useEffect(() => {
      if (input && typeof input === 'string') {
        if (isValidASCII(input)) {
          setValue(name, input);
        } else {
        // Optionally handle invalid input here, such as warning the user
          const msg = ErrorMessages.InvalidCharacters;
          doSecondaryModalOpen(genericSecondaryModal, { title: 'Invalid Characters', msg: msg });
          setValue(name, sanitizeASCII(input));
        }
      }
    }, [input, name, setValue, doSecondaryModalOpen]);

    const handleBlur = (e) => {
      const name = e?.target?.name;
      const value = e?.target?.value;
      onBlur(e);
      // Input type validations
      if (type === 'date') {
        isDateValid(value, min, endDate) === false && setValue(name, '');
      }
      if (type === 'number' || valueAsNumber) {
        if (decimalNumberRegex.test(e?.target?.value) !== true) {
        // Parse out any letters and extra periods from int/float value
          const parsedLettersVal = e?.target?.value?.replace(/[^0-9.]/g, '');
          const rx = /^(\d*\.)([\d.]*)$/;
          const parsedPeriodsVal = parsedLettersVal?.replace(rx, (a, b, c) => b + c?.replace(/\./g, ''));
          setValue(name, parsedPeriodsVal);
        }
      }
    };

    const handleChange = (e) => {
      onChange(e);
    };

    const setMax = () => max ? max : endDate;

    const {ref: textInputRef , ...rest } = register(name, {onBlur:handleBlur, onChange:handleChange, pattern, ...validations});

    return (
      <>
        <Label htmlFor={name}>
          <span id={`${name}_label`}>{label}</span>
          {required ? (<span className='asterisk-color'>*</span>) : (showOptionalText && <span className='text-italic'> (optional)</span>)}
          {tooltip && <Tooltip clickable={tooltipClickable} content={tooltip} header={label} iconStyle={{ marginLeft: '5px' }} name={name} /> }
        </Label>
        {hint && <div className='usa-hint' id={`${name}_hint`}>{hint}</div>}
        <UswdsTextInput
          className={classes}
          defaultValue={getValues(name)}
          readOnly={readOnly}
          id={name}
          inputRef={textInputRef}
          max={type === 'date' ? setMax() : null}
          maxLength={maxLength}
          min={type === 'date' ? min : null}
          name={name}
          required={required}
          type={type}
          validationStatus={inputError && 'error'}
          {...rest}
          {...customProps}
        />
        {signature && <div className='usa-hint margin-bottom-2 padding-left-1 text-italic'>{signature}</div>} 
      </>
    );
  });

export default TextInput;
