import ExternalLink from '@components/external-link/ExternalLink';
import InternalLink from '@components/internal-link/InternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const SPN_SAN_FRANCISCO = () => (
  <>
    <section id='spn-san-francisco'>
      <DistrictInfoTable district='SPN' orgEmail='dll-spn-dmmo@usace.army.mil'/>
      <p>
      For those seeking dredging permits from the San Francisco District, applicants should use the <ExternalLink content='Consolidated Dredging-Dredged Material Reuse-Disposal Application' title='Consolidated Dredging-Dredged Material Reuse-Disposal Application' href='https://www.spn.usace.army.mil/Missions/Dredging-Work-Permits/Application/' />.  Completed dredging applications can be submitted by email to <InternalLink content='dll-spn-dmmo@usace.army.mil' title='dll-spn-dmmo@usace.army.mil' href='mailto:dll-spn-dmmo@usace.army.mil' />.
      </p>
    </section>
  </>
);

export default SPN_SAN_FRANCISCO;
