import { Accordion } from '@trussworks/react-uswds';
import SAA_CARIBBEAN from './DistrictContent/SAD/SAD.SAA_Caribbean';
import SAM_MOBILE from './DistrictContent/SAD/SAD.SAM_Mobile';
import SAW_WILMINGTON from './DistrictContent/SAD/SAD.SAW_Wilmington';


const USACEDistrictAccordion = [
  {
    id: '1',
    headingLevel: 'h3',
    title: 'Caribbean District (SAA)',
    content: <SAA_CARIBBEAN />,
  },
  {
    id: '2',
    headingLevel: 'h3',
    title: 'Mobile District (SAM)',
    content: <SAM_MOBILE />,
  },
  {
    id: '3',
    headingLevel: 'h3',
    title: 'Wilmington District (SAW)',
    content: <SAW_WILMINGTON />,
  },
];


const SAD = () => (
  <>
    <section id='sad'>
      <h6 className='margin-bottom-1'>Regulatory Districts</h6>
      <Accordion bordered items={USACEDistrictAccordion} multiselectable={true}  />
    </section>
  </>
);

export default SAD;
