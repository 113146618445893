const secondaryModalBundle = {
  name: 'secondarymodal',
  getReducer: () => {
    const initialData = {
      content: null,
      props: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SECONDARY_MODAL_UPDATED') {
        return Object.assign({}, state, payload);
      }
      return state;
    };
  },
  doSecondaryModalOpen: (content, props) => ({ dispatch }) => {
    dispatch({
      type: 'SECONDARY_MODAL_UPDATED',
      payload: {
        content: content,
        props: props,
      },
    });
  },
  doSecondaryModalClose: () => ({ dispatch }) => {
    dispatch({
      type: 'SECONDARY_MODAL_UPDATED',
      payload: {
        content: null,
        props: null,
      },
    });
  },
  selectSecondaryModalContent: (state) => state.secondarymodal.content,
  selectSecondaryModalProps: (state) => state.secondarymodal.props,
};

export default secondaryModalBundle;
