import { Icon } from '@trussworks/react-uswds';
import './secondaryModal.scss';

const ModalHeader = ({ title = '', hasIcon = false, isError = false }) => {
  const DisplayIcon = ({ isError }) =>
    isError ? (
      <Icon.Error className='secondary-modal-icon' color='#D43929' />
    ) : (
      <Icon.Help className='secondary-modal-icon' color='#005ea2' />
    );

  return (
    <header id='secondary-modal-header' className='secondary-modal-header'>
      <h3 className='modal-title'>
        {hasIcon && <DisplayIcon isError={isError} />}
        {title}
      </h3>
    </header>
  );
};

export default ModalHeader;
