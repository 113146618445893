import React from 'react';

import NewSectionCard from '../components/_shared/NewSectionCard';

import ribitsIcon from '../../../styles/images/icon-RIBITS.png';
import { SectionHeader } from './_shared/helper.jsx';

const Mitigation = () => (
  <>
    <section id='mitigation-info-id'>
      <SectionHeader>Mitigation Information</SectionHeader>
      <NewSectionCard
        title='Final Compensatory Mitigation Rule'
        content='Learn more about the Final Compensatory Mitigation Rule, related documents and resources, and district specific mitigation information.'
        url={'https://www.usace.army.mil/Missions/Civil-Works/Regulatory-Program-and-Permits/mitig_info/'}
        buttonText='Mitigation Rule'
        buttonTitle='MitigationRule'
        external
      />
    </section>

    <section id='ribits-id' className='mt-3'>
      <SectionHeader>Regulatory in Lieu Fee and Bank Information Tracking System (RIBITS)</SectionHeader>
      <p>RIBITS was developed by the U.S. Army Corps of Engineers to track mitigation banking and in-lieu fee (ILF) program activities across the United States. RIBITS includes information regarding banks and ILF program sites, associated documents, mitigation credit availability, service areas, and policies and procedures that affect bank and ILF program development and operation.</p>
      <p>With support from the U. S. Environmental Protection Agency, U. S. Fish and Wildlife Service, NOAA-National Marine Fisheries Service (NOAA-NMFS), and Federal Highway Administration, RIBITS has grown to include conservation banking and multi-agency banking activities. Support from the U. S. Department of Agriculture allows RIBITS to track water-quality trading activities in
        multiple states, with pilot projects in the states of Virginia and Iowa, and additional support from NOAA-NMFS allows RIBITS to track credits for restoration banks recognized under Natural Resource Damage Assessment statutes.</p>
      <p>Upcoming modifications include a mitigation banking module for RIBITS newest partner agency, the Bureau of Land Management. Hosted by CWBI (Civil Works Business Intelligence).</p>
      <div className='row'>
        <div className='col-4'>
          <img src={ribitsIcon} alt='RIBITS icon' style={{ width: '100%', height: '200px' }} />
        </div>
        <div className='col-8'>
          <NewSectionCard
            title='RIBITS'
            content='Navigate and explore RIBITS here.'
            url='https://ribits.ops.usace.army.mil/ords/f?p=107:2::::::'
            buttonText='RIBITS'
            buttonTitle='ribits'
            external
          />
        </div>
      </div>
    </section>
  </>
);

export default Mitigation;
