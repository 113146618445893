import React from 'react';

import NewSectionCard from '../components/_shared/NewSectionCard';

import { SectionHeader } from './_shared/helper.jsx';

import '../resources.scss';

const Announcements = () => (
  <>
    <section id='regulatory-announcements-id'>
      <SectionHeader>Regulatory Announcements</SectionHeader>
      <p>From the Headquarters homepage, view any recent announcements related to the Regulatory Program.</p>
      <NewSectionCard
        title='Recent Announcements'
        content='View recent regulatory announcements about the Regulatory Program and Permits.'
        url='https://www.usace.army.mil/missions/civil-works/regulatory-program-and-permits/'
        buttonText='Announcements'
        buttonTitle='Announcements'
        external
      />
    </section>
  </>
);

export default Announcements;
