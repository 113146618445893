import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const LRP_PITTSBURGH = () => (
  <>
    <section id='lrp-pittsburgh'>
      <DistrictInfoTable district='LRP'/>
      <p>
 Please do not use RRS to apply for a permit in Pennsylvania.  Please continue to submit Water Obstruction and Encroachment Joint Permit Applications to the appropriate Pennsylvania Department of Environmental Protection Regional Office.  The  <ExternalLink content='Pittsburgh District website' title='Pittsburgh District website' href='https://www.lrp.usace.army.mil/Missions/Regulatory/' /> contains more information on obtaining a Department of the Army permit and the joint application form.
      </p>
    </section>
  </>
);

export default LRP_PITTSBURGH;
