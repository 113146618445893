import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAN_NEW_YORK = () => (
  <>
    <section id='nan-new-york'>
      <DistrictInfoTable district='NAN'/>
      <p>
      Please do not use RRS to apply for a permit in New York. The Buffalo and New York Districts employ a <ExternalLink content='joint permit application process' title='Joint Permit Application Process' href='https://www.lrd.usace.army.mil/Wetlands-Permits/Article/3646979/new-york-state-regulatory-program/' /> with New York State.  
      </p>
    </section>
  </>
);

export default NAN_NEW_YORK;
