import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const MVN_NEW_ORLEANS = () => (
  <>
    <section id='mvn-new-orleans'>
      <DistrictInfoTable district='MVN'/>
      <p>
      Those seeking a wetland permit in the coastal zone of Louisiana should use the <ExternalLink content='joint permit application form' title='Joint Permit Application Form' href='https://www.dnr.louisiana.gov/index.cfm?md=pagebuilder&tmp=home&pid=93' />.  
      </p>
      <p>
      Once information is submitted to the appropriate State agency, the State will send the permit application electronically via email to the correct district.  The <ExternalLink content='New Orleans District Website' title='New Orleans District Website' href='https://www.mvn.usace.army.mil/Missions/Regulatory/Permits/' /> contains more information on obtaining a Department of the Army permit and the joint application form.
      </p>
    </section>
  </>
);

export default MVN_NEW_ORLEANS;
