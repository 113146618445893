import React from 'react';
import { classArray } from '../../utils/helpers.jsx';

import './pageContent.scss';

const PageContent =
  ({
    children,
  }) => {
    const pageClasses = classArray([
      'page-content',
      'container-fluid',
    ]);

    return (
      <div className={pageClasses}>
        <>
          {children}
        </>
      </div>
    );
  };

export default PageContent;
