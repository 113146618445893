import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'redux-bundler-react';

import AddressValidator from '../AddressValidator.jsx';
import TextInput from '@components/text-input/TextInput';
import SelectInput from '@components/select/Select';
import CreatableSelectInput from '@components/new-inputs/creatableSelectInput.jsx';
import { Button, Grid } from '@trussworks/react-uswds';
import Icon from '@components/icon/Icon';
import { mdiAccount } from '@mdi/js';

import { useFormContext } from 'react-hook-form';
import { salutations, states, countries } from '@forms/input-forms/_helper.jsx';
import { formatCoordFlt, formatCoordStr, formatUSPhoneNumber, mapCountryAlpha3toAlpha2 } from '@src/utils/helpers.jsx';
import PhoneInput from '@components/phone-input/PhoneInput';
import countryRegionData from 'country-region-data/dist/data-umd';

import './ContactFields.scss';
import AddressSuggestInput from '@components/address-suggestion/AddressSuggestInput.jsx';
import FieldHeader from '../FieldHeader.jsx';
import { ContactTypes } from '@src/utils/enums.ts';

const ContactFields = connect(
  'doResetAddressCandidatesResults',
  'doUpdateRequestLocation',
  'selectUserProfileData',
  ({
    doResetAddressCandidatesResults,
    doUpdateRequestLocation,
    handleFieldChange,
    isOptional = false,
    isReadOnly,
    label,
    requiredFields,
    requireEmail = true,
    showButton,
    showContactInfoSection = true,
    showSalutation = true,
    type,
    userProfileData,
  }) => {
    const { watch, setValue, register, trigger } = useFormContext();
    const [showPhoneTwo, setShowPhoneTwo] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const phoneRequired = requiredFields ? requiredFields.includes('Phone Number One') : true;

    register('showPhoneTwo');

    const phoneOne = watch('phoneOne');
    const phoneTwoType = watch('phoneTwoType');
    const phoneTwo = watch('phoneTwo');
    const faxPhone = watch('faxPhone');
    const address1 = watch('address');
    const address2 = watch('addressTwo');
    const city = watch('city');
    const state = watch('state');
    const zipcode = watch('zipcode');
    const country = watch('country');

    useEffect(() => {
      if ((phoneTwoType || phoneTwo) && !showPhoneTwo) {
        setShowPhoneTwo(() => true);
        setValue('showPhoneTwo', true);
      }
    }, [phoneTwoType, phoneTwo, showPhoneTwo, setValue]);


    const togglePhone = () => {
      if (!isReadOnly) {
        if (showPhoneTwo) {
          setShowPhoneTwo(() => false);
          setValue('showPhoneTwo', false);
          setValue('phoneTwo', '', { shouldValidate: true });
          setValue('phoneTwoCountryCode', '', { shouldValidate: true });
          setValue('phoneTwoType', '', { shouldValidate: true });
          setValue('phoneTwoExtension', '', { shouldValidate: true });
        } else {
          setShowPhoneTwo(() => true);
          setValue('showPhoneTwo', true, {shouldValidate: true});
          setValue('phoneTwoCountryCode', country === 'US' ? '1' : '', {shouldValidate: true});
        }
      }
    };

    const populateFromProfile = (e) => {
      e.preventDefault();
      setValue('firstName', userProfileData?.firstName ??  null, { shouldValidate: true });
      setValue('middleName', userProfileData?.middleName ??  null, { shouldValidate: true });
      setValue('lastName', userProfileData?.lastName ??  null, { shouldValidate: true });
      setValue('address', userProfileData?.address ??  null, { shouldValidate: true });
      setValue('addressTwo', userProfileData?.addressTwo ??  null, { shouldValidate: true });
      setValue('city', userProfileData?.city ??  null, { shouldValidate: true });
      setValue('country', userProfileData?.country ??  null, { shouldValidate: true });
      setValue('state', userProfileData?.state ??  null, { shouldValidate: true });
      setValue('zipcode', userProfileData?.zipcode ??  null, { shouldValidate: true });
      setValue('company', userProfileData?.companyName ??  null, { shouldValidate: true });
      setValue('phoneOneType', userProfileData?.phoneOneType ?? null, { shouldValidate: true });
      setValue('phoneOneCountryCode', userProfileData?.phoneOneCountryCode ??  null, { shouldValidate: true });
      setValue('phoneOne', userProfileData?.phoneOne ? (userProfileData?.phoneOneCountryCode === '1' ? formatUSPhoneNumber(userProfileData?.phoneOne) : userProfileData?.phoneOne) : null, { shouldValidate: true });
      setValue('phoneTwoType', userProfileData?.phoneTwoType ?? null, { shouldValidate: true });
      setValue('phoneTwoCountryCode', userProfileData?.phoneTwoCountryCode ??  null, { shouldValidate: true });
      setValue('phoneTwo', userProfileData?.phoneTwo ? (userProfileData?.phoneTwoCountryCode === '1' ? formatUSPhoneNumber(userProfileData?.phoneTwo) : userProfileData?.phoneTwo) : null, { shouldValidate: true });
      setValue('faxCountryCode', userProfileData?.faxCountryCode ??  null, { shouldValidate: true });
      setValue('faxPhone', userProfileData?.faxPhone ? (userProfileData?.faxCountryCode === '1' ? formatUSPhoneNumber(userProfileData?.faxPhone) : userProfileData?.faxPhone) : null, { shouldValidate: true });
      setValue('salutation', userProfileData?.salutation ??  null, { shouldValidate: true });
      setValue('emailAddress', userProfileData?.emailAddress ??  null, { shouldValidate: true });
      handleFieldChange && handleFieldChange();
    };

    const populateAddressFields = (address, isProjectAddress) => {
      const fields = address?.[0]?.candidates?.[0]?.attributes;
      const addressField = document.getElementById('address');
      const addressFieldFocused = document.activeElement === addressField;

      if (fields) {

        if (addressFieldFocused) {
          addressField.blur();
        }

        setValue('address', fields.StAddr, { shouldValidate: true });
        setValue('addressTwo', fields.SubAddr, { shouldValidate: true });
        setValue('city', fields.City, { shouldValidate: true });
        setValue('state', fields.RegionAbbr, { shouldValidate: true });
        setValue('zipcode', fields.Postal, { shouldValidate: true });
        setValue('county', fields.Subregion, { shouldValidate: true });
        setValue('country', mapCountryAlpha3toAlpha2(fields.Country), { shouldValidate: true });
        if (isProjectAddress)
        {
          setValue('latitude', formatCoordStr(fields.Y), { shouldValidate: true });
          setValue('longitude', formatCoordStr(fields.X), { shouldValidate: true });
          doUpdateRequestLocation({ address: fields?.StAddr, addressTwo: fields?.SubAddr, city: fields?.City, state: fields?.RegionAbbr, zipcode: fields?.Postal, county: fields?.Subregion, country: fields?.Country !== 'USA' ? fields?.Country : 'US', latitude: formatCoordFlt(fields.Y), longitude: formatCoordFlt(fields.X) });
        }
        else
        {
          doResetAddressCandidatesResults();
        }

        if (addressFieldFocused) {
          addressField.focus();
        }
      }
    };

    const addressValues = useMemo(() => ({
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zipcode: zipcode,
      country: country,
    }), [address1, address2, city, state, zipcode, country]);

    const zipRequired = useMemo(() => {
      const countryNotForeign = country === 'US' || !country;
      const required = requiredFields ? requiredFields.includes('Zip Code') : true;

      return countryNotForeign && required;
    }, [country, requiredFields]);

    const handleBlur = (e) => {
      setValue(e?.target?.name, e?.target?.value?.trim(), {shouldValidate: true});

      if (e?.target?.name === 'address' && e?.target?.value?.trim()) {
        setValue('country', 'US');
      }
    };

    const regionHelper = useCallback((country) => {
      const selectedCountryData = countryRegionData?.find(regionCountry => regionCountry.countryShortCode === country);

      let regions = [];
      if (selectedCountryData) {
        regions = selectedCountryData.regions.map(region => ({
          value: region.shortCode ?? region.name, // Some countries do not have provided shortcodes
          text: region.name
        }));
      }

      regions.push({ value: 'Other', text: 'Other' });
      regions.push({ value: 'None', text: 'None' });

      return regions;
    }, []);

    const handleCountryChange = useCallback((selectedCountry) => {
      const regions = regionHelper(selectedCountry);
      setStateOptions(regions);

      if (selectedCountry !== country) {
        trigger('address');
        setValue('city', '',{shouldValidate: true});
        setValue('state', '',{shouldValidate: true});
        setValue('zipcode', '', {shouldValidate: true});
      }

      if(selectedCountry === 'US' && !phoneOne)
      {
        setValue('phoneOneCountryCode', '1', {shouldValidate: true});
      }
      else if(selectedCountry !== 'US' && !phoneOne)
      {
        setValue('phoneOneCountryCode', '', {shouldValidate: true});
      }

      if(selectedCountry === 'US' && !phoneTwo && showPhoneTwo)
      {
        setValue('phoneTwoCountryCode', '1', {shouldValidate: true});
      }
      else if(selectedCountry !== 'US' && !phoneTwo && showPhoneTwo)
      {
        setValue('phoneTwoCountryCode', '', {shouldValidate: true});
      }

      if(selectedCountry === 'US' && !faxPhone)
      {
        setValue('faxCountryCode', '1', {shouldValidate: true});
      }
      else if(selectedCountry !== 'US' && !faxPhone)
      {
        setValue('faxCountryCode', '', {shouldValidate: true});
      }

    }, [country, phoneOne, phoneTwo, showPhoneTwo, faxPhone, regionHelper, setStateOptions, setValue, trigger]);

    useEffect(() => {
      const regions = regionHelper(country);
      setStateOptions(regions);
    }, [country, regionHelper, setStateOptions]);

    useEffect(() => {
      const matchingOption = stateOptions?.find(option => option?.value === state);
      if (matchingOption) {
        setValue('state', matchingOption?.value, { shouldValidate: true });
      }
    }, [stateOptions, setValue, state]);

    return (
      <div className='container-fluid'>
        <FieldHeader text={label} />
        {type === ContactTypes.PropertyOwner && (
          <>
            <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Parcel Number(s)</h6>
            <Grid row gap='md'>
              <Grid col={8} tablet={{col: 8}}>
                <CreatableSelectInput name={'parcels'} label='Parcel Number(s)' placeholder='Enter any applicable parcel numbers' multi required={requiredFields ? requiredFields.includes('Parcel Number(s)') : false} readOnly={isReadOnly} onChange={handleFieldChange} />
              </Grid>
            </Grid>
          </>)}
        <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Name</h6>
        <Grid row gap='md'>
          <Grid tablet={{col: 'auto'}}>
            <div align='left'>
              {showButton &&
                <Button className='use-profile-button' onClick={(e) => populateFromProfile(e)} tabIndex={0} disabled={isReadOnly}> <Icon focusable={false} title='Use Profile Data' path={mdiAccount} size={0.85} />USE PROFILE DATA </Button>}
            </div>
            {showSalutation === true && <SelectInput name='salutation' label='Salutation' readOnly={isReadOnly} onChange={handleFieldChange}>
              {salutations.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>}
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 4}}>
            <TextInput label='First Name' name='firstName' required={requiredFields ? requiredFields.includes('First Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
          <Grid tablet={{col: 4}}>
            <TextInput label='Middle Name' name='middleName' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
          <Grid tablet={{col: 4}}>
            <TextInput label='Last Name' name='lastName' required={requiredFields ? requiredFields.includes('Last Name') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
          </Grid>
        </Grid>

        <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Address {isOptional && <span className='text-italic'> (optional)</span>}
        </h6>
        <Grid row gap='md'>
          <Grid tablet={{col: 8}}>
            <AddressSuggestInput id='address' name='address' label='Address' required={requiredFields ? requiredFields.includes('Address One') : true} usOnly={false} onBlur={handleBlur} onChange={handleFieldChange} populateAddressFields={populateAddressFields} readOnly={isReadOnly} showOptionalText={false} />
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 8}}>
            <TextInput label='Address Line 2' name='addressTwo' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} showOptionalText={!isOptional} />
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 'auto'}}>
            <SelectInput name='country' label='Country' required={requiredFields ? requiredFields.includes('Country') : true} readOnly={isReadOnly} onChange={(e) => handleCountryChange(e.target.value)} showOptionalText={false}>
              {countries?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
            </SelectInput>
          </Grid>
        </Grid>
        <Grid row gap='md'>
          <Grid tablet={{col: 4}}>
            <TextInput label='City' name='city' required={requiredFields ? requiredFields.includes('City') : true} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} showOptionalText={false}/>
          </Grid>
          {country === 'US' || country === null || country === '' ? (
            <Grid tablet={{col: 4}}>
              <SelectInput name='state' label='State' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly} onChange={handleFieldChange} showOptionalText={false}>
                {states?.map((item, i) => (<option key={i + 2} value={item.value}>{item.text}</option>))}
              </SelectInput>
            </Grid>
          ) : (
            <Grid tablet={{col: 4}}>
              <SelectInput name='state' label='Region' required={requiredFields ? requiredFields.includes('State') : true} readOnly={isReadOnly} showOptionalText={false}>
                {stateOptions?.map((item, i) => (<option key={i + 2} value={item?.value}>{item?.text}</option>))}
              </SelectInput>
            </Grid>
          )}
          <Grid tablet={{col: 4}}>
            <TextInput type='text' label={country === 'US' || country === null || country === '' ? 'Zip Code' : 'Postal code'} required={zipRequired} name='zipcode' maxLength={country === 'US' ? 5 : 10} readOnly={isReadOnly} onChange={handleFieldChange} showOptionalText={!isOptional && !(country === 'US' || country === null)} />
          </Grid>
        </Grid>

        <Grid row className='margin-top-2' gap='md'>
          <Grid tablet={{col:12}}>
            <AddressValidator source='form' isDisabled={isReadOnly} address={addressValues} />
          </Grid>
        </Grid>

        {showContactInfoSection && (
          <>
            <h6 className='border-bottom width-full padding-bottom-1 margin-top-4'>{label} Contact Information</h6>
            <div className='usa-label'>Phone Number {phoneRequired && <span className='asterisk-color'>*</span>}</div>
            <div className='phone-container phone1'>
              <PhoneInput phoneName='phoneOne' phoneCountryCodeName='phoneOneCountryCode' phoneTypeName='phoneOneType' extensionName='phoneOneExtension' required={requiredFields ? requiredFields.includes('Phone Number One') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} showTypeSelect />
              {!showPhoneTwo && (
                <div className='phone-button-container'>
                  <Button className='button-small add-phone-button' disabled={isReadOnly} size='small' title='Add Phone Number' id='phone-toggle-1' name='phoneToggleButton1' onClick={togglePhone} onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }}>Add</Button>
                </div>
              )}
            </div>
            {showPhoneTwo && (
              <div className='phone-container phone2'>
                <PhoneInput phoneName='phoneTwo' phoneCountryCodeName='phoneTwoCountryCode' phoneTypeName='phoneTwoType' extensionName='phoneTwoExtension' required={requiredFields ? requiredFields.includes('Phone Number Two') : true} readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} showTypeSelect />
                <div className='phone-button-container'>
                  <Button className='button-small' disabled={isReadOnly} title='Remove Phone Number' id='phone-toggle-2' name='phoneToggleButton2' onClick={togglePhone} secondary onKeyUp={(e) => { e.key === 'Enter' && togglePhone(); }} >Remove</Button>
                </div>
              </div>
            )}
            <div>
              <PhoneInput phoneName='faxPhone' phoneCountryCodeName='faxCountryCode' label='Fax Number' readOnly={isReadOnly} onChange={handleFieldChange} onBlur={handleBlur} />
            </div>
            <Grid row gap='md'>
              <Grid col>
                <TextInput name='emailAddress' label='Email Address' required={requireEmail && (requiredFields ? requiredFields.includes('Email Address') : true)} readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </Grid>
            </Grid>
            <Grid row gap='md'>
              <Grid col>
                <TextInput name='company' label='Company' readOnly={isReadOnly} onBlur={handleBlur} onChange={handleFieldChange} />
              </Grid>
            </Grid>
          </>)}
      </div >
    );
  }
);

export default ContactFields;
