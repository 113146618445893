import { useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import TextInput from '@components/text-input/TextInput.jsx';


const generateLabel = ({random1, random2}) => `What is ${random1} + ${random2}?`;

const Antispam = connect('doSetAntispamResults',({doSetAntispamResults, onBlur}) => {
  const [answer, setAnswer] = useState({});

  useEffect(()=>{

    const min = 1;
    const max = 10;
    const random1 = Math.floor(min + Math.random() * (max - min + 1));
    const random2 = Math.floor(min + Math.random() * (max - min + 1));
    setAnswer({random1: random1, random2: random2, answer: random1 + random2});

  },[]);

  const handleInputBlur = (e) => {
    onBlur();
  };

  const handleInputChange = (e) => {
    const value = Number(e?.target?.value);
    if (value === answer?.answer) {
      doSetAntispamResults(true);
    } else {
      doSetAntispamResults(false);
    }
  };

  return (<TextInput style={{width: '100px'}} name='antispam' type='number' label={generateLabel(answer)} onChange={handleInputChange} onBlur={handleInputBlur} required />);

});

export default Antispam;
