import { useState, useEffect } from 'react';
import { connect } from 'redux-bundler-react';

import Icon from '@components/icon/Icon';
import { mdiArrowLeftThick, mdiCloudUploadOutline, mdiMapMarkerRadius, mdiFilePdfBox } from '@mdi/js';
import { Button } from '@trussworks/react-uswds';

import { filterNullEmptyObjects } from '@src/utils/helpers';
import ProjectLocationModal from '../modals/ProjectLocationModal';
import NavbarNavigateWarningModal from '../modals/NavbarNavigateWarningModal';

import TimeAgo from 'react-timeago';

import './FormHeader.scss';

const iconProps = {
  focusable: false,
  size: 1,
};

const formatter = (value, unit, suffix) => {
  if (unit !== 'second' && suffix !== 'from now') {
    return ['Last saved:', value, unit + (value !== 1 ? 's' : ''), suffix].join(' ');
  }

  if (suffix === 'ago') {
    return 'Last saved: a few seconds ago';
  }

  if (suffix === 'from now') {
    return 'Last saved: Just now';
  }
};

const FormHeader = connect(
  'doUploadAndUpdateViolationComplaint',
  'doUploadAndUpdatePreApp',
  'doUploadAndUpdateJD',
  'doUploadAndUpdatePermits',
  'doGenerateDraftDocuments',
  'doModalOpen',
  'doUpdateRelativeUrl',
  'selectSelectedRequest',
  'selectProjectID',
  'selectRequestFormData',
  'selectIsReadOnly',
  ({
    doUploadAndUpdateViolationComplaint,
    doUploadAndUpdatePreApp,
    doUploadAndUpdateJD,
    doUploadAndUpdatePermits,
    doGenerateDraftDocuments,
    doModalOpen,
    doUpdateRelativeUrl,
    selectedRequest,
    requestFormData,
    header,
    isReadOnly,
    disableSaveProgress,
  }) => {
    const [modifiedDate, setModifiedDate] = useState(new Date());

    const handleOnSave = () => {
      const payload = filterNullEmptyObjects(requestFormData);

      switch (selectedRequest) {
        case '1':
          doUploadAndUpdateJD(payload, 'Draft');
          break;
        case '2':
          doUploadAndUpdateJD(payload, 'Draft');
          break;
        case '3':
          doUploadAndUpdatePermits(payload, 'Draft');
          break;
        case '4':
          doUploadAndUpdatePermits(payload, 'Draft');
          break;
        case '5':
          doUploadAndUpdatePreApp(payload, 'Draft');
          break;
        case '6':
          doUploadAndUpdateViolationComplaint(payload, 'Draft');
          break;
        default:
          break;        }
    };

    const handleLocationClick = () => {
      doModalOpen(ProjectLocationModal);
    };

    const handleExitClick = () => {
      isReadOnly ? doUpdateRelativeUrl('/dashboard') : doModalOpen(NavbarNavigateWarningModal, { url: '/' });
    };

    useEffect(() => {
      const dateString = requestFormData?.requestLastUpdatedDate || requestFormData?.createdDate;

      if (!dateString) {
        return;
      }

      const lastModifiedZulu = new Date(dateString); //temp fix as time is being returned from DB as Zulu time when it is actually local server time

      setModifiedDate(lastModifiedZulu);
    }, [requestFormData?.requestLastUpdatedDate, requestFormData?.createdDate]);

    const locationText = selectedRequest === '6' ? 'Site Location' : 'Project Location';

    return (
      <div className='form-header-container'>
        <div className='text-container'>
          <div className='title-container'>
            <div className='header-text'>{header} - </div>
            <div className='project-name'>{requestFormData?.projectName}</div>
            {modifiedDate && <TimeAgo className='modified-text' date={modifiedDate} formatter={formatter} />}
          </div>
        </div>
        <div className='buttons-container'>
          <div className='exit-button-container'>
            <Button className='button-small exit-button' onClick={handleExitClick} secondary title='Exit' type='button'>
              <Icon path={mdiArrowLeftThick} {...iconProps} />
              Exit Form
            </Button>
          </div>
          <div className='action-buttons-container'>
            {!isReadOnly && !disableSaveProgress && (
              <Button className='action-button button-small save-progress' onClick={handleOnSave} type='button'>
                <Icon path={mdiCloudUploadOutline} {...iconProps} />
                Save Progress
              </Button>
            )}
            {!isReadOnly && (
              <Button
                className='action-button button-small draft-pdf-button'
                onClick={doGenerateDraftDocuments}
                title='Download all draft documents with currently entered information for this request'
                type='button'
              >
                <Icon path={mdiFilePdfBox} {...iconProps} />
                Download Draft PDF
              </Button>
            )}
            {
              <Button
                className='action-button button-small'
                onClick={handleLocationClick}
                type='button'
              >
                <Icon path={mdiMapMarkerRadius} {...iconProps} />
                {locationText}
              </Button>
            }
          </div>
        </div>
      </div>
    );
  }
);
export default FormHeader;
