import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const NAE_NEW_ENGLAND = () => (
  <>
    <section id='nae-new-england'>
      <DistrictInfoTable district='NAE'/>
      <p>
      The New England District uses a series of <ExternalLink content='Regional and Programmatic General Permits (GPs)' title='Regional and Programmatic General Permits (GPs)' href='https://www.nae.usace.army.mil/Missions/Regulatory/State-General-Permits/' /> organized by state, in lieu of the Nationwide Permits. 
      </p>
      <p>
       New England District is developing a process to integrate submission of applications for these GPs into the RRS. At this time the RRS should only be used for submission of individual permit applications in Connecticut, Massachusetts, Maine, New Hampshire, Rhode Island, and Vermont. At this time, the RRS should be used for submission of individual permit applications using form ENG 4345, jurisdictional determination requests, pre-application consultation requests, and reporting potential violations in Connecticut, Massachusetts, Maine, New Hampshire, Rhode Island, and Vermont.  Please visit our webpage for the current instructions and additional information that may be required by state for submission of New England GP applications: <ExternalLink content='http:www.nae.usace.army.mil/Missions/Regulatory/State-General-Permits/' title='http:www.nae.usace.army.mil/Missions/Regulatory/State-General-Permits/' href='http:www.nae.usace.army.mil/Missions/Regulatory/State-General-Permits/' />.
      </p>
    </section>
  </>
);

export default NAE_NEW_ENGLAND;
