import React from 'react';
import { connect } from 'redux-bundler-react';
import { mdiFileEditOutline, mdiFileQuestionOutline, mdiViewDashboardVariantOutline } from '@mdi/js';


import ModuleButton from '../../../Resources/components/_shared/ModuleButton';
import NewApplicationModal from '../modals/NewApplication';
import NotSureModal from '../modals/NotSure';
import { ProfileRoles } from '../../../../utils/enums';

import '../../home.scss';

const FormCard = connect(
  'doModalOpen',
  'doUpdateRelativeUrl',
  'doResetReduxFormData',
  'selectUserProfileData',
  ({
    doModalOpen,
    doUpdateRelativeUrl,
    doResetReduxFormData,
    userProfileData
  }) => {
    const handleButtonClick = (modal) => {
      doResetReduxFormData();
      doModalOpen(modal);
    };

    return (
      <div className='p-2 align-content-center'>
        <h4 className='text-center mb-4'>Select an option below to get started: </h4>
        <div className='row home-request-row'>
          <div className='row module-button-row'>
            <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1 mr-3'>
              <ModuleButton
                title='Start a New Request'
                imageAlt='Start a New Request'
                text='Create a new request for Pre-application Scoping, Jurisdictional Determination, or Nationwide Permit'
                icon={mdiFileEditOutline}
                onClick={() => handleButtonClick(NewApplicationModal)}
                isDisabled={userProfileData?.role === ProfileRoles.USACERegulator || userProfileData?.role === ProfileRoles.ExternalAgency}
              />
            </div>
            <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1 mr-3'>
              <ModuleButton
                title={'I\'m Not Sure'}
                imageAlt={'I\'m Not Sure'}
                text='Answer a few quick questions to help determine which type of request to submit'
                icon={mdiFileQuestionOutline}
                onClick={() => handleButtonClick(NotSureModal)}
                isDisabled={userProfileData?.role === ProfileRoles.USACERegulator || userProfileData?.role === ProfileRoles.ExternalAgency}
              />
            </div>
            <div className='col-lg-3 col-md-6 col-xs-12 mt-1 mb-1 mr-3'>
              <ModuleButton
                title='My Dashboard'
                imageAlt='My Dashboard'
                text='View Submitted/Edit Draft Requests'
                icon={mdiViewDashboardVariantOutline}
                onClick={() => doUpdateRelativeUrl('/dashboard')}
                isDisabled={userProfileData?.role === ProfileRoles.USACERegulator || userProfileData?.role === ProfileRoles.ExternalAgency}
              />
            </div>
          </div>
        </div>
      </div >
    );
  }
);

export default FormCard;