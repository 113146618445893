import ExternalLink from '@components/external-link/ExternalLink';
import { DistrictInfoTable } from '../../JointPermitApplications';

const MVK_VICKSBURG = () => (
  <>
    <section id='mvk-vicksburg'>
      <DistrictInfoTable district='MVK'/>
      <p>
      Those seeking a wetland permit in the coastal zone of Mississippi (MS) should use the <ExternalLink content='joint permit application and notification form' title='Joint Permit Application and Notification Form' href='https://dmr.ms.gov/permitting/' />. In addition, applicants in MS should also review the Mississippi General Permits “MVK_SAM MSGP” to see if their activity qualifies for a programmatic permit through Mississippi Department of Marine Resources (MDMR).  Those seeking a wetland permit in the coastal zone of Louisiana should utilize the <ExternalLink content='joint permit application form' title='Joint Permit Application Form' href='https://www.dnr.louisiana.gov/index.cfm?md=pagebuilder&tmp=home&pid=93' />.  
      </p>
      <p>
      Once information is submitted to the appropriate State agency, the State will send the permit application electronically via email to the correct district.  The <ExternalLink content='Vicksburg District website' title='Vicksburg District Website' href='https://www.mvk.usace.army.mil/Missions/Regulatory/'/> contains more information on obtaining a Department of the Army authorization and application form.
      </p>
    </section>
  </>
);

export default MVK_VICKSBURG;
