const sectionStateBundle = {
  name: 'sectionstate',
  getReducer: () => {
    const initialData = {
      applicant: null,
      agent: null,
      propertyowner: null,
      propertyownermodal: null,
      adjoiningpropertyownermodal: null,
      project: null,
      contact: null,
      location: null,
      aquaticresources: null,
      aquaticresourcesmodal: null,
      supportinginfo: null,
      certifysignsubmit: null,
      historicpropendspecies: null,
    };

    return (state = initialData, { type, payload }) => {

      if (type === 'SAVE_SECTION_DATA') {
        const formattedData = Object.fromEntries(
          Object.entries(payload.data).map(([key, value]) => [
            key,
            {
              ...value,
              value: value.value === null ? null : value.value
            }
          ])
        );
        return { ...state, [payload.sectionname]: formattedData };
      }

      if (type === 'CLEAR_SECTION') {
        return { ...state, [payload.sectionname]: null };
      }
      if (type === 'RESET_SECTION_STATE') {
        return initialData;
      }

      return state;
    };
  },
  doSaveSection: (sectionname, data) => ({ dispatch }) => {
    dispatch({
      type: 'SAVE_SECTION_DATA',
      payload: {
        sectionname: sectionname,
        data: data,
      },
    });
  },
  doClearSection: (sectionname) => ({ dispatch }) => {
    dispatch({
      type: 'CLEAR_SECTION',
      payload: {
        sectionname: sectionname,
      },
    });
  },
  doResetSectionState: () => ({ dispatch }) => {
    dispatch({
      type: 'RESET_SECTION_STATE',
    });
  },
  selectAllSectionData: (state) => state.sectionstate,
  selectApplicantState: (state) => state.sectionstate.applicant,
  selectAgentState: (state) => state.sectionstate.agent,
  selectPropertyOwnerState: (state) => state.sectionstate.propertyowner,
  selectPropertyOwnerModalState: (state) => state.sectionstate.propertyownermodal,
  selectAdjoiningPropertyOwnerModalState: (state) => state.sectionstate.adjoiningpropertyownermodal,
  selectProjectSectionState: (state) => state.sectionstate.project,
  selectContactState: (state) => state.sectionstate.contact,
  selectLocationState: (state) => state.sectionstate.location,
  selectAquaticResourcesState: (state) => state.sectionstate.aquaticresources,
  selectAquaticResourcesModalState: (state) => state.sectionstate.aquaticresourcesmodal,
  selectSupportingInfoState: (state) => state.sectionstate.supportinginfo,
  selectCertifySignSubmitState: (state) => state.sectionstate.certifysignsubmit,
  selectHistoricPropEndSpecies: (state) => state.sectionstate.historicpropendspecies,
};

export default sectionStateBundle;
